import React, {
  Suspense,
  useEffect,
  useState,
  useRef,
  useLayoutEffect,
} from "react";
import Download from "../images/download-icon.png";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// https://medium.com/geekculture/creating-multi-select-dropdown-with-checkbox-in-react-792ff2464ef3
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import StackChart from "../Components/StackChart";
import { Tooltip } from "react-tooltip";
import downloadFlag from "../DownloadFlag";

import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";

import configDriven from "../ConfirgDriven";
import MultiCal from "../Components/MultiCal";


import MultiCascaderH1 from "../Components/MultiCascader";
import MultiCascaderC1 from "../Components/ChannelMultiCascader";


import MultiCascaderH2 from "../Components/MultiCascader";
import MultiCascaderC2 from "../Components/ChannelMultiCascader";




const Option = (props) => {

  return (
    <div className="custom-select-option">
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const Comparison = (props) => {
  console.log("Props Comparison",props);
  const refComp1 = useRef(null);
  const refComp2 = useRef(null);

  const [CompHeight1, setCompHeight1] = useState("");
  const [CompHeight2, setCompHeight2] = useState("");

  const [full, setFull] = useState(true);
  const [full2, setFull2] = useState(true);

  const [isSubmitEnabled, setIsSubmitEnabled] = useState(true);
  // const [refreshKey, setRefreshKey] = useState(0);
  const [submittedStartDate, setSubmittedStartDate] = useState(props.DefaultStartDateComp1);
  const [submittedEndDate, setSubmittedEndDate] = useState(props.DefaultEndDateComp1);
  const [submittedTimeFrame, setSubmittedTimeFrame] = useState(props.timeframeComp1);
  const [isRefreshEnabled, setIsRefreshEnabled] = useState(false);

  useEffect(() => {
    if (props.DefaultStartDateComp1 !== submittedStartDate || props.DefaultEndDateComp1 !== submittedEndDate) {
      setIsRefreshEnabled(true);
    }

    if (props.DefaultStartDateComp1 === submittedStartDate && props.DefaultEndDateComp1 === submittedEndDate) {
      setIsRefreshEnabled(false);
    }

  }, [props.DefaultStartDateComp1, props.DefaultEndDateComp1])


  const [isSubmitEnabled2, setIsSubmitEnabled2] = useState(true);
  // const [refreshKey, setRefreshKey] = useState(0);
  const [submittedStartDate2, setSubmittedStartDate2] = useState(props.DefaultStartDateComp2);
  const [submittedEndDate2, setSubmittedEndDate2] = useState(props.DefaultEndDateComp2);
  const [submittedTimeFrame2, setSubmittedTimeFrame2] = useState(props.timeframeComp2);
  const [isRefreshEnabled2, setIsRefreshEnabled2] = useState(false);

  useEffect(() => {
    if (props.DefaultStartDateComp2 !== submittedStartDate2 || props.DefaultEndDateComp2 !== submittedEndDate2) {
      setIsRefreshEnabled2(true);
    }

    if (props.DefaultStartDateComp2 === submittedStartDate2 && props.DefaultEndDateComp2 === submittedEndDate2) {
      setIsRefreshEnabled2(false);
    }

  }, [props.DefaultStartDateComp2, props.DefaultEndDateComp2])

  // const dataC = JSON.parse(localStorage.getItem("channelFilters"));
  // console.log("dataC: ", dataC);
  // const dataH = JSON.parse(localStorage.getItem("allBrands"));
  // console.log("dataH: ", dataH);



  const handler = () => {
    setFull(!full);
  };

  const handler2 = () => {
    setFull2(!full2);
  };

  useEffect(() => {
    if (refComp1.current !== null) {
      setCompHeight1(refComp1.current.clientHeight);
    }
    if (refComp2.current !== null) {
      setCompHeight2(refComp2.current.clientHeight);
    }
  }, []);

  const IsInAllFilters = (item) => {
    for (let i = 0; i < props.ActiveFiltersComp.length; i++) {
      if (props.ActiveFiltersComp[i].name === item.name) {
        return true;
      }
    }
    return false;
  };

  const fnDisabledSubmitBtn1 = () => {
    if (
      props.DefaultStartDateComp1 !== "NaN-NaN-NaN" &&
      props.DefaultEndDateComp1 !== "NaN-NaN-NaN"
    ) {
      return false;
    } else if (
      props.DefaultStartDateComp1 === "NaN-NaN-NaN" ||
      props.DefaultEndDateComp1 === "NaN-NaN-NaN"
    ) {
      return true;
    }
  };

  const fnDisabledSubmitBtn2 = () => {
    if (
      props.DefaultStartDateComp2 !== "NaN-NaN-NaN" &&
      props.DefaultEndDateComp2 !== "NaN-NaN-NaN"
    ) {
      return false;
    } else if (
      props.DefaultStartDateComp2 === "NaN-NaN-NaN" ||
      props.DefaultEndDateComp2 === "NaN-NaN-NaN"
    ) {
      return true;
    }
  };

  const [divWidth, setDivWidth] = useState("");
  const [divWidth2, setDivWidth2] = useState("");

  // console.log("divWidth: ", divWidth);

  useLayoutEffect(() => {
    const updateDivHeight = () => {
      if (refComp1.current !== null) {
        const newWidth = refComp1.current.clientWidth;
        setDivWidth(newWidth);
      }

      if (refComp2.current !== null) {
        const newWidth2 = refComp2.current.clientWidth;
        setDivWidth2(newWidth2);
      }
    };

    updateDivHeight(); // Initial update
    window.addEventListener("resize", updateDivHeight);

    return () => {
      window.removeEventListener("resize", updateDivHeight);
    };
  }, []);

  const displayNames = JSON.parse(localStorage.getItem("displayNames"));

  const returnDisplayName = (id) => {
    if (configDriven.show_display_name && displayNames?.hasOwnProperty(id)) {
      return displayNames[id];
    } else {
      return id;
    }
  }


  return (
    <div className="container-fluid main-content-w pt-1">
      {/* <div className="row align-items-center">
            <div className="col-xl-10">
               <div className="card">
                  <div className="card-body allFilter">
                     <h6 className="m-0 text-center cm-filter-title">All Filter</h6>
                     <ul id="scroller" className="p-0">
                        {
                           props.ActiveFiltersComp.map((item, index) => {
                              return (
                                 <li key={index} onClick={() => props.RemoveCompFilter(item)} className="cm-filter-option-li">
                                    <span className="btn cm-drag-btn p-1 mx-2 active">
                                       <p className="m-0 text-left px-1">{item.name}</p>
                                       <p className="m-0 px-1">
                                          <i className="fa fa-minus" aria-hidden="true"></i>
                                       </p>
                                    </span>
                                 </li>
                              )
                           })
                        }
                        {
                           props.AllFiltersComp.map((item, index) => {
                              return (
                                 (!IsInAllFilters(item)) ? <li key={index} onClick={() => props.AddCompFilter(index)} className="cm-filter-option-li">
                                    <span className="btn cm-drag-btn p-1 mx-2">
                                       <p className="m-0 text-left px-1">{item.name}</p>
                                       <p className="m-0 px-1">
                                          <i className="fa fa-plus" aria-hidden="true"></i>
                                       </p>
                                    </span>
                                 </li> : null
                                 // <li key={index} className="cm-filter-option-li" onClick={() => props.RemoveCompFilter(item)}>
                                 // 	<span className="btn cm-drag-btn p-1 mx-2 active">
                                 // 		<p className="m-0 text-left px-1">{item.name}</p>
                                 // 		<p className="m-0 px-1">
                                 // 			<i className="fa fa-minus" aria-hidden="true"></i>
                                 // 		</p>
                                 // 	</span>
                                 // </li>
                              )
                           })
                        }
                     </ul>
                  </div>
               </div>
            </div>
            <div className="col-xl-2">
               <div className="card">
                  <div className="card-body cursor-pointer p-3" onClick={() => props.setCompFilterTabShow(!props.CompFilterTabShow)}>
                     <h6 className={props.CompFilterTabShow ? "m-0 text-center primary-text" : "m-0 text-center"}><i className="fa fa-filter" aria-hidden="true"></i> Selected Filter</h6>
                  </div>
               </div>
            </div>
         </div> */}
      <div className="row">
        {props.CompFilterTabShow ? (
          <div className="col-xl-12">
            <div className="card-body filter-bg p-0">
              <div className="card custom-summary filter-active">
                <div className="card-body height-656 p-0">
                  <div className="">
                    <div className="custom-header mb-1">
                      <span className="header-title">
                        <h3 className="data-spends my-2 px-2">
                          Analytics Filter
                        </h3>
                        <h3
                          className="m-0"
                          onClick={() => props.setCompFilterTabShow(false)}
                        >
                          <i
                            className="fa fa-times cursor-pointer"
                            aria-hidden="true"
                          ></i>{" "}
                          &nbsp;
                        </h3>
                      </span>
                    </div>
                    <div
                      className="custom-body"
                      style={{ overflow: "auto", height: "100%" }}
                    >
                      <DragDropContext onDragEnd={props.onDragEndcomp}>
                        <div className="row">
                          <div
                            className="col-sm-12"
                            style={{ overflow: "auto", height: "auto" }}
                          >
                            <div className="p-0" style={{ height: "auto" }}>
                              <div className="row">
                                <Droppable droppableId={"active"}>
                                  {(provided) => (
                                    <div
                                      className="col-sm-12 cm-drop"
                                      {...provided.droppableProps}
                                      ref={provided.innerRef}
                                    >
                                      {props.ActiveFiltersComp.map(
                                        (item, index) => {
                                          return (
                                            <Draggable
                                              key={item.name}
                                              draggableId={item.name}
                                              index={index}
                                            >
                                              {(provided) => (
                                                <span
                                                  ref={provided.innerRef}
                                                  {...provided.draggableProps}
                                                  {...provided.dragHandleProps}
                                                  className="btn mb-3 cm-drop-btn"
                                                >
                                                  <div className="drop-header">
                                                    <p className="m-0 text-left">
                                                      {item.name}
                                                    </p>
                                                    {/* <p className="m-0"><i className="fa fa-minus" aria-hidden="true"></i></p> */}
                                                  </div>
                                                  <div className="row">
                                                    <div className="col-sm-6 border-right-2x">
                                                      <div className="drop-body text-left">
                                                        <label
                                                          className="m-0"
                                                          htmlFor="chart1"
                                                        >
                                                          {" "}
                                                          Chart 1
                                                        </label>
                                                        <ReactSelect
                                                          options={item.filters}
                                                          isMulti
                                                          closeMenuOnSelect={
                                                            false
                                                          }
                                                          hideSelectedOptions={
                                                            false
                                                          }
                                                          components={{
                                                            Option,
                                                          }}
                                                          onChange={(
                                                            selected
                                                          ) => {
                                                            if (
                                                              selected !==
                                                              null &&
                                                              selected.length >
                                                              0
                                                            ) {
                                                              if (
                                                                selected[
                                                                  selected.length -
                                                                  1
                                                                ].value ===
                                                                "All"
                                                              ) {
                                                                return props.onChangeFilterComp(
                                                                  selected[
                                                                  selected.length -
                                                                  1
                                                                  ],
                                                                  index,
                                                                  "Selected1"
                                                                );
                                                              } else {
                                                                for (
                                                                  var i = 0;
                                                                  i <
                                                                  selected.length;
                                                                  i++
                                                                ) {
                                                                  if (
                                                                    selected[i]
                                                                      .value ===
                                                                    "All"
                                                                  ) {
                                                                    selected.splice(
                                                                      i,
                                                                      1
                                                                    );
                                                                  }
                                                                }
                                                              }
                                                            }
                                                            return props.onChangeFilterComp(
                                                              selected,
                                                              index,
                                                              "Selected1"
                                                            );
                                                          }}
                                                          allowSelectAll={true}
                                                          value={
                                                            item.Selected1
                                                              ? item.Selected1
                                                              : null
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                      <div className="drop-body text-left">
                                                        <label
                                                          className="m-0"
                                                          htmlFor="chart2"
                                                        >
                                                          {" "}
                                                          Chart 2
                                                        </label>
                                                        <ReactSelect
                                                          options={item.filters}
                                                          isMulti
                                                          closeMenuOnSelect={
                                                            false
                                                          }
                                                          hideSelectedOptions={
                                                            false
                                                          }
                                                          components={{
                                                            Option,
                                                          }}
                                                          onChange={(
                                                            selected
                                                          ) => {
                                                            if (
                                                              selected !==
                                                              null &&
                                                              selected.length >
                                                              0
                                                            ) {
                                                              if (
                                                                selected[
                                                                  selected.length -
                                                                  1
                                                                ].value ===
                                                                "All"
                                                              ) {
                                                                return props.onChangeFilterComp(
                                                                  selected[
                                                                  selected.length -
                                                                  1
                                                                  ],
                                                                  index,
                                                                  "Selected2"
                                                                );
                                                              } else {
                                                                for (
                                                                  var i = 0;
                                                                  i <
                                                                  selected.length;
                                                                  i++
                                                                ) {
                                                                  if (
                                                                    selected[i]
                                                                      .value ===
                                                                    "All"
                                                                  ) {
                                                                    selected.splice(
                                                                      i,
                                                                      1
                                                                    );
                                                                  }
                                                                }
                                                              }
                                                            }
                                                            return props.onChangeFilterComp(
                                                              selected,
                                                              index,
                                                              "Selected2"
                                                            );
                                                          }}
                                                          allowSelectAll={true}
                                                          value={
                                                            item.Selected2
                                                              ? item.Selected2
                                                              : null
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </span>
                                              )}
                                            </Draggable>
                                          );
                                        }
                                      )}
                                    </div>
                                  )}
                                </Droppable>
                              </div>
                            </div>
                          </div>
                        </div>
                      </DragDropContext>
                    </div>
                    {/* <div className="custom-footer mb-1 text-center">
                                            <button className="btn simulator-btn-info mx-2" type="type">Apply</button>
                                        </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        <div className="col-xl-6 cm-chart">
          <div className="card card-min-height" style={{
            height: `${window.innerHeight < 850
              ? window.innerHeight * 0.785
                : window.innerHeight * 0.84
              }px`,
          }}>
            <div className="" style={{ padding: '10px' }}>
              <div className="row align-items-center justify-content-sm-between  ef-drivers">
                <div
                  className="col-sm-12"
                  style={{ display: "flex", justifyContent: "space-between", border: "none" }}
                >
                  {false && <MultiCascaderH1 data={props.HFilters} Chartdata={props.Chartdata[0]} setChartdata={props.setChartdata[0]} setFetchCall={props.setFetchCall[0]} selectedCheckboxes={props.selectedCheckboxes} setSelectedCheckboxes={props.setSelectedCheckboxes} />}

                   <div className="row align-items-center ce-filter justify-content-start" >


                {true && (
                  <div className="col-sm-12 col-md-10 col-lg-6 d-flex align-items-center justify-content-space-between gap-2">
                    {props.DefaultStartDateComp1 &&
                      props.DefaultEndDateComp1 &&
                      props.startdate &&
                      props.enddate && (
                        <MultiCal
                          // key={refreshKey}
                          setDefaultStartDate={props.setDefaultStartDateComp1}
                          setDefaultEndDate={props.setDefaultEndDateComp1}
                          DefaultStartDate={props.DefaultStartDateComp1}
                          DefaultEndDate={props.DefaultEndDateComp1}
                          rangeStartDate={props.startdate}
                          rangeEndDate={props.enddate}
                          setTimeframe={props.setTimeframeComp1}
                          onChangeHandler={props.onChangeHandlerComp1}
                          TimeFramesAll={props.TimeFramesAllComp1}
                          timeframe={props.timeframeComp1}
                          SelectedChart={props.SelectedChartComp1}
                          setIsSubmitEnabled={setIsSubmitEnabled}
                          // handleRefreshClick={handleRefreshClick}
                          submittedStartDate={submittedStartDate}
                          submittedEndDate={submittedEndDate}
                          submittedTimeFrame={submittedTimeFrame}
                          isRefreshEnabled={isRefreshEnabled}
                        />
                      )}
                    <div className="">
                      <button
                        className="submit-btn textSize"
                        disabled={isSubmitEnabled}
                        // className="btn-class"
                        type="button"
                        style={{
                          // marginTop:'-40px !important',
                          // fontSize: "10px",
                          width: "80px",
                          height: "20px",
                        }}
                        onClick={() => {
                          setSubmittedStartDate(props.DefaultStartDateComp1);
                          setSubmittedEndDate(props.DefaultEndDateComp1);
                          setSubmittedTimeFrame(props.timeframeComp1);
                          setIsRefreshEnabled(false);
                          props.setFetchCallComp1(true);
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                )}
              </div>
                  <p className="card-title-lable" style={{ margin: "0 auto" }}>
                    {/* EFFECTIVENESS MEASUREMENT OVER TIME */}
                  </p>
                  {false && <MultiCascaderC1 data={props.CFilters} Chartdata={props.Chartdata[0]} setChartdata={props.setChartdata[0]} setFetchCall={props.setFetchCall[0]} selectedCheckboxes={props.selectedCheckboxesC} setSelectedCheckboxes={props.setSelectedCheckboxesC} />}
                  {/* for chart download */}
                  {(downloadFlag.chart ||
                    downloadFlag.data ||
                    downloadFlag.pdf) && (
                      <span className="flex-shrink-0 dropdown">
                        <Link
                          to="#"
                          className="d-flex align-items-center justify-content-end text-decoration-none dropdown-toggle text-white"
                          id="dropdownUser2"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {/* <p className="m-0 px-2">
                              {" "}

                              <img src={Download} alt="" style={{ float: "right", cursor: 'pointer', width: '25px', height: '25px' }} title="Download" />
                            </p> */}

                          <p className="m-0 px-2">
                            <svg
                              src={Download}
                              title="Download"
                              xmlns="http://www.w3.org/2000/svg"
                              width="25"
                              height="25"
                              className="bi bi-download icon-class"
                              viewBox="0 0 16 16"
                            >
                              <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                              <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                            </svg>
                          </p>
                        </Link>
                        <ul
                          className="dropdown-menu text-small shadow"
                          aria-labelledby="dropdownUser2"
                        >
                          {downloadFlag.chart && (
                            <li onClick={(e) => { }}>
                              <Link
                                className="dropdown-item"
                                onClick={(e) => {
                                  e.preventDefault();
                                  if (
                                    props.categoriesComp1.length > 0 &&
                                    props.seriesComp1.length > 0
                                  ) {
                                    //  setDownloadSpendschart(true)
                                    props.setIsDownloadingChart(true);
                                    props.setChartContainerID("Comp1");
                                    props.setDownloadedImageName(
                                      "Campaign Effectiveness"
                                    );
                                    props.setDownloadingChartTitle(
                                      "Campaign Effectiveness"
                                    );
                                    props.setHierarchy(props.BreadCrumbsComp1);
                                    props.setStartDate(
                                      props.DefaultStartDateComp1
                                    );
                                    props.setEndDate(props.DefaultEndDateComp1);
                                    props.setTimeFrameChart(props.timeframeComp1);
                                  } else {
                                    toast.error("Chartdata is not available.");
                                  }
                                }}
                              >
                                Download Chart
                              </Link>
                            </li>
                          )}

                          {downloadFlag.data && (
                            <li onClick={(e) => { }}>
                              <Link
                                className="dropdown-item"
                                onClick={(e) => props.downloadDataC1(e)}
                              >
                                Download Data
                              </Link>
                            </li>
                          )}
                          {downloadFlag.pdf && (
                            <li onClick={(e) => { }}>
                              <Link
                                className="dropdown-item"
                                onClick={(e) => props.downloadPDFC1(e)}
                              >
                                Download PDF
                              </Link>
                            </li>
                          )}
                        </ul>
                      </span>
                    )}
                  {/* for chart download */}
                </div>
              </div>
              {/* <div className="row align-items-center ce-filter justify-content-start" >


                {true && (
                  <div className="col-sm-12 col-md-10 col-lg-6 d-flex align-items-center justify-content-space-between gap-2">
                    {props.DefaultStartDateComp1 &&
                      props.DefaultEndDateComp1 &&
                      props.startdate &&
                      props.enddate && (
                        <MultiCal
                          // key={refreshKey}
                          setDefaultStartDate={props.setDefaultStartDateComp1}
                          setDefaultEndDate={props.setDefaultEndDateComp1}
                          DefaultStartDate={props.DefaultStartDateComp1}
                          DefaultEndDate={props.DefaultEndDateComp1}
                          rangeStartDate={props.startdate}
                          rangeEndDate={props.enddate}
                          setTimeframe={props.setTimeframeComp1}
                          onChangeHandler={props.onChangeHandlerComp1}
                          TimeFramesAll={props.TimeFramesAllComp1}
                          timeframe={props.timeframeComp1}
                          SelectedChart={props.SelectedChartComp1}
                          setIsSubmitEnabled={setIsSubmitEnabled}
                          // handleRefreshClick={handleRefreshClick}
                          submittedStartDate={submittedStartDate}
                          submittedEndDate={submittedEndDate}
                          submittedTimeFrame={submittedTimeFrame}
                          isRefreshEnabled={isRefreshEnabled}
                        />
                      )}
                    <div className="">
                      <button
                        className="submit-btn"
                        disabled={isSubmitEnabled}
                        // className="btn-class"
                        type="button"
                        style={{
                          // marginTop:'-40px !important',
                          fontSize: "10px",
                          width: "80px",
                          height: "20px",
                        }}
                        onClick={() => {
                          setSubmittedStartDate(props.DefaultStartDateComp1);
                          setSubmittedEndDate(props.DefaultEndDateComp1);
                          setSubmittedTimeFrame(props.timeframeComp1);
                          setIsRefreshEnabled(false);
                          props.setFetchCallComp1(true);
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                )}
              </div> */}

              <div className="row mt-1">
                <div className="col-sm-12">
                  <div className="row align-items-center sorting-btn-ui">
                    <div className="col-sm-4">
                      <ul>
                        <li onClick={props.DrillUpAll1} title="Drill Up">
                          <i className="fa fa-arrow-up icon-class" aria-hidden="true"></i>
                        </li>
                        <li onClick={props.DrillDownAll1} title="Drill Down">
                          <i
                            className="fa fa-arrow-down icon-class"
                            aria-hidden="true"
                          ></i>
                        </li>
                        <li onClick={props.DrillAll1} title="Expand All">
                          <i className="fa fa-sitemap icon-class" aria-hidden="true"></i>
                        </li>
                        <li
                          onClick={() => {
                            props.ResetAll1();
                            setFull(false);
                          }}
                          title="Reset"
                        >
                          <i className="fa icon-class" aria-hidden="true">
                            &#8635;
                          </i>
                        </li>
                        <li>
                          {full ? (
                            <i
                              className="fas fa-magnifying-glass-minus icon-class"
                              onClick={handler}
                              style={{
                                color: "white",
                                cursor: "pointer",
                              }}
                            ></i>
                          ) : (
                            <i
                              className="fas fa-magnifying-glass-plus icon-class"
                              onClick={handler}
                              style={{
                                color: "white",
                                cursor: "pointer",
                              }}
                            ></i>
                          )}
                        </li>
                      </ul>
                    </div>

                    {/* <div className="col-sm-8 custom-brdcrum">
                      {props.BreadCrumbsComp1.map(item => {
                        return returnDisplayName(item);
                      }).join(" / ")}
                    </div> */}

                    {/* <div className="col-sm-8 custom-brdcrum">
                      {props.BreadCrumbsComp1.map((item) => {
                        return displayNames[item];
                      }).join(" / ")}
                    </div> */}
                  </div>
                  <div className="col-sm-12" style={{display:'flex',alignContent:'center'}}>
                  <div
                      className="col-sm-6 custom-brdcrum2"
                      style={{
                        display: "flex",
                        alignContent: "center",
                        justifyContent: "start",
                        wordBreak: "break-word",
                        flexWrap: "wrap",
                        minHeight: "min-content",
                      }}
                    >
                      {props.newHierarchyBreadCrumb ||
                      props.newChannelBreadCrumb ? (
                        <>
                          <i
                            className="fas fa-filter"
                            style={{ marginRight: "5px", fontSize: "10px" }} // Adds spacing and adjusts icon size
                          ></i>
                          {props.newHierarchyBreadCrumb &&
                          props.newChannelBreadCrumb
                            ? `${props.newHierarchyBreadCrumb} / ${props.newChannelBreadCrumb}`
                            : props.newHierarchyBreadCrumb ||
                              props.newChannelBreadCrumb}
                        </>
                      ) : null}
                    </div>
 
 
                  <div className="col-sm-6 custom-brdcrum">
                      {props.BreadCrumbsComp1.map(item => {
                        return returnDisplayName(item);
                      }).join(" / ")}
                    </div>
                  </div>

                </div>
                
                <div className="col-sm-12">
                  <div
                    className=""
                    style={{ height: "100%" }}
                    id="Comp1"
                    ref={refComp1}
                  >
                    {props.categoriesComp1.length > 0 &&
                      props.seriesComp1.length > 0 ? (
                      <Suspense fallback={<div>Loading...</div>}>
                        <StackChart
                          categories={props.categoriesComp1}
                          series={props.seriesComp1}
                          xtitle={props.xtitleComp1}
                          ytitle={props.ytitleComp1}
                          charttitle={props.charttitleComp1}
                          DrillDown={props.DrillDownComp1}
                          DrillUp={props.DrillUpComp1}
                          height={CompHeight1}
                          setLoader={props.setLoader}
                          NotAllow={props.NotAllow}
                          divWidth={divWidth}
                          full={full}
                        />
                      </Suspense>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-6 cm-chart">
          <div className="card card-min-height" style={{
            height: `${window.innerHeight < 850
              ? window.innerHeight * 0.785
                : window.innerHeight * 0.84
              }px`,
          }}>
            <div className="" style={{ padding: '10px' }}>
              <div className="row align-items-center justify-content-sm-between  ef-drivers">
                <div
                  className="col-sm-12"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {false && <MultiCascaderH2 data={props.HFilters} Chartdata={props.Chartdata[1]} setChartdata={props.setChartdata[1]} setFetchCall={props.setFetchCall[1]} selectedCheckboxes={props.selectedCheckboxes} setSelectedCheckboxes={props.setSelectedCheckboxes} />}

                  <div className="row align-items-center ce-filter justify-content-start" >

{true && (
  <div className="col-sm-12 col-md-10 col-lg-4 d-flex align-items-center justify-content-space-between gap-2">
    {props.DefaultStartDateComp2 &&
      props.DefaultEndDateComp2 &&
      props.startdate &&
      props.enddate && (
        <MultiCal
          // key={refreshKey}
          setDefaultStartDate={props.setDefaultStartDateComp2}
          setDefaultEndDate={props.setDefaultEndDateComp2}
          DefaultStartDate={props.DefaultStartDateComp2}
          DefaultEndDate={props.DefaultEndDateComp2}
          rangeStartDate={props.startdate}
          rangeEndDate={props.enddate}
          setTimeframe={props.setTimeframeComp2}
          onChangeHandler={props.onChangeHandlerComp2}
          TimeFramesAll={props.TimeFramesAllComp2}
          timeframe={props.timeframeComp2}
          SelectedChart={props.SelectedChartComp2}
          setIsSubmitEnabled={setIsSubmitEnabled2}
          // handleRefreshClick={handleRefreshClick}
          submittedStartDate={submittedStartDate2}
          submittedEndDate={submittedEndDate2}
          submittedTimeFrame={submittedTimeFrame2}
          isRefreshEnabled={isRefreshEnabled2}
        />
      )}
    <div className="">
      <button
        className="submit-btn textSize"
        disabled={isSubmitEnabled2}
        // className="btn-class"
        
        type="button"
        style={{
          // marginTop:'-40px !important',
          fontSize: "10px",
          width: "80px",
          height: "20px",
        }}
        onClick={() => {
          setSubmittedStartDate2(props.DefaultStartDateComp2);
          setSubmittedEndDate2(props.DefaultEndDateComp2);
          setSubmittedTimeFrame2(props.timeframeComp2);
          setIsRefreshEnabled2(false);
          props.setFetchCallComp2(true);
        }}
      >
        Submit
      </button>
    </div>
  </div>
)}
</div>
                  <p className="card-title-lable" style={{ margin: "0 auto" }}>
                    {/* EFFECTIVENESS MEASUREMENT OVER TIME */}
                  </p>
                  {false && <MultiCascaderC2 data={props.CFilters} Chartdata={props.Chartdata[1]} setChartdata={props.setChartdata[1]} setFetchCall={props.setFetchCall[1]} selectedCheckboxes={props.selectedCheckboxesC} setSelectedCheckboxes={props.setSelectedCheckboxesC} />}
                  {(downloadFlag.chart ||
                    downloadFlag.data ||
                    downloadFlag.pdf) && (
                      <span className="flex-shrink-0 dropdown">
                        <Link
                          to="#"
                          className="d-flex align-items-center justify-content-end text-decoration-none dropdown-toggle text-white"
                          id="dropdownUser2"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {/* <p className="m-0 px-2">
                              {" "}

                              <img src={Download} alt="" style={{ float: "right", cursor: 'pointer', width: '25px', height: '25px' }} title="Download" />
                            </p> */}

                          <p className="m-0 px-2">
                            <svg
                              src={Download}
                              title="Download"
                              xmlns="http://www.w3.org/2000/svg"
                              width="25"
                              height="25"
                              className="bi bi-download icon-class"
                              viewBox="0 0 16 16"
                            >
                              <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                              <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                            </svg>
                          </p>
                        </Link>
                        <ul
                          className="dropdown-menu text-small shadow"
                          aria-labelledby="dropdownUser2"
                        >
                          {downloadFlag.chart && (
                            <li onClick={(e) => { }}>
                              <Link
                                className="dropdown-item"
                                onClick={(e) => {
                                  e.preventDefault();
                                  if (
                                    props.categoriesComp2.length > 0 &&
                                    props.seriesComp2.length > 0
                                  ) {
                                    //  setDownloadSpendschart(true)
                                    props.setIsDownloadingChart(true);
                                    props.setChartContainerID("Comp2");
                                    props.setDownloadedImageName(
                                      "Campaign Effectiveness"
                                    );
                                    props.setDownloadingChartTitle(
                                      "Campaign Effectiveness"
                                    );
                                    props.setHierarchy(props.BreadCrumbsComp2);
                                    props.setStartDate(
                                      props.DefaultStartDateComp2
                                    );
                                    props.setEndDate(props.DefaultEndDateComp2);
                                    props.setTimeFrameChart(props.timeframeComp2);
                                  } else {
                                    toast.error("Chartdata is not available.");
                                  }
                                }}
                              >
                                Download Chart
                              </Link>
                            </li>
                          )}

                          {downloadFlag.data && (
                            <li onClick={(e) => { }}>
                              <Link
                                className="dropdown-item"
                                onClick={(e) => props.downloadDataC2(e)}
                              >
                                Download Data
                              </Link>
                            </li>
                          )}
                          {downloadFlag.pdf && (
                            <li onClick={(e) => { }}>
                              <Link
                                className="dropdown-item"
                                onClick={(e) => props.downloadPDFC2(e)}
                              >
                                Download PDF
                              </Link>
                            </li>
                          )}
                        </ul>
                      </span>
                    )}
                </div>
              </div>

              {/* <div className="row align-items-center ce-filter justify-content-start" >

                {true && (
                  <div className="col-sm-12 col-md-10 col-lg-4 d-flex align-items-center justify-content-space-between gap-2">
                    {props.DefaultStartDateComp2 &&
                      props.DefaultEndDateComp2 &&
                      props.startdate &&
                      props.enddate && (
                        <MultiCal
                          // key={refreshKey}
                          setDefaultStartDate={props.setDefaultStartDateComp2}
                          setDefaultEndDate={props.setDefaultEndDateComp2}
                          DefaultStartDate={props.DefaultStartDateComp2}
                          DefaultEndDate={props.DefaultEndDateComp2}
                          rangeStartDate={props.startdate}
                          rangeEndDate={props.enddate}
                          setTimeframe={props.setTimeframeComp2}
                          onChangeHandler={props.onChangeHandlerComp2}
                          TimeFramesAll={props.TimeFramesAllComp2}
                          timeframe={props.timeframeComp2}
                          SelectedChart={props.SelectedChartComp2}
                          setIsSubmitEnabled={setIsSubmitEnabled2}
                          // handleRefreshClick={handleRefreshClick}
                          submittedStartDate={submittedStartDate2}
                          submittedEndDate={submittedEndDate2}
                          submittedTimeFrame={submittedTimeFrame2}
                          isRefreshEnabled={isRefreshEnabled2}
                        />
                      )}
                    <div className="">
                      <button
                        className="submit-btn"
                        disabled={isSubmitEnabled2}
                        // className="btn-class"
                        type="button"
                        style={{
                          // marginTop:'-40px !important',
                          fontSize: "10px",
                          width: "80px",
                          height: "20px",
                        }}
                        onClick={() => {
                          setSubmittedStartDate2(props.DefaultStartDateComp2);
                          setSubmittedEndDate2(props.DefaultEndDateComp2);
                          setSubmittedTimeFrame2(props.timeframeComp2);
                          setIsRefreshEnabled2(false);
                          props.setFetchCallComp2(true);
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                )}
              </div> */}

              <div className="row mt-1" >
                <div className="col-sm-12">
                  <div className="row align-items-center sorting-btn-ui">
                    <div className="col-sm-4">
                      <ul>
                        <li onClick={props.DrillUpAll2} title="Drill Up">
                          <i className="fa fa-arrow-up icon-class" aria-hidden="true"></i>
                        </li>
                        <li onClick={props.DrillDownAll2} title="Drill Down">
                          <i
                            className="fa fa-arrow-down icon-class"
                            aria-hidden="true"
                          ></i>
                        </li>
                        <li onClick={props.DrillAll2} title="Expand All">
                          <i className="fa fa-sitemap icon-class" aria-hidden="true"></i>
                        </li>
                        <li
                          onClick={() => {
                            props.ResetAll2();
                            setFull2(false);
                          }}
                          title="Reset"
                        >
                          <i className="fa icon-class" aria-hidden="true">
                            &#8635;
                          </i>
                        </li>
                        <li>
                          {full ? (
                            <i
                              className="fas fa-magnifying-glass-minus icon-class"
                              onClick={handler2}
                              style={{
                                color: "white",
                                cursor: "pointer",
                              }}
                            ></i>
                          ) : (
                            <i
                              className="fas fa-magnifying-glass-plus icon-class"
                              onClick={handler2}
                              style={{
                                color: "white",
                                cursor: "pointer",
                              }}
                            ></i>
                          )}
                        </li>
                      </ul>
                    </div>
                   

                    {/* <div className="col-sm-8 custom-brdcrum">
                      {props.BreadCrumbsComp2.map((item) => {
                        return displayNames[item];
                      }).join(" / ")}
                    </div> */}
                  </div>
                  <div className="col-sm-12" style={{display:'flex',alignContent:'center'}}>
                  <div
                      className="col-sm-6 custom-brdcrum"
                      style={{
                        display: "flex",
                        alignContent: "center",
                        justifyContent: "start",
                      }}
                    >
                      {props.newHierarchyBreadCrumb2 ||
                      props.newChannelBreadCrumb2 ? (
                        <>
                          <i
                            className="fas fa-filter"
                            style={{ marginRight: "5px", fontSize: "10px" }} // Adds spacing and adjusts icon size
                          ></i>
                          {props.newHierarchyBreadCrumb2 &&
                          props.newChannelBreadCrumb2
                            ? `${props.newHierarchyBreadCrumb2} / ${props.newChannelBreadCrumb2}`
                            : props.newHierarchyBreadCrumb2 ||
                              props.newChannelBreadCrumb2}
                        </>
                      ) : null}
                    </div>
 
                  <div className="col-sm-6 custom-brdcrum">
                      {props.BreadCrumbsComp2.map(item => {
                        return returnDisplayName(item);
                      }).join(" / ")}
                    </div>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div
                    className=""
                    ref={refComp2}
                    style={{ height: "100%" }}
                    id="Comp2"
                  >
                    {props.categoriesComp2.length > 0 &&
                      props.seriesComp2.length > 0 ? (
                      <Suspense fallback={<div>Loading...</div>}>
                        <StackChart
                          categories={props.categoriesComp2}
                          series={props.seriesComp2}
                          xtitle={props.xtitleComp2}
                          ytitle={props.ytitleComp2}
                          charttitle={props.charttitleComp2}
                          DrillDown={props.DrillDownComp2}
                          DrillUp={props.DrillUpComp2}
                          height={CompHeight2}
                          NotAllow={props.NotAllow}
                          setLoader={props.setLoader}
                          divWidth={divWidth2}
                          full={full2}
                        />
                      </Suspense>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row custom-note">
        <div className="col-sm-1">
          {props.FooterNotes !== "" ? (
            <>
              <i
                className="fas fa-mail-bulk chart_info_icon footer-notes-icon icon-class"
                style={{ display: "flex", alignItems: "center", justifyContent: "center", fontSize: '15px', marginLeft: '6px', marginBottom: '10px' }}
                id="clickable"
              ></i>
              <Tooltip anchorSelect="#clickable" clickable>
                {/* <div className="footer-notes">{FooterNotes}</div> */}
                <div
                  className="footer-notes"
                  style={{ height: 'max-content' }}
                  dangerouslySetInnerHTML={{ __html: props.FooterNotes }}
                ></div>
              </Tooltip>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Comparison;