import React from "react";
import FusionCharts from "fusioncharts";
import PowerCharts from "fusioncharts/fusioncharts.powercharts";
import ExcelExport from "fusioncharts/fusioncharts.excelexport";
import charts from "fusioncharts/fusioncharts.charts";
import ReactFusioncharts from "react-fusioncharts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";

ReactFusioncharts.fcRoot(
  FusionCharts,
  charts,
  ExcelExport,
  PowerCharts,
  FusionTheme
);
const WaterfallChart = (props) => {
 const dataSource = {
    chart: {
        caption: "",
        xAxisName: props.data.xtitle,
        xAxisNameFontColor: localStorage.getItem("CurrentTheme") !== null ? localStorage.getItem("CurrentTheme") === "Dark" ?
            "#D6FF41" :
            localStorage.getItem("ColorCode") === "Lavender" ?
                "#9384bf" :
                localStorage.getItem("ColorCode") === "Sapphire" ?
                    "#6b75af" :
                    localStorage.getItem("ColorCode") === "Teal" ?
                        "#57898c" :
                        localStorage.getItem("ColorCode") === "Cyan" ?
                            "#21afc2" :
                            localStorage.getItem("ColorCode") === "Goldenrod" ?
                                "#dca25b" : "" : "#D6FF41",
        yAxisName: props.data.ytitle,
        yAxisNameFontColor: localStorage.getItem("CurrentTheme") !== null ? localStorage.getItem("CurrentTheme") === "Dark" ?
            "#D6FF41" :
            localStorage.getItem("ColorCode") === "Lavender" ?
                "#9384bf" :
                localStorage.getItem("ColorCode") === "Sapphire" ?
                    "#6b75af" :
                    localStorage.getItem("ColorCode") === "Teal" ?
                        "#57898c" :
                        localStorage.getItem("ColorCode") === "Cyan" ?
                            "#21afc2" :
                            localStorage.getItem("ColorCode") === "Goldenrod" ?
                                "#dca25b" : "" : "#D6FF41",

        theme: "fusion",
        numberSuffix: "%",
        exportenabled: 0,
        exportformats:
            "PNG=Export as High Quality Image|PDF=Export as Printable Document|XLS=Export Chart Data|CSV=Export Chart Data as csv",
        showSumAtEnd: "0",
        yAxisMinValue:
            props.data.chartdata.length > 0
                ? Math.floor(props.data.chartdata[0].value)
                : 0,
        bgColor: localStorage.getItem("CurrentTheme") !== null ? localStorage.getItem("CurrentTheme") === "Dark" ? "#1c2427" : "#ffffff" : "#1c2427",
        baseFontColor: localStorage.getItem("CurrentTheme") !== null ? localStorage.getItem("CurrentTheme") === "Dark" ? "#ffffff" : "#1c2427" : "#ffffff",
        toolTipColor: "#000000",
        toolTipBgColor: "#ffffff",
        // showToolTip: "0", // Disable tooltips
        xaxislinecolor: "#7D9CA6",
        yaxislinecolor: "#7D9CA6",
        showxaxisline: "1",
        showyaxisline: "1",
        decimals: "1", // Show up to 1 decimal place
        showvalues: "1",
        valueFontColor: localStorage.getItem("CurrentTheme") !== null ? localStorage.getItem("CurrentTheme") === "Dark" ? "#ffffff" : "#1c2427" : "#ffffff",
        chartLeftMargin: "0",
        chartTopMargin: "10",
        chartRightMargin: "0",
        chartBottomMargin: "0",
        // Disable hover effects and crosshair
        showHoverEffect: "0",
        plotHoverEffect: "0",
        crossLineAlpha: "0",
        drawCrossLineOnTop: "0",
        // Disable tooltips if you want to remove all hover interactions
        // showToolTip: "0"
        // crosshair:0,
        

    },
    axes: [
      {
          type: 'number',
          position: 'left',
          
          crossline: {
            enabled: 0
        },
        showHoverEffect: 0
      },
  ],
    data: props.data.chartdata
};


  return (
    <div>
      <ReactFusioncharts
        type="waterfall2d"
        // width={props.ChartWidth}
        width='100%'
        height={props.ChartHeight}
        dataFormat="JSON"
        dataSource={dataSource}
        fcEvent-dataplotRollOver={props.dataplotRollOverHandler}
      />
    </div>
  );
};

export default WaterfallChart;