import React, { useState, useEffect, useRef } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import MultiCascader from "../Components/MultiCascaderComp";
import MultiCascaderCompH from "../Components/MultiCascaderCompH";
import configDriven from "../ConfirgDriven";
import "@fortawesome/fontawesome-free/css/all.min.css"; // Import Font Awesome CSS

import { Box } from "@mui/material";
import zIndex from "@mui/material/styles/zIndex";

const AllHeirarchyFilter = (props) => {
  const [reset, setReset] = useState(false);
  const [fetchData, setFetchData] = useState(false);
  const [isFirstVisit, setIsFirstVisit] = useState(true);
  const [isFirstVisit1, setIsFirstVisit1] = useState(true);
  const [hierarchyCheckBox, setHierarchyCheckBox] = useState(
    props?.selectedCheckboxes
  );

  const [hierarchyCheckBox2, setHierarchyCheckBox2] = useState(
    props?.selectedCheckboxes
  );
  const dropdownRef = useRef(null);
  const dropRef = useRef(null);

  // const [isopen, setIsOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const barRef = useRef(null);
  const scrollRef = useRef(null);

  // const dropdownRef = useRef(null);

  // const toggleDropdown = () => {
  //   setIsOpen(!isOpen);
  // };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    // setFetchData(!fetchData);
  };

  // useEffect(()=>{
  //   console.log("button clicked", isOpen)

  // },[isOpen])
  useEffect(() => {
    const handleScroll = () => {
      if (isOpen) {
        setIsOpen(false);
      }
    };

    // Add event listeners
    const container = scrollRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }
    window.addEventListener('scroll', handleScroll);

    // Clean up function
    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && barRef.current) {
      const rect = barRef.current.getBoundingClientRect();
      setDropdownPosition({
        top: rect.bottom,
        left: rect.left,
      });
    }
  }, [isOpen]);

  const enableFetching = () => {
    setFetchData(true); // Enable fetching on any operation
    // props.setFetchCall(true);
    // props.setFetchCall2(true);
  };
  console.log("props?.enableFetching", enableFetching);


  console.log("SelectedCheckboxes ", hierarchyCheckBox);
  console.log("props HeirarchyFIlter ", props);
  const bgColor = (node) => {
    if (props.Chartdata.length === 1) {
      if (props.Chartdata[0].hasOwnProperty(node)) {
        return props.Chartdata[0][node].Filter?.length > 0;
      }
    } else if (props.Chartdata.length === 2) {
      if (props.Chartdata[0].hasOwnProperty(node)) {
        if (props.Chartdata[0][node].Filter?.length > 0) {
          return true;
        } else if (props.Chartdata[1].hasOwnProperty(node)) {
          return props.Chartdata[1][node].Filter?.length > 0;
        }
      }
    }
    return false;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const newItems = Array.from(props.ActiveFilters);
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);
    props.setActiveFilters(newItems);
    console.log("newItems: ", newItems);
    props.updateChartData(newItems);
  };

  // const data = JSON.parse(localStorage.getItem("allBrands"));

  const displayNames = JSON.parse(localStorage.getItem("displayNames"));
  const returnDisplayName = (id) => {
    if (configDriven.show_display_name && displayNames?.hasOwnProperty(id)) {
      return displayNames[id];
    } else {
      return id;
    }
  };

  const styles = {
    bar: {
      display: "flex",
      marginBottom: "0.2rem",
      // padding: '1px 8px',
      justifyContent: "space-between",
      alignItems: "center",
      backgroundColor: "#1c2427",
      marginLeft: "4px",
      marginRight: "2px",
      borderRadius:'5px',
      padding:'3px',
      height: "20px",
      color: "white",
      position: "relative",
    },
    barTitle: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      // fontSize: "10px",
    },
    dropdown: {
      // fontSize: "10px",
      position: "static",
    },
    dropdownButton: {
      backgroundColor: "transparent",
      color: "white",
      border: "none",
      cursor: "pointer",

    },
    dropdownContent: {
      display: "flex",
      flexDirection: "row",
      position: "fixed",
      top: `${dropdownPosition.top}px`,
      left: `${dropdownPosition.left}px`,
      backgroundColor: "#1c2427",
      color: "#d6ff41",
      // minWidth: "200px",
      maxWidth: "90%",
      maxHeight: "80vh",
      overflowY: "auto",
      boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
      zIndex: 1000,
      padding: "2px",
      borderRadius: "4px",
    },
    component: {
      position: "relative",
      zIndex: 1000,
      backgroundColor: "#1c2427",
      margin: "1px 0",
    },
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        dropRef.current &&
        !dropRef.current.contains(event.target)
      ) {
        setIsOpen(false); // Close dropdown if click outside
        setFetchData(false);
      }
    };

    // Add event listener when component mounts
    document.addEventListener("click", handleClickOutside, true);

    // Remove event listener when component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <div
      className={
        props.OnlyHeirarchyFilter
          ? "col-xl-12"
          : props.ActiveChart === "Comparison"
            ? "col-xl-5"
            : "col-xl-5"
      }
      style={{
        borderRadius: "10px",

        marginTop: 0,
        height: "max-content",
      }}
    >
      <div className="heirarchy-class" style={{ border: "none" }} ref={scrollRef}>
        <div className="heirarchy-class-div" style={{ position: "relative" }}>
          {/* <div className="row align-items-center" style={{border: "none"}}> */}

          <h6
            className="m-0 text-center cm-filter-title textSize"
            // style={{ fontSize: "10px !important" }}
          >
            Hierarchy Filter
          </h6>

          {/* <p className="m-0 px-1" style={{ color: "black", fontSize: "10px" }}>
            <i
              className="fas fa-sync-alt"
              style={{
                cursor:
                  JSON.stringify(hierarchyCheckBox) === "{}"
                    ? "default"
                    : "pointer",
                color:
                  JSON.stringify(hierarchyCheckBox) === "{}"
                    ? "black"
                    : "white",
              }}
              onClick={() => {
                if (JSON.stringify(hierarchyCheckBox) !== "{}") {
                  setHierarchyCheckBox({});
                  props.setFetchCall(true);
                  setReset(true);
                }

                // innerWidth 1470
                // innerHeight 799
              }}
              aria-hidden="true"
            ></i>
          </p> */}

          {(props.ActiveChart === undefined ||
            props.ActiveChart === "Measurement" ||
            props.ActiveChart === "Comparison" ||
            props.ActiveChart === "Trend_Analysis") && (
              <div style={{ paddingLeft: "0.1rem" }}>
                {/* <MultiCascader
                  data={props.HFilters}
                  Chartdata={props.Chartdata}
                  setChartdata={props.setChartdata}
                  setFetchCall={props.setFetchCall}
                  selectedCheckboxes={hierarchyCheckBox}
                  setSelectedCheckboxes={setHierarchyCheckBox}
                  fiexedFilters={props.fiexedFilters}
                  ActiveFilters={props.ActiveFilters}
                  reset={reset}
                  setReset={setReset}
                /> */}
                <div style={styles.bar}
                  // className="form-control"
                  className="filter-class-disable"
                  ref={barRef}>
                  <span  className="textSize" style={styles.barTitle}>Select Hierarchy</span>
                  <div className="textSize" style={styles.dropdown}>
                    <button
                      disabled={true}
                      style={styles.dropdownButton}
                      onClick={toggleDropdown}
                      ref={dropRef}
                    >
                      {isOpen ? (
                        <i
                          // onClick={toggleDropdown}
                          className="fas fa-chevron-up"
                        />
                      ) : (
                        <i
                          // onClick={toggleDropdown}
                          className="fas fa-chevron-down"
                        />
                      )}
                    </button>
                    {isOpen && (
                      <div style={styles.dropdownContent} ref={dropdownRef}>
                        <div style={styles.component}>
                          <MultiCascaderCompH
                            data={props.HFilters}
                            setNewHierarchyBreadCrumb={props.setNewHierarchyBreadCrumb}
                            Chartdata={props.Chartdata}
                            setChartdata={props.setChartdata}
                            setFetchCall={props.setFetchCall}
                            selectedCheckboxes={props.selectedCheckboxesH1}
                            setSelectedCheckboxes={props.setSelectedCheckboxesH1}
                            fiexedFilters={props.fiexedFilters}
                            ActiveFilters={props.ActiveFilters}
                            isFirstVisit={isFirstVisit}
                            setIsFirstVisit={setIsFirstVisit}
                            reset={reset}
                            fetchData={fetchData}
                            enableFetching={enableFetching}
                            setReset={setReset}
                            placeholder={"First Chart"}
                          />
                        </div>
                        <div style={styles.component}>
                          <MultiCascader
                            data={props.HFilters}
                            Chartdata={props.Chartdata2}
                            setNewHierarchyBreadCrumb2={props.setNewHierarchyBreadCrumb2}
                            setChartdata={props.setChartdata2}
                            setFetchCall={props.setFetchCall2}
                            selectedCheckboxes={props.selectedCheckboxesH2}
                            setSelectedCheckboxes={props.setSelectedCheckboxesH2}
                            fiexedFilters={props.fiexedFilters}
                            ActiveFilters={props.ActiveFilters}
                            isFirstVisit={isFirstVisit1}
                            setIsFirstVisit={setIsFirstVisit1}
                            fetchData={fetchData}
                            enableFetching={enableFetching}
                            reset={reset}
                            setReset={setReset}
                            placeholder={"Second Chart"}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          <div className="d-flex">
            {props.Fixed.map((item, index) => (
              <li
                key={index}
                style={{ marginLeft: "5px" }}
                className={
                  true
                    ? "cm-filter-option-li-filter-active textSize"
                    : "cm-filter-option-li textSize"
                }
              >
                <span
                  className="btn cm-drag-btn p-1 mx-2 active filter-class-item"
                  style={{}}
                >
                  <p
                    className="m-0 text-left px-1 textSize"
                    // style={{ fontSize: "10px" }}
                  >
                    {returnDisplayName(item.id)}
                  </p>
                  <p className="m-0 px-1">
                    {/* <i className="fa fa-minus" aria-hidden="true"></i> */}
                  </p>
                </span>
              </li>
            ))}
          </div>
          <div
            id="scroller"
            className="d-flex"
            style={{ height: "max-content" }}
          >
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="changeable" direction="horizontal">
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    {props.ActiveFilters.map((item, index) => (
                      <Draggable
                        key={item.name}
                        draggableId={item.name.toString()}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                          >
                            <li
                              key={index}
                              className={""}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "35px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                              onClick={() => props.RemoveHeirarchyFilter(item)}
                            >
                              <span
                                className="btn cm-drag-btn p-1 mx-2 active  filter-class-item filter-class-item-active"
                                style={{ height: "20px" }}
                              >
                                <p
                                  className="m-0 text-left px-1 textSize"
                                  // style={{ fontSize: "10px" }}
                                >
                                  {returnDisplayName(item.name)}
                                </p>
                                <p className="m-0 px-1">
                                  <i
                                    className="fa fa-minus"
                                    aria-hidden="true"
                                  ></i>
                                </p>
                              </span>
                            </li>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            {props.AllFilters.map((item, index) =>
              !props.IsInAllHeirarchyFilters(item) ? (
                <li
                  key={index}
                  className={""}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "35px",
                  }}
                  onClick={() => {
                    setFetchData(false);
                    props.AddHeirarchyFilter(index);
                  }}
                >
                  <span className="btn cm-drag-btn p-1 mx-2 active  filter-class-item filter-class-item-inactive">
                    <p
                      className="m-0 text-left px-1 textSize"
                      style={{
                        color: "white !important",
                        // fontSize: "10px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {returnDisplayName(item.name)}
                    </p>
                    <p
                      className="m-0 px-1"
                      style={{ color: "white !important" }}
                    >
                      <i className="fa fa-plus" aria-hidden="true"></i>
                    </p>
                  </span>
                </li>
              ) : null
            )}
          </div>

          {/* {parseInt(props.ShowComp) === 1 &&
          props.SelectedChart === "overview" ? (
            <div
            className="d-flex align-items-center gap-2 heirarchy-class-div"
            style={{ position: "absolute", right: 0, width: 200 }}
          >
            <h6 className="m-0 cm-filter-title">Show Competition</h6>
            <input
              type="checkbox"
              className=""
              name="check"
              style={{marginTop:'-5px',cursor:'pointer'}}
              checked={parseInt(props.ShowCompValue) === 1}
              onChange={(e) => {
                if (parseInt(props.ShowCompValue) === 1) {
                  props.setShowCompValue(0);
                } else {
                  props.setShowCompValue(1);
                }
                props.setFetchCall(true);
              }}
            />
          </div>
          
        ) : null} */}
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default AllHeirarchyFilter;
