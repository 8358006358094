import React, { useEffect, useState, useRef } from "react";


const Table = (props) => {
    console.log("props Table ",props);
    return (
    <>
<div className="row">
<div className="col-sm-12">
  <div className="table-responsive p-0 analytics-table">
    {JSON.stringify(props.data) !== "{}" ? (
      <table className="table table-bordered text-center">
        <thead>
          <tr>
            {props.data.headers.map(
              (item, index) => {
                return (
                  <th key={index} scope="col">
                    {item}
                  </th>
                );
              }
            )}
          </tr>
        </thead>
        <tbody>
          {props.data.rawdata.map((item, index) => {
            return (
              <tr key={index}>
                {item.rodata.map((item1, index1) => {
                  return (
                    <td
                      key={index + index1}
                      style={{ color: item1.color,
                        background: index === 0 ? '#d6ff41' : ''  ,
                        color: index === 0 ? '#000000' : item1.color ,
                        textAlign:'left'

                       }}
                    >
                      {item1.value}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    ) : null}
  </div>
</div>
</div>

</>
    )
}

export default Table;