import React, { useState, useEffect, useRef } from "react";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import configDriven from "../ConfirgDriven";

const Cascader = ({
  data,
  searchValue,
  setSearchValue,
  selectedPath,
  setSelectedPath,
  setIdList,
  pathName,
  selectedCheckboxes,
  setSelectedCheckboxes,
  flattenedData,
  searchResults,
}) => {
  const displayNames = JSON.parse(localStorage.getItem("displayNames"));

  const returnDisplayName = (key) => {
    const parts = key.split("-");
    // Map each part using displayNames and join them with a -
    return parts.map(part => displayNames[part] || part).join("/");
  };
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedExpandIcon, setSelectedExpandIcon] = useState(null);

  // Handle click on category button
  const handleCategoryButtonClick = (categoryId) => {
    const pathSegments = categoryId.split("-");
    const newPath = pathSegments.map((segment, index) =>
      pathSegments.slice(0, index + 1).join("-")
    );

    if (categoryId !== selectedCategory) {
      setSelectedCategory(null);
      setSelectedExpandIcon(null);
      setSelectedPath(newPath);
      setSelectedCategory(categoryId);
      setSelectedExpandIcon(categoryId);
    } else {
      setSelectedPath((prevSelectedPath) => {
        if (prevSelectedPath.length >= newPath.length) {
          return newPath.slice(0, pathSegments.length);
        }
        return newPath;
      });

      setSelectedCategory((prevSelectedCategory) => {
        const prevPathSegments = prevSelectedCategory
          ? prevSelectedCategory.split("-")
          : [];
        if (prevPathSegments.length >= pathSegments.length) {
          return pathSegments.join("-");
        }
        return prevSelectedCategory;
      });
    }
  };

  // Handle click on expand icon (+ or -)
  const handleExpandIconClick = (categoryId) => {
    if (categoryId === selectedCategory) {
      setSelectedCategory(null);
      setSelectedExpandIcon(null);
    } else {
      setSelectedCategory(categoryId);
      setSelectedExpandIcon(categoryId);
    }

    const pathSegments = categoryId.split("-");
    const newPath = pathSegments.map((segment, index) =>
      pathSegments.slice(0, index + 1).join("-")
    );

    if (categoryId !== selectedCategory) {
      setSelectedCategory(null);
      setSelectedExpandIcon(null);
      setSelectedPath(newPath);
      setSelectedCategory(categoryId);
      setSelectedExpandIcon(categoryId);
    } else {
      setSelectedPath((prevSelectedPath) => {
        if (prevSelectedPath.length >= newPath.length) {
          return newPath.slice(0, pathSegments.length);
        }
        return newPath;
      });

      setSelectedCategory((prevSelectedCategory) => {
        const prevPathSegments = prevSelectedCategory
          ? prevSelectedCategory.split("-")
          : [];
        if (prevPathSegments.length >= pathSegments.length) {
          return pathSegments.join("-");
        }
        return prevSelectedCategory;
      });
    }
  };

  // const handleSearchResultCheckboxClickWrapper = (result)=>{
  //   handleSearchResultCheckboxClick(result);
  // };

  const selectedIds = Object.keys(selectedCheckboxes)
    .filter((id) => selectedCheckboxes[id] === true)
    .filter((id) => {
      return !id.endsWith("-all") && id !== "all";
    });



  const handleCheckboxClick = (categoryId) => {
    setSearchValue(categoryId);
    const pathSegments = categoryId.split("-");
    const newPath = pathSegments.map((segment, index) =>
      pathSegments.slice(0, index + 1).join("-")
    );

    if (categoryId !== selectedCategory) {
      setSelectedCategory(null);
      setSelectedExpandIcon(null);
      setSelectedPath(newPath);
      setSelectedCategory(categoryId);
      setSelectedExpandIcon(categoryId);
    } else {
      setSelectedPath((prevSelectedPath) => {
        if (prevSelectedPath.length >= newPath.length) {
          return newPath.slice(0, pathSegments.length);
        }
        return newPath;
      });

      setSelectedCategory((prevSelectedCategory) => {
        const prevPathSegments = prevSelectedCategory
          ? prevSelectedCategory.split("-")
          : [];
        if (prevPathSegments.length >= pathSegments.length) {
          return pathSegments.join("-");
        }
        return prevSelectedCategory;
      });
    }
    // setSearchValue("");
    setSelectedCheckboxes((prev) => {
      const isChecked = prev[categoryId];
      const updatedCheckboxes = { ...prev, [categoryId]: !isChecked };

      const updateParentCheckboxes = (childId) => {
        const childItem = flattenedData.find((item) => item.id === childId);
        if (childItem) {
          const parentId = childItem.parentId;
          if (parentId) {
            const siblings = flattenedData.filter(
              (item) => item.parentId === parentId && !item.id.endsWith("-all")
            );
            const allSiblingsChecked = siblings.every(
              (sibling) => updatedCheckboxes[sibling.id] === true
            );
            const someSiblingsChecked = siblings.some(
              (sibling) => updatedCheckboxes[sibling.id] === true
            );
            const anySiblingsIndeterminate = siblings.some(
              (sibling) => updatedCheckboxes[sibling.id] === "indeterminate"
            );

            // Updated logic to prevent setting parent to true if all children are true
            if (allSiblingsChecked) {
              updatedCheckboxes[parentId] = "indeterminate";
            } else if (anySiblingsIndeterminate || someSiblingsChecked) {
              updatedCheckboxes[parentId] = "indeterminate";
            } else {
              updatedCheckboxes[parentId] = false;
            }

            if (parentId.endsWith("-all")) {
              updatedCheckboxes[parentId.replace("-all", "")] = allSiblingsChecked;
            } else {
              updatedCheckboxes[`${parentId}-all`] = allSiblingsChecked;
            }

            if (parentId !== "all") {
              updateParentCheckboxes(parentId);
            } else {
              const rootLevelItems = flattenedData.filter(
                (item) => !item.parentId
              );
              const allRootItemsUnchecked = rootLevelItems.every(
                (item) => updatedCheckboxes[item.id] === false
              );
              const someRootItemsChecked = rootLevelItems.some(
                (item) => updatedCheckboxes[item.id] === true
              );
              const anyRootItemsIndeterminate = rootLevelItems.some(
                (item) => updatedCheckboxes[item.id] === "indeterminate"
              );

              if (allRootItemsUnchecked) {
                updatedCheckboxes["all"] = false;
              } else if (anyRootItemsIndeterminate || someRootItemsChecked) {
                updatedCheckboxes["all"] = "indeterminate";
              } else {
                updatedCheckboxes["all"] = true;
              }
            }
          }
        }
      };

      if (categoryId === "all") {
        flattenedData.forEach((item) => {
          updatedCheckboxes[item.id] = !isChecked;
        });
      } else if (categoryId.endsWith("-all")) {
        const categoryLevel = categoryId.replace("-all", "");
        flattenedData.forEach((item) => {
          if (item.id.startsWith(categoryLevel) && !item.id.includes("-all")) {
            updatedCheckboxes[item.id] = !isChecked;
          }
        });

        flattenedData.forEach((item) => {
          if (item.id.endsWith("-all")) {
            const levelPrefix = item.id.replace("-all", "");
            const levelItems = flattenedData.filter(
              (i) => i.parentId === levelPrefix && !i.id.endsWith("-all")
            );
            const allLevelItemsChecked = levelItems.every(
              (i) => updatedCheckboxes[i.id] === true
            );
            const someLevelItemsChecked = levelItems.some(
              (i) => updatedCheckboxes[i.id] === true
            );
            const anyLevelItemsIndeterminate = levelItems.some(
              (i) => updatedCheckboxes[i.id] === "indeterminate"
            );

            // Updated logic to prevent setting parent to true if all children are true
            if (allLevelItemsChecked) {
              updatedCheckboxes[item.id] = "indeterminate";
            } else if (anyLevelItemsIndeterminate || someLevelItemsChecked) {
              updatedCheckboxes[item.id] = "indeterminate";
            } else {
              updatedCheckboxes[item.id] = false;
            }
          }
        });
      }

      updateParentCheckboxes(categoryId);

      return updatedCheckboxes;
    });
  };


  const getCheckboxIcon = (categoryId) => {
    console.log("categoryID", categoryId);
    if (categoryId?.access === 0) {
      return null;
    }
    if (selectedCheckboxes[categoryId] === "indeterminate") {
      return (
        <IndeterminateCheckBoxIcon
          sx={{ color: "#d6ff41", fontSize: "13px" }}
        />
      );
    }
    return selectedCheckboxes[categoryId] ? (
      <CheckBoxIcon sx={{ color: "#d6ff41", fontSize: "13px" }} />
    ) : (
      <CheckBoxOutlineBlankIcon sx={{ color: "#d6ff41", fontSize: "13px" }} />
    );
  };

  return (
    <div style={{ display: "flex" }}>
      <div
        style={{
          padding: "4px 0",
          // height: "385px",
          backgroundColor: "#1c2427",
        }}
      >
        {data.map((category) => (
          <div style={{ marginBottom: "4px", width: "100%" }} key={category.id}>
            <div style={{ display: "flex", width: "100%" }}>
              <div
                style={{
                  color: selectedPath.includes(category.id) ? "black" : "white",
                  padding: "0 10px",
                  fontSize: "10wpx",
                  cursor: "pointer",
                  backgroundColor: selectedPath.includes(category.id)
                    ? "#4A808080"
                    : selectedExpandIcon === category.id
                      ? "#4A808080"
                      : "#1c2427",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
                onClick={() => handleCategoryButtonClick(category.id)}
              >
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCheckboxClick(category.id);
                  }}
                  style={{ marginRight: "8px", width: '10px', }}
                >

                  {category?.access !== 0 && getCheckboxIcon(category.id)}
                </div>
                <span
                  style={{ flexGrow: 1, textAlign: "left", color: "white" }}
                >
                  {returnDisplayName(category.id.split('-').pop())}
                </span>

                {category?.children?.length > 0 && (
                  <span
                    style={{
                      marginLeft: "4px",
                      cursor: "pointer",
                      textAlign: "right",
                      color: "#D6FF41",
                      fontWeight: "bold",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleExpandIconClick(category.id);
                    }}
                  >
                    {selectedExpandIcon === category.id ? "−" : "+"}
                  </span>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div>
        {data.map(
          (category) =>
            selectedCategory === category.id &&
            category.children && (
              <div key={category.id}>
                <Cascader
                  data={category.children}
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                  selectedPath={selectedPath}
                  setSelectedPath={setSelectedPath}
                  setIdList={setIdList}
                  pathName={pathName}
                  selectedCheckboxes={selectedCheckboxes}
                  setSelectedCheckboxes={setSelectedCheckboxes}
                  flattenedData={flattenedData}
                  searchResults={searchResults}
                />
              </div>
            )
        )}
      </div>
    </div>
  );
};

const CascaderWrapper = ({ setFetchCall, salesData, data, placeholder, Chartdata, setChannelData, checkedList, setCheckedList, selectedCheckboxes, setSelectedCheckboxes }) => {
  const [searchValue, setSearchValue] = useState("");
  const [isCascaderVisible, setIsCascaderVisible] = useState(false);
  const [selectedPath, setSelectedPath] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [isDropDownVisible, setIsDropDownVisible] = useState(false);
  const dropdownRef = useRef(null);
  const cascaderRef = useRef(null);
  const searchBarRef = useRef(null);
  const searchListIconRef = useRef(null);
  const toggleCascaderRef = useRef(null);
  const [idList, setIdList] = useState([]);
  const [nameList, setNameList] = useState([]);
  const [pathName, setPathName] = useState("");
  const searchResultsContainerRef = useRef(null);
  // const [selectedCheckboxes, setSelectedCheckboxes] = useState({});
  const [SelectAllIcon, setSelectAllIcon] = useState(true);
  // const [checkedList, setCheckedList] = useState([]);

  const displayNames = JSON.parse(localStorage.getItem("displayNames"));
  const getDisplayName = (key) => {
    // Split key by '-'
    const parts = key.split("-");
    // Map each part using displayNames and join them with a -
    return parts.map(part => displayNames[part] || part).join("/");
  };


  console.log("checkedList 1", checkedList);
  const selectedIds = Object.keys(selectedCheckboxes)
    .filter((id) => selectedCheckboxes[id] === true)
    .filter((id) => !id.endsWith("-all") && id !== "all");

  useEffect(() => {
    setCheckedList(selectedIds);
  }, [selectedCheckboxes]);

  function deepEqual(obj1, obj2) {
    if (obj1 === obj2) {
      // If both objects are identical by reference
      return true;
    }

    if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
      // If either of the objects is not an object, or is null, they're not equal
      return false;
    }

    // Get the keys of both objects
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    // If the number of keys is different, the objects aren't equal
    if (keys1.length !== keys2.length) {
      return false;
    }

    // Check if all keys and their corresponding values are the same
    for (let key of keys1) {
      if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
        return false;
      }
    }

    return true;
  }
  const CheckEqual = (arr1, arr2) => {

    if (arr1.length != arr2.length)
      return false;

    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) { return false; }
    }
    return true;

  }
  useEffect(() => {
    // if (checkedList.length !== 0) {

    if (!CheckEqual(salesData, checkedList)) {

      console.log("get_trend salesData", salesData, checkedList);
      setChannelData((prevChannelData) => {
        // Create a new set with the previous data and the new checkedList to ensure uniqueness\

        const updatedChannelData = new Set([

          ...checkedList,
        ]);

        // Convert the set back to an array and return it
        return Array.from(updatedChannelData);
      });

      setFetchCall(true);
    }

    // }
  }, [checkedList]);


  function flattenDataToObject(data) {
    const result = [];

    const traverse = (items, parentId = null) => {
      items?.forEach((item) => {
        const { id, name, children } = item;
        result.push({ id, name, parentId });

        if (children && children.length > 0) {
          traverse(children, id);
        }
      });
    };

    traverse(data);
    return result;
  }

  const flattenedData = flattenDataToObject(data);

  function getTeaList(idList, flattenedData) {
    return idList.map((key) => flattenedData[key]);
  }

  useEffect(() => {
    const result = getTeaList(idList, flattenedData);
    setNameList(result);
  }, [idList]);

  useEffect(() => {
    if (nameList) {
      const names = nameList.join(" / ");
      setPathName(names);
      setSearchValue(names);
    } else {
      setPathName("");
      setSearchValue("");
    }
  }, [nameList]);


  // Toggle visibility of cascader
  const toggleCascaderVisibility = (event) => {
    event.stopPropagation();
    setIsCascaderVisible((prevState) => !prevState);
  };

  // Handle search input change
  const handleSearchInputChange = (event) => {
    const searchTerm = event.target.value;
    setSearchValue(searchTerm);
    if (searchTerm.trim() === "") {
      setSearchResults([]);
      setSearchValue("");
      setIsDropDownVisible(false);
    } else {
      const matchingResults = findMatchingResults(data, searchTerm);
      setSearchResults(matchingResults);
      setIsDropDownVisible(true);
    }
  };


  // Find matching results based on search term
  const findMatchingResults = (data, searchTerm) => {
    const matchingResults = [];
    const traverse = (children, path) => {
      children.forEach((item) => {
        const currentPath = path ? `${path} / ${item.name}` : item.name;
        if (item.name.toLowerCase().includes(searchTerm.toLowerCase())) {
          matchingResults.push({ path: currentPath.split(" / "), id: item.id, access:item.access });
        }
        if (item.children) {
          traverse(item.children, currentPath);
        }
      });
    };
    traverse(data, "");
    return matchingResults;
  };

  const handleSearchResultAllClick = (event) => {
    event.stopPropagation();
    searchResults.forEach((result) => {
      if (!checkedList.includes(result.id)) {
        handleSearchResultCheckboxClick(event, result);
      }
    });
  };

  const handleSetSelectedCheckBoxes = (categoryId) => {

    setSelectedCheckboxes((prev) => {
      const isChecked = prev[categoryId];
      const updatedCheckboxes = { ...prev, [categoryId]: !isChecked };

      const updateParentCheckboxes = (childId) => {
        const childItem = flattenedData.find((item) => item.id === childId);
        if (childItem) {
          const parentId = childItem.parentId;
          if (parentId) {
            const siblings = flattenedData.filter(
              (item) => item.parentId === parentId && !item.id.endsWith("-all")
            );
            const allSiblingsChecked = siblings.every(
              (sibling) => updatedCheckboxes[sibling.id] === true
            );
            const someSiblingsChecked = siblings.some(
              (sibling) => updatedCheckboxes[sibling.id] === true
            );
            const anySiblingsIndeterminate = siblings.some(
              (sibling) => updatedCheckboxes[sibling.id] === "indeterminate"
            );

            // Updated logic to prevent setting parent to true if all children are true
            if (allSiblingsChecked) {
              updatedCheckboxes[parentId] = "indeterminate";
            } else if (anySiblingsIndeterminate || someSiblingsChecked) {
              updatedCheckboxes[parentId] = "indeterminate";
            } else {
              updatedCheckboxes[parentId] = false;
            }

            if (parentId.endsWith("-all")) {
              updatedCheckboxes[parentId.replace("-all", "")] = allSiblingsChecked;
            } else {
              updatedCheckboxes[`${parentId}-all`] = allSiblingsChecked;
            }

            if (parentId !== "all") {
              updateParentCheckboxes(parentId);
            } else {
              const rootLevelItems = flattenedData.filter(
                (item) => !item.parentId
              );
              const allRootItemsUnchecked = rootLevelItems.every(
                (item) => updatedCheckboxes[item.id] === false
              );
              const someRootItemsChecked = rootLevelItems.some(
                (item) => updatedCheckboxes[item.id] === true
              );
              const anyRootItemsIndeterminate = rootLevelItems.some(
                (item) => updatedCheckboxes[item.id] === "indeterminate"
              );

              if (allRootItemsUnchecked) {
                updatedCheckboxes["all"] = false;
              } else if (anyRootItemsIndeterminate || someRootItemsChecked) {
                updatedCheckboxes["all"] = "indeterminate";
              } else {
                updatedCheckboxes["all"] = true;
              }
            }
          }
        }
      };

      if (categoryId === "all") {
        flattenedData.forEach((item) => {
          updatedCheckboxes[item.id] = !isChecked;
        });
      } else if (categoryId.endsWith("-all")) {
        const categoryLevel = categoryId.replace("-all", "");
        flattenedData.forEach((item) => {
          if (item.id.startsWith(categoryLevel) && !item.id.includes("-all")) {
            updatedCheckboxes[item.id] = !isChecked;
          }
        });

        flattenedData.forEach((item) => {
          if (item.id.endsWith("-all")) {
            const levelPrefix = item.id.replace("-all", "");
            const levelItems = flattenedData.filter(
              (i) => i.parentId === levelPrefix && !i.id.endsWith("-all")
            );
            const allLevelItemsChecked = levelItems.every(
              (i) => updatedCheckboxes[i.id] === true
            );
            const someLevelItemsChecked = levelItems.some(
              (i) => updatedCheckboxes[i.id] === true
            );
            const anyLevelItemsIndeterminate = levelItems.some(
              (i) => updatedCheckboxes[i.id] === "indeterminate"
            );

            // Updated logic to prevent setting parent to true if all children are true
            if (allLevelItemsChecked) {
              updatedCheckboxes[item.id] = "indeterminate";
            } else if (anyLevelItemsIndeterminate || someLevelItemsChecked) {
              updatedCheckboxes[item.id] = "indeterminate";
            } else {
              updatedCheckboxes[item.id] = false;
            }
          }
        });
      }

      updateParentCheckboxes(categoryId);

      return updatedCheckboxes;
    });

  };

  // function for handling the check box in the searchResult
  const handleSearchResultCheckboxClick = (event, result) => {
    // console.log("selectedIds", selectedIds);
    event.stopPropagation();
    //  setSearchValue("");

    const updatedResults = searchResults.map((r) =>
      r.id === result.id ? { ...r, checked: !r.checked } : r
    );
    setSearchResults(updatedResults);

    // Update selectedCheckboxes state for cascader

    const categoryId = result.id;
    handleSetSelectedCheckBoxes(categoryId);
  };

  // Handle click outside the dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        toggleCascaderRef.current &&
        toggleCascaderRef.current.contains(event.target)
      ) {
        if (isCascaderVisible) {
          setIsCascaderVisible(false);
        } // Toggle cascader visibility
        return;
      }

      // Check if the click is on the cascader container
      if (cascaderRef.current && cascaderRef.current.contains(event.target)) {
        setSearchValue("");
        setIsCascaderVisible(true);

        setIsDropDownVisible(false);
        return;
      }

      // Check if the click is on the search bar
      if (searchBarRef.current && searchBarRef.current.contains(event.target)) {
        return;
      }

      // Check if the click is on the dropdown
      if (dropdownRef.current && dropdownRef.current.contains(event.target)) {
        return;
      }

      // Close both cascader and dropdown if clicking outside
      setSearchValue("");
      setIsCascaderVisible(false);
      setIsDropDownVisible(false);
    };

    document.addEventListener("mousedown", handleClickOutside, true);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    if (cascaderRef.current) {
      cascaderRef.current.scrollLeft = cascaderRef.current.scrollWidth;
    }
  }, [data, searchValue, selectedPath, selectedCheckboxes, searchResults]);

  // console.log("searchResults: ", searchResults);



  return (
    <div style={{ position: "absolute" }}>
      <div ref={cascaderRef}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ position: "relative", display: "flex" }}>
            <input
              ref={searchBarRef}
              id="search-bar "
              type="text"
              className="form-control textSize"
              value={searchValue}
              onChange={handleSearchInputChange}
              autoComplete="off"
              placeholder={placeholder}
              style={{
                flexGrow: 2,
                position: "relative",
                border: "1px solid #3E5056",
                borderRadius: "4px",
                backgroundColor: "#1c2427",
                autocomplete: "off",
                color: "white",
                width: '115px',
                // fontSize: '10px',
                padding: '3px',
                outline: 'none',
              }}
            />
            <button
              ref={toggleCascaderRef}
              onClick={toggleCascaderVisibility}
              className="cascader-toggle-button"
              style={{
                position: "absolute",
                right: "5px",
                top: "50%",
                transform: "translateY(-50%)",
                backgroundColor: "transparent",
                border: "none",
                color: "white",
                cursor: "pointer",
                fontSize: '8px'
              }}
            >
              {isCascaderVisible ? "▲" : "▼"}
            </button>
            {searchResults.length > 0 && isDropDownVisible ? (
              <div
                ref={dropdownRef}
                style={{
                  position: "absolute",
                  top: "calc(100% + 4px)",
                  left: 0,
                  backgroundColor: "#1c2427",
                  border: "1px solid #3E5056",
                  // border: "1px solid red",
                  borderRadius: "4px",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  zIndex: "999999",
                  width: "100%",
                  maxHeight: "200px",
                  overflowY: "auto",
                  color: "white",
                }}
              >
                {/* <div className="flex mx-1 my-1">
                   <div onClick={handleSearchResultAllClick}>
                    {SelectAllIcon ? (
                      <CheckBoxOutlineBlankIcon
                        sx={{
                          color: "#d6ff41",
                          fontSize: "16px",
                          marginRight: "4px",
                          cursor: "pointer",
                        }}
                      />
                    ) : (
                      <CheckBoxIcon
                        sx={{
                          color: "#d6ff41",
                          fontSize: "16px",
                          marginRight: "4px",
                          cursor: "pointer",
                        }}
                      />
                    )}
                  </div> 
                   <div
                    style={{
                      fontSize: "15px",

                      text: "bold",
                    }}
                  >
                    Select All
                  </div> 
                </div> */}
                {console.log("SearchReuslts", searchResults)}
                {searchResults.map((result, index) => (
                  result.id.split('-').pop() !== 'all' ?
                    <div
                      key={index}
                      ref={searchResultsContainerRef}
                      style={{
                        padding: "4px",
                        cursor: "pointer",
                        display: "flex",
                        className: "search-result",
                        // border: "1px solid red"
                      }}
                    // onClick={() => handleSearchResultClick(result)}
                    >
                      <div
                        ref={searchListIconRef}
                        className="search-result-icon"
                        onClick={(event) => handleSearchResultCheckboxClick(event, result)}
                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                      >
                        {console.log("result inside", result)}
                        {result.access !== 0 && (
                          selectedIds.includes(result.id) ? (
                            <CheckBoxIcon sx={{ color: "#d6ff41", fontSize: "12px" }} />
                          ) : (
                            <CheckBoxOutlineBlankIcon sx={{ color: "#d6ff41", fontSize: "12px" }} />
                          )
                        )}
                      </div>
                      {/* fontSize: "10px"  */}
                      <div className="textSize" style={{ marginLeft: "4px",}}>
                        {[getDisplayName(result.id)].map((part, index, arr) => {
                          const regex = new RegExp(`(${searchValue})`, "gi");
                          const parts = part.split(regex);
                          return (
                            <span key={index}>
                              {parts.map((p, i) => (
                                <span
                                  key={i}
                                  style={{
                                    color:
                                      p.toLowerCase() ===
                                        searchValue.toLowerCase()
                                        ? "#D6FF41"
                                        : "white",
                                    fontWeight:
                                      p.toLowerCase() ===
                                        searchValue.toLowerCase()
                                        ? "bold"
                                        : "normal",
                                  }}
                                >
                                  {p}
                                </span>
                              ))}
                              {/* {index < result.path.length - 1 && " / "} */}
                            </span>
                          );
                        })}
                      </div>
                    </div> : null
                ))}
              </div>
            ) : searchValue &&
              searchResults.length === 0 &&
              isDropDownVisible ? (
              <div
                ref={dropdownRef}
                style={{
                  position: "absolute",
                  top: "calc(100% + 4px)",
                  left: 0,
                  backgroundColor: "#1c2427",
                  border: "1px solid #3E5056",
                  borderRadius: "4px",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  zIndex: 1000000,
                  width: "100%",
                  maxHeight: "200px",
                  overflowY: "auto",
                  color: "white",
                  fontSize: "12px"
                }}
              >
                No data
              </div>
            ) : null}
          </div>
        </div>

        {isCascaderVisible && (
          <div

            style={
              {
                // width:'25rem',

                maxWidth: '25rem',
                zIndex: "999",
                overflowX: 'scroll',
                padding: "4px",
                border: "1px solid #3e5056",
                backgroundColor: "#1c2427",
                Width: 'min-content',
                position: "absolute"

              }

            }


            ref={cascaderRef}  >
            <Cascader
              data={data}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              selectedPath={selectedPath}
              setSelectedPath={setSelectedPath}
              setIdList={setIdList}
              pathName={pathName}
              selectedCheckboxes={selectedCheckboxes}
              setSelectedCheckboxes={setSelectedCheckboxes}
              flattenedData={flattenedData}
              searchResults={searchResults}
            />
          </div>
        )}
      </div>

    </div>
  );
};

export default CascaderWrapper;
