import React, { useEffect, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import CustomEvents from "highcharts-custom-events";
import HighchartsGroupedCategories from "highcharts-grouped-categories";
import axiosChartDownload from "../Axios";
import { toast } from "react-toastify";
import domtoimage from "dom-to-image";
import configDriven from "../ConfirgDriven";

HighchartsGroupedCategories(Highcharts);
CustomEvents(Highcharts);

const StackChart = (props) => {
  const refChart = useRef(null);
  const divRef = useRef();

  const calculateWidth = (categories, containerWidth, minColumnWidth) => {
    // by a column
    let converedWidthByColumns = minColumnWidth * categories + categories * 50;

    // console.log("result: ", Math.max(containerWidth, converedWidthByColumns));
    return Math.max(containerWidth, converedWidthByColumns);
  };

  const displayNames = JSON.parse(localStorage.getItem("displayNames"));

  const returnDisplayName = (id) => {
  if (configDriven.show_display_name && displayNames?.hasOwnProperty(id)) {
    return displayNames[id];
  } else {
    return id;
  }
}

  function getFormattedString(id, obj) {
    console.log("id ltstroi",id,"    ",obj);
    if (!id || typeof id !== "string") {
      // If id is null, undefined, or not a string, return it as is
      return id;
    }
  
    if (configDriven.show_display_name) {
      // Split the id by hyphens
      const keys = id.split("-");
  
      // Map the keys to their corresponding values in the object
      const values = keys.map((key) => obj?.[key] ?? key);
  
      // Join the values with hyphens and return the result
      return values.join("-");
    } else {
      return id;
    }
  }

  // useEffect(() => {
  //   const chart = refChart.current.chart;
  //   if (chart) chart.reflow(false);
  // }, [props.ZoomIn]);

  useEffect(() => {
    if (props.Downloadchart) {
      const chartContainer = document.getElementById("campaign_chart");
      domtoimage
        .toPng(chartContainer)
        .then(function (dataUrl) {
          var string = "";
          for (var i = 0; i < props.BreadCrumbs.length; i++) {
            string += props.BreadCrumbs[i];
            if (i !== props.BreadCrumbs.length - 1) {
              string += ",";
            }
          }

          props.setLoader(true);
          axiosChartDownload
            .post("download_chart/create-image/", {
              title: "Campaign Effectiveness",
              filter: props.Brand,
              hierarchy: string,
              timeframe: props.timeframe,
              start_date: props.StartDate,
              end_date: props.EndDate,
              base64_image: dataUrl.split(",")[1],
              theme: localStorage.getItem("CurrentTheme"),
            })
            .then((response) => {
              if (response.data.error === 1) {
                toast.error(response.data.erroMsg);
                props.setLoader(false);
              } else {
                var link = document.createElement("a");
                link.target = "_blank";
                link.href = response.data.data.combined_graph_url;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                props.setLoader(false);
              }
            })
            .catch((data) => {
              props.setLoader(false);
            });
        })
        .catch(function (error) {
          props.setLoader(false);
          console.error("Error while generating chart image:", error);
        });
      props.setDownloadchart(false);
    }
  }, [props]);

  const displayNamesChart = JSON.parse(
    localStorage.getItem("displayNames")
  );

  const handleLoader=()=>{
    console.log("handleLoader");
    props.setLoader(false);
  }
 

  const options = {
    credits: {
      enabled: false,
    },
    chart: {
      type: "column",
      zoomType: "xy",
      resetZoomButton: {
        position: {
          align: 'center', // Align the button to the center horizontally
          verticalAlign: 'top', // Keep it at the top vertically
          x: 0, // No horizontal offset, keep it centered
          y: 10, // Optional: Adjust vertical offset if needed
        },
        theme: {
          fill: "",
          stroke: "#d6ff41",
          style: {
            color: "white",
          },
        },
      },
      backgroundColor: "rgba(0,0,0,0)",
      showAxes: true,
      height: window.innerHeight<950?window.innerHeight * 0.68:window.innerHeight * 0.73, 
      events: {
        render: handleLoader,
      }
    },
    title: {
      text: props.charttitle,
      style: {
        color: "#D6FF41",
        fontSize: "14px",
      },
    },
    subtitle: {
      text: "",
    },
    xAxis: {
      categories: props.categories,
      crosshair: false,
      title: {
        text: props.xtitle,
        style: {
          color: "#D6FF41",
        },
      },
      // labels: {
      //   useHTML: true,
      //   formatter: function () {
      //     let fulltext = "";
      //     if (typeof this.value === "string") {
      //       const SeriesList = [];
      //       function chnnellist(list) {
      //         if (list.name.split(",")[0] !== "") {
      //           SeriesList.push(list.name.split(",")[0]);
      //         }
      //         if ("parent" in list && list.parent !== undefined) {
      //           chnnellist(list.parent);
      //         }
      //       }
      //       chnnellist(this.axis.categories[this.pos]);

      //       const val = this.value.split(",");
      //       let title = SeriesList.reverse().join("-");
      //       title = title.substring(0, title.indexOf(val[0]) + val[0].length);

      //       let short = "";
      //       if (val[1]) {
      //         if (val[1] === "-1") {
      //           short = val[0];
      //         } else if (val[1] === "0") {
      //           short =
      //             val[0] !== "" && val[0].length > 5
      //               ? val[0].slice(0, 5) + ".."
      //               : val[0];
      //         } else {
      //           short =
      //             val[0] !== "" && val[0].length > 5
      //               ? val[0].slice(0, 5) + ".."
      //               : val[0];
      //         }
      //       } else {
      //         short = val[0];
      //       }
      //       return localStorage.getItem("CurrentTheme") === "Dark"
      //         ? '<div class="cursor-pointer" style="background-color:#1C2427;z-index:99999" title=' +
      //             title +
      //             ">" +
      //             short +
      //             "</div>"
      //         : '<div class="cursor-pointer" style="color:#2f3c41; background-color:white; z-index:99999" title=' +
      //             title +
      //             ">" +
      //             short +
      //             "</div>";
      //     } else {
      //       return localStorage.getItem("CurrentTheme") === "Dark"
      //         ? '<div class="cursor-pointer" style="background-color:#1C2427;z-index:99999" title=' +
      //             fulltext +
      //             ">" +
      //             this.value.name.split(",")[0] +
      //             "</div>"
      //         : '<div class="cursor-pointer" style="color:#2f3c41; background-color:white; z-index:99999" title=' +
      //             fulltext +
      //             ">" +
      //             this.value.name.split(",")[0] +
      //             "</div>";
      //     }
      //   },
      //   rotation: 0,
      //   style: {
      //     color: "#FFFFFF",
      //   },
      //   events: {
      //     click: function () {
      //       props.DrillUp(this);
      //     },
      //   },
      // },
      labels: {
        useHTML: true,
        formatter: function () {
          let fulltext = "";
          if (typeof this.value === "string") {
            const SeriesList = [];
            function chnnellist(list) {
              if (list.name.split(",")[0] !== "") {
                SeriesList.push(list.name.split(",")[0]);
              }
              if ("parent" in list && list.parent !== undefined) {
                chnnellist(list.parent);
              }
            }
            chnnellist(this.axis.categories[this.pos]);

            const val = this.value.split(",");
            const lebelText = returnDisplayName(val[0]);
            // console.log("lebelText: ", lebelText);
            let title = SeriesList.reverse().join("-");
            title = title.substring(0, title.indexOf(val[0]) + val[0].length);

            let short = "";
            if (val[1]) {
              if (val[1] === "-1") {
                short = val[0];
              } else if (val[1] === "0") {
                short =
                  val[0] !== "" && val[0].length > 5
                    ? val[0].slice(0, 5) + ".."
                    : val[0];
              } else {
                short =
                  val[0] !== "" && val[0].length > 5
                    ? val[0].slice(0, 5) + ".."
                    : val[0];
              }
            } else {
              short = val[0];
            }
            // console.log("fulltext: ", fulltext);
            return localStorage.getItem("CurrentTheme") === "Dark"
            ? `<div id="${title}" class="cursor-pointer" style="background-color:#1C2427;z-index:99999" title="${getFormattedString(title, displayNamesChart)}">${lebelText}</div>`
            : `<div id="${title}" class="cursor-pointer" style="color:#2f3c41; background-color:white; z-index:99999" title="${getFormattedString(title, displayNamesChart)}">${lebelText}</div>`;
        } else {
          return localStorage.getItem("CurrentTheme") === "Dark"
            ? `<div id="${this?.value?.name?.split(",")[0]}" class="cursor-pointer" style="background-color:#1C2427;z-index:99999" title="${fulltext}">${this?.value?.name?.split(",")[0]}</div>`
            : `<div id="${this?.value?.name?.split(",")[0]}" class="cursor-pointer" style="color:#2f3c41; background-color:white; z-index:99999" title="${fulltext}">${this?.value?.name?.split(",")[0]}</div>`;
        }
        },
        rotation: props.full ? 0 : props.series[0].data.length > 3 ? -45 : 0,
        style: {
          color: "#FFFFFF",
        },
        events: {
          click: function () {
            props.DrillUp(this);
          },
        },
      },
    },
    yAxis: {
      min: null,
      gridLineColor: '#808080',
      // gridLineDashStyle: "ShortDash",
      visible: true,
      lineWidth: 1,
      title: {
        text: props.ytitle,
        style: {
          color: "#D6FF41",
        },
      },
      labels: {
        style: {
          color:
            localStorage.getItem("CurrentTheme") === "Dark"
              ? "#FFFFFF"
              : "#1C2427",
        },
      },
      softMin: 0, // Allows negative values while ensuring the axis adapts to data
      },
    tooltip: {
      followPointer: true,
      formatter: function () {
        const SeriesList = [];
        function chnnellist(list) {
          if (typeof list === 'object' && list !== null) { // Ensure it's an object
              if (list.name.split(",")[0] !== "") {
                  SeriesList.push(list.name.split(",")[0]);
              }
              if ("parent" in list && list.parent !== undefined) {
                  chnnellist(list.parent);
              }
          } else if (typeof list === 'string') { // Handle the case where list is a string
              SeriesList.push(list.split(",")[0]);
          }
      }
      
        chnnellist(this?.x);

        let label = SeriesList?.reverse()?.join("-");
        // return label + " " + this.y.toFixed(2) + "";
        const foundLabel = label?.split("-")?.pop();

        var series = this.series;
        var spends = series.userOptions.spends[this.point.index];
        var contribution = series.userOptions.contributions
    ? series.userOptions.contributions[this.point.index] 
    : undefined;

        return (
          "<b>" +
          // label +
          getFormattedString(label, displayNamesChart) +
          "</b>" +
          // series.name +
          // ": " +
          // this.y +"%"+
          "<br/>"
           +
          // "Spends: " +
          // spends +
          // "<br/>" 
          // +
          "Contribution: " +this.y +"%"
          // contribution
        );
      },
      borderColor: "#ffffff",
      backgroundColor: "#ffffff",
      borderRadius: 8,
    },
    plotOptions: {
      
      series: {
        // cursor: 'pointer',
        point: {
          events: {
            click: function () {
              const SeriesList = [];
              function chnnellist(list) {
                if (list.name.split(",")[0] !== "") {
                  SeriesList.push(list.name.split(",")[0]);
                }
                if ("parent" in list && list.parent !== undefined) {
                  chnnellist(list.parent);
                }
              }
              chnnellist(this.category);

              let label = SeriesList.reverse().join("-");
              console.log("this inside measurement: ", this);

              if (!props.NotAllow.includes(label)) {
                // props.DrillDown(this);
                props.DrillDown(label);
              }
            },
            mouseOver: function () {
              const SeriesList = [];
              function chnnellist(list) {
                if (list.name.split(",")[0] !== "") {
                  SeriesList.push(list.name.split(",")[0]);
                }
                if ("parent" in list && list.parent !== undefined) {
                  chnnellist(list.parent);
                }
              }
              chnnellist(this.category);

              let label = SeriesList.reverse().join("-");

              window.document.getElementsByTagName("body")[0].style.cursor =
                "pointer";
              if (props.NotAllow.includes(label)) {
                window.document.getElementsByTagName("body")[0].style.cursor =
                  "not-allowed";
              }
            },
            mouseOut: function () {
              window.document.getElementsByTagName("body")[0].style.cursor =
                "default";
            },
          },
        },
      },
      column: {
        stacking: "normal",
        // stacking: "",
        pointPadding: 0,
        borderWidth: 0,
        dataLabels: {
          headerFormat:
            '<div style="padding:0;background-color:#ffffff;color:#000000;"><table>',
          pointFormat: '<td style="padding:0;"><b>{point.y:.2f}</b></td></tr>',
          footerFormat: "</table></div>",
          enabled: true,
          verticalAlign: "top",
          color: "#ffffff",
          align: "center",
          x: 0,
          y: -20,
        },
        color:
          localStorage.getItem("CurrentTheme") === "Dark"
            ? "#D6FF41"
            : localStorage.getItem("ColorCode") === "Lavender"
            ? "#9384bf"
            : localStorage.getItem("ColorCode") === "Sapphire"
            ? "#6b75af"
            : localStorage.getItem("ColorCode") === "Teal"
            ? "#57898c"
            : localStorage.getItem("ColorCode") === "Cyan"
            ? "#21afc2"
            : localStorage.getItem("ColorCode") === "Goldenrod"
            ? "#dca25b"
            : "#D6FF41",
        // events: {
        //   click: (e) => {
        //     console.log(e)
        //   }
        // }
      },
    },
    legend: {
      enabled: false,
      itemHoverStyle: {
        color: "#ffffff",
      },
      itemStyle: {
        color: "#ffffff",
      },
      symbolRadius: 0,
    },
    series: props.series,
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          enabled: false,
          className: "highcharts-contextbutton",
          symbol: "menu",
          symbolStroke: "#ffffff",
          theme: {
            fill: "#1c2427",
          },
          titleKey: "",
          menuItems: ["viewFullscreen"],
        },
      },
    },
  };

  // useEffect(() => {
  //   Highcharts.chart(divRef.current, {
  //       ...options
  //   })

  // }, [props.series])

  useEffect(() => {
    Highcharts.chart(divRef.current, {
        ...options
    })

  }, [props.series, props.full, props.height])

  return (
    <div
      key={props.full}
      id="campaign_chart"
      style={{
        // border: "1px solid red",
        overflowX: "scroll",
        width: "100%",
      }}
    >
      <div
        id="container"
        ref={divRef}
        style={{
          width: props.full
            ? calculateWidth(props.series[0].data.length, props.divWidth, 40)
            : null,
          // border: "1px solid yellow",
        }}
      >
        {/* <HighchartsReact
          ref={refChart}
          highcharts={Highcharts}
          options={options}
        /> */}
      </div>
    </div>
  );
};

export default StackChart;