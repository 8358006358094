import React, { useState, useEffect } from 'react';
import '../assets/ManualCSS/CustomSelection.css';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';

const CustomSelect = ({ value, onChange, options = [],isDetailPopUp=true , barWidth}) => {
  console.log("options ",options);
  const [selectedValue, setSelectedValue] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [hoveredOption, setHoveredOption] = useState(null);

  // useEffect(() => {
  //   if (options.length > 0) {
  //     setSelectedValue(options[0].value);
  //   }
  // }, [options]);

  useEffect(() => {
    if (!value && options.length > 0) {
      onChange({ target: { value: options[0].value } });
    }
  }, [value, onChange, options]);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (key) => {
    console.log("handleOptionClick",key);
    if(isDetailPopUp){
    setSelectedValue(key);
    
    
    }
    
    
    onChange({ target: { value: key } });
    setIsOpen(false);

    console.log("handleOptionClick filter",options.find(option => option.value === key)?.value || value || 'Select...');
  };

  useEffect(()=>{
console.log("options.find(option => option.value === selectedValue)?.value ",options.find(option => option.value === selectedValue)?.value);
  },[selectedValue])

  return (
    <div className="custom-select-container " tabIndex={0} onBlur={() => setIsOpen(false)}>
      <div className='custom-select' style={{ width: barWidth === undefined ? '6.5rem' : `${barWidth}px`,fontSize:isDetailPopUp === false ? '10px' : '12px' ,height:isDetailPopUp === false ? '20px' : '26px' }}onClick={handleToggle}>

        {isDetailPopUp===true?options.find(option => option.value === selectedValue)?.value===undefined?value:options.find(option => option.value === selectedValue)?.value: value}
        <span className="dropdown-icon">
          {isOpen ? <KeyboardArrowUpOutlinedIcon /> : <KeyboardArrowDownOutlinedIcon />}
        </span>
      </div>
      {isOpen && options.length > 0 && (
        <div className="custom-options">
          {options.map((item) => (
            <div
              key={item.key}
              className={`custom-option ${hoveredOption === item.key && value !== item.value ? 'hovered-option' : ''} ${value === item.value ? 'selected-option' : ''} textSize`}
              onMouseEnter={() => setHoveredOption(item.key)}
              onMouseLeave={() => setHoveredOption(null)}
              onClick={() => handleOptionClick(item.value)}
              style={{
                width: barWidth === undefined ? '6rem' : `${barWidth-7}px`,
                // fontSize: "10px"
              }}            >
              {item.value}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomSelect;
