import React, { useEffect, useState, useCallback } from "react";
import { Stack, Button, Typography } from "@mui/material";
import { useParams } from "react-router";
import OptimiseForm from "./OptimiseForm";
import Topbar from "../Components/Topbar";
import Menubar from "../Components/Menubar";
import Loader from "../Components/Loader";
import axios from "../Axios";
import axiosDownload from "../Axios";
import SkewbSimulator from "./SkewbSimulator";
import SaveResultAlert from "./SaveResultAlert";
import RangeConfirmAlert from "./RangeConfirmAlert";
import AlertBox from "./AlertBox";
import ColumnChart from '../Components/ColumnChart'
import LineChart from '../Components/LineChart'
import StackChart from '../Components/StackChart'
import WaterfallChart from '../Components/WaterFallChart'
import Table from "../Components/TableList"


import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PopUp from "../Components/PopUp";

import LightThemeHexCode from "../Components/LightThemeColorCode";

import findAllChangedParents from "./findAllChangedParents";
import findAllLevelChildren from "./allLevelChildren";
import { Details } from "@mui/icons-material";

const Main = () => {
  const { ResultId } = useParams();

  const [loader, setLoader] = useState(false);
  const [AllowCall, setAllowCall] = useState(false);
  const [ChartActiveTab, setChartActiveTab] = useState("Distribution");
  const [AllowEdit, setAllowEdit] = useState(false);
  const [PreviousData, setPreviousData] = useState([]);
  const [SelectedYear, setSelectedYear] = useState("");
  // console.log("SelectedYear: ", SelectedYear);
  const [Summary, setSummary] = useState({});
  const [result_name, setResultName] = useState("");
  const [Notes, setNotes] = useState("");
  const [SaveResponse, setSaveResponse] = useState("");
  const [ConfirmRange, setConfirmRange] = useState({});
  const [AlertMessage, setAlertMessage] = useState("");
  const [ChannelExpand, setChannelExpand] = useState({});
  const [ChannelExpand1, setChannelExpand1] = useState({});
  const [Levels, setLevels] = useState([]);
  const [hirarchy_level, setHirarchy_level] = useState([]);

  const [CaraouselChartdict, setCaraouselChartdict] = useState([]);
  const [CaraouselSummarydict, setCaraouselSummarydict] = useState([]);
  const [OptChartdict, setOptChartdict] = useState([]);
  const [OptSummarydict, setOptSummarydict] = useState([]);

  const [BrandName, setBrandName] = useState("");
  console.log("BrandName Main: ", BrandName);
  const [KPIName, setKPIName] = useState("");
  console.log("KPIName Main: ", KPIName);
  const [KPIMatch, setKPIMatch] = useState("");



  const [DeepDive, setDeepDive] = useState(false);
  const Analyse = JSON.parse(localStorage.getItem("Analyse"));
  console.log("Analyse: ", Analyse);

  useEffect(() => {
    console.log("ANalyse Brand ", Analyse.Brand)
    setBrandName(Analyse.Brand);

  }, [Analyse.Brand])

  const Detail = JSON.parse(localStorage.getItem("Details"));

  useEffect(() => {
    setHirarchy_level(Detail.hirarchy_level);
  }, [Detail])

  useEffect(() => {
    if (KPIMatch !== "") {
      setKPIName(KPIMatch)
    }
  }, [KPIMatch])


  const [prevJsonFetched, setPrevJsonFetched] = useState(false);
  // console.log("prevJsonFetched: ", prevJsonFetched);

  const [clickedOptmize, setClickedOptimize] = useState(false);

  const [allChannels, setAllChannels] = useState({});
  const [DATA, setDATA] = useState({});
  const [userInputKey, setUserInputKey] = useState("");
  // console.log("userInputKey: ", userInputKey);
  const [flatChannels, setFlatChannels] = useState({});
  const [myObj, setMyObj] = useState({}); // for storing parent max and sum of child
  const [randomFunExecuted, setRandomFunExecuted] = useState(false);

  const [isOpen2, setIsOpen2] = useState(false);
  const [message2, setMessage2] = useState("");

  const [isExecuted, setIsExecuted] = useState(false);
  const [MyLevel, setMyLevel] = useState("");
  const [prevDataForDownload, setprevDataForDownload] = useState({});
  const [newDataForDownload, setNewDataForDownload] = useState({});
  const [allTopLevelChanged, setAllTopLevelChanged] = useState(false);

  // console.log("allTopLevelChanged: ", allTopLevelChanged);

  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");

  // alert box start for range.........
  const Alert = ({ message }) => {
    return (
      <>
        {isOpen && (
          <div
            className="card card-min-height"
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "450px",
              backgroundColor: "#1c2427",
              // boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.1)",
              padding: "2rem",
              borderRadius: "1rem",
              zIndex: 99999,
              textAlign: "center",
            }}
          >
            <p
              style={{
                fontSize: "1.25rem",
                color: "white",
                // fontWeight: 700,
              }}
            >
              {message}
            </p>
            <div style={{ display: "flex", justifyContent: "center", gap: "1.5rem", marginTop: "1.5rem" }}>
              <button
                className="btn-class"
                onClick={() => {
                  setIsOpen(false);
                  setIsOpen2(false);
                  setIsExecuted(true);
                }}
                style={{
                  // padding: "0.5rem 1.5rem",
                  // backgroundColor: "#d6ff41",
                  // color: "#1e293b",
                  // fontSize: "1rem",
                  // fontWeight: 700,
                  // border: "none",
                  // cursor: "pointer",
                }}
              >
                YES
              </button>
              <button
                className="btn-class"
                onClick={() => {
                  setIsOpen(false);
                  traverse(MyLevel, 0, false);
                  setIsExecuted(true);
                  EditNewSpends(MyLevel, flattened[userInputKey][7]);
                }}
                style={{
                  // padding: "0.5rem 1.5rem",
                  // border: "1px solid #d6ff41",
                  // backgroundColor: "#1e293b",
                  // color: "black",
                  // fontSize: "1rem",
                  // fontWeight: 700,
                  // cursor: "pointer",
                }}
              >
                NO
              </button>
            </div>
          </div>
        )}
      </>
    );
  };

  // Alert box end for range............end

  const Alert2 = ({ message }) => {
    return (
      <>
        {isOpen2 && (
          <div
            className="card card-min-height"
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "450px",
              backgroundColor: "#1c2427",
              boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.1)",
              padding: "2rem",
              borderRadius: "1rem",
              zIndex: 9999999,
              textAlign: "center",
            }}
          >
            <p
              style={{
                fontSize: "1.25rem",
                color: "white",

              }}
            >
              {message}
            </p>
            <div style={{}}>
            <button
              className="btn-class"
              onClick={() => {
                setIsOpen2(false);
                setIsOpen(false);
                setIsExecuted(true);
                setMessage2("");
                traverse(MyLevel, 0, false);
                EditNewSpends(MyLevel, flattened[userInputKey][7]);
              }}

            >
              RESET
            </button>
            </div>

          </div>
        )}
      </>
    );
  };


  // console.log("allChannels: ", allChannels);

  useEffect(() => {
    setClickedOptimize(false);
  }, [Analyse.Brand, Analyse.KPI]);

  useEffect(() => {
    setDeepDive(false);
  }, [Analyse.KPI, Analyse.Brand]);

  const YearHandler = (e) => {
    setAllowCall(false);
    for (var i = 0; i < PreviousData.length; i++) {
      if (e.target.value === PreviousData[i].year) {
        setSummary(PreviousData[i].data);
        break;
      }
    }
    setSelectedYear(e.target.value);
  };

  const [NewData, setNewData] = useState({});
  const [NewFixedData, setNewFixedData] = useState({});
  const [NewSaveData, setNewSaveData] = useState({});
  const [Parameter, setParameter] = useState({
    brand: BrandName,
    kpi: KPIName,
    timeframe: "",
    timeperiod: "",
    optimization_type: "",
    budget: "",
  });
  const [SuggestedAllocation, setSuggestedAllocation] = useState([]);
  const [EditedAllocation, setEditedAllocation] = useState([]);

  const [GrowthDistribution, setGrowthDistribution] = useState({
    GrowthData: [],
    GrowthLabel: [],
    GrowthColor: [],
  });

  const [Contribution, setContribution] = useState([]);

  const [SaveResult, setSaveResult] = useState(false); // boolean

  console.log("Parameter Main: ", Parameter);

  const SaveOptimisation = () => {
    if (result_name !== "") {
      const ParamData = {
        result_name: result_name,
        optimization_type: Parameter.optimization_type,
        budget: Parameter.budget,
        // brand: BrandName===""?Analyse.Brand:BrandName,
        brand: Parameter.brand,
        kpi: Parameter.kpi,
        timeframe: Parameter.timeframe,
        timeperiod: Parameter.timeperiod,
        result_data: NewSaveData,
        ddtag: SelectedYear,
      };
      setLoader(true);
      axios
        .post("save_optimisation/", ParamData)
        .then((response) => {
          if (response.data.error === 1) {
            toast.error(response.data.erroMsg);
            setLoader(false);
          } else {
            // setSaveResult(false)
            setSaveResponse(response.data.data);
            setLoader(false);
          }
        })
        .catch(() => {
          setLoader(false);
        });
    }
  };

  const OptimizedData = (parameter) => {
    console.log("paramterss ", parameter);
    setResultName(parameter.result_name);
    setParameter(parameter);
  };


  const [patameterSet, setParameterSet] = useState(false);

  useEffect(() => {
    if (BrandName && KPIName) {
      setParameter(prev => ({
        ...prev,
        brand: BrandName,
        kpi: KPIName,
      }));
      setParameterSet(true);
    }
  }, [BrandName, KPIName, Parameter.budget]);



  useEffect(() => {

    console.log("get_prev ", AllowCall, "   ", patameterSet)
    if (Parameter.brand !== "" && Parameter.kpi !== "" && Parameter.budget !== "" && AllowCall && patameterSet) {
      setLoader(true);
      axios
        .post("get_opt_prev_json/", Parameter)
        .then((response) => {
          if (response.data.error === 1) {
            toast.error(response.data.erroMsg);
            setLoader(false);
          } else {
            // setPreviousResponse(response.data);
            // console.log(get_opt_prev_json)
            // console.log(response.data.data)
            const temp = { data: response.data.data };
            // setPreviousData(response.data.data.data);
            // setSummary(response.data.data.data[0].data)
            // setSelectedYear(response.data.data.data[0].year);

            setPreviousData(temp.data);
            setprevDataForDownload(temp);
            setSummary(temp.data[0].data);
            setSelectedYear(temp.data[0].year);
            setPrevJsonFetched(true);
            setLoader(false);
            setParameterSet(false);
            // setClickedOptimize(true);
          }
        })
        .catch(() => {
          setLoader(false);
        });
    }
  }, [Parameter, AllowCall, patameterSet]);

  useEffect(() => {
    console.log("optimise api parameter", Parameter, "  ", PreviousData.length, " ", AllowCall, "  ", prevJsonFetched);
    if (PreviousData.length && AllowCall && prevJsonFetched) {
      setLoader(true);
      Parameter.ddtag = SelectedYear;
      Parameter.brand = BrandName;
      Parameter.kpi = KPIName;
      Parameter.theme = localStorage.getItem("CurrentTheme");
      Parameter.LightThemeColorCode = [
        localStorage.getItem("ColorCode"),
        LightThemeHexCode[localStorage.getItem("ColorCode")],
      ];

      axios
        .post("optimise/", Parameter)
        .then((response) => {
          if (response.data.error === 1) {
            toast.error(response.data.erroMsg);
            setLoader(false);
          } else {
            // setCurrentResponse(response.data);
            setNewSaveData(response.data.data);
            setNewData(response.data.data.data);
            setNewFixedData(response.data.data.data);
            setNewDataForDownload(response.data.data);
            setAllChannels(response.data.data.data);
            setDATA(response.data.data.data);
            setNotes(response.data.notes);
            setCaraouselChartdict(response.data.data.caraouselchartdict);
            setCaraouselSummarydict(response.data.data.caraouselsummarydict);
            setOptChartdict(response.data.data.optchartdict);
            setOptSummarydict(response.data.data.optsummarydict);

            // setNewData(optimise.data);
            // setNewFixedData(optimise.data);
            // setNotes(optimise.notes)
            // setCaraouselChartdict(optimise.data.caraouselchartdict)
            setLoader(false);
            setPrevJsonFetched(false);
            setClickedOptimize(true);
          }
        })
        .catch(() => {
          setLoader(false);
        });
    }
  }, [PreviousData, Parameter, AllowCall, SelectedYear]);


  // saved result
  useEffect(() => {
    if (ResultId) {
      setLoader(true);
      axios
        .post("optimise/", { result_id: ResultId })
        .then((response) => {
          if (response.data.error === 1) {
            console.log("saved result123 ", response.data)
            toast.error(response.data.erroMsg);
            setLoader(false);
          } else {
            console.log("saved result ", response.data)

            // setNewData(response.data.data);
            // setNewFixedData(response.data.data);
            // setCurrentResponse(response.data);
            setNewSaveData(response.data.data);
            setNewData(response.data.data.data);
            setNewFixedData(response.data.data.data);
            setAllChannels(response.data.data.data);
            setDATA(response.data.data.data);
            setNotes(response.data.notes);
            setCaraouselChartdict(response.data.data.caraouselchartdict);
            setCaraouselSummarydict(response.data.data.caraouselsummarydict);
            setOptChartdict(response.data.data.optchartdict);
            setOptSummarydict(response.data.data.optsummarydict);
            setParameter({
              // brand: response.data.data.brand_short,
              // brand: BrandName===""?Analyse.Brand: BrandName,
              brand: BrandName,
              // kpi: response.data.data.kpi_short,
              kpi: KPIName,
              timeframe: response.data.data.timeframe,
              timeperiod: response.data.data.timeperiod,
              optimization_type: response.data.data.optimization_type,
              budget: response.data.data.spend,
            });

            const ddtag = response.data.data.ddtag;

            axios
              .post("get_opt_prev_json/", {
                // "brand": response.data.data.brand_short,
                // brand: BrandName===""? Analyse.Brand : BrandName,
                brand: BrandName,
                // "kpi": response.data.data.kpi_short,
                kpi: KPIName,
                timeperiod: response.data.data.timeperiod,
                timeframe: response.data.data.timeframe,
              })
              .then((response) => {
                if (response.data.error === 1) {
                  toast.error(response.data.erroMsg);
                  setLoader(false);
                } else {
                  // setPreviousResponse(response.data)
                  setPreviousData(response.data.data);
                  if (ddtag) {
                    for (var i = 0; i < response.data.data.length; i++) {
                      if (ddtag === response.data.data[i].year) {
                        setSelectedYear(response.data.data[i].year);
                        setSummary(response.data.data[i].data);
                        break;
                      }
                    }
                  } else {
                    setSelectedYear(response.data.data[0].year);
                    setSummary(response.data.data[0].data);
                  }
                  setDeepDive(true);
                  setLoader(false);
                }
              })
              .catch(() => {
                setLoader(false);
              });
          }
        })
        .catch(() => {
          setLoader(false);
        });
    } else {
      setAllowCall(true);
    }
  }, [ResultId, BrandName, KPIName]);

  // const EditNewSpends = (ChannelName, Index, Spends, Index1 = '') => {
  //   if (isNaN(Spends)) {
  //     return false
  //   }
  //   var TempNewData = JSON.parse(JSON.stringify(NewData));

  //   if (Index1 === '') {
  //     TempNewData.channels[ChannelName][Index].spends = Spends;
  //   } else {
  //     TempNewData.channels[ChannelName][Index].subchannel[Index1].spends = Spends;
  //   }

  //   setNewData(TempNewData);
  // };

  // const EditNewSpends = (MyLevel, Spends) => {
  //   if (isNaN(Spends)) {
  //     return false
  //   }
  //   var TempNewData = JSON.parse(JSON.stringify(NewData));

  //   MyLevel = MyLevel.split("|");
  //   var i = 0
  //   var j = 0
  //   var k = 0
  //   var l = 0

  //   if (MyLevel.length === 2) {
  //     for (i = 0; i < TempNewData.channels[MyLevel[0]].length; i++) {
  //       if (TempNewData.channels[MyLevel[0]][i].mykey === MyLevel[1]) {
  //         TempNewData.channels[MyLevel[0]][i].spends = Spends;
  //         break;
  //       }
  //     }
  //   }

  //   if (MyLevel.length === 3) {
  //     for (i = 0; i < TempNewData.channels[MyLevel[0]].length; i++) {
  //       if (TempNewData.channels[MyLevel[0]][i].mykey === MyLevel[1]) {
  //         for (j = 0; j < TempNewData.channels[MyLevel[0]][i].subchannel.length; j++) {
  //           if (TempNewData.channels[MyLevel[0]][i].subchannel[j].mykey === MyLevel[2]) {
  //             TempNewData.channels[MyLevel[0]][i].subchannel[j].spends = Spends;
  //             break
  //           }
  //         }
  //       }
  //     }
  //   }

  //   if (MyLevel.length === 4) {
  //     for (i = 0; i < TempNewData.channels[MyLevel[0]].length; i++) {
  //       if (TempNewData.channels[MyLevel[0]][i].mykey === MyLevel[1]) {
  //         for (j = 0; j < TempNewData.channels[MyLevel[0]][i].subchannel.length; j++) {
  //           if (TempNewData.channels[MyLevel[0]][i].subchannel[j].mykey === MyLevel[2]) {
  //             for (k = 0; k < TempNewData.channels[MyLevel[0]][i].subchannel[j].subchannel.length; k++) {
  //               if (TempNewData.channels[MyLevel[0]][i].subchannel[j].subchannel[k].mykey === MyLevel[3]) {
  //                 TempNewData.channels[MyLevel[0]][i].subchannel[j].subchannel[k].spends = Spends;
  //                 break
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }

  //   if (MyLevel.length === 5) {
  //     for (i = 0; i < TempNewData.channels[MyLevel[0]].length; i++) {
  //       if (TempNewData.channels[MyLevel[0]][i].mykey === MyLevel[1]) {
  //         for (j = 0; j < TempNewData.channels[MyLevel[0]][i].subchannel.length; j++) {
  //           if (TempNewData.channels[MyLevel[0]][i].subchannel[j].mykey === MyLevel[2]) {
  //             for (k = 0; k < TempNewData.channels[MyLevel[0]][i].subchannel[j].subchannel.length; k++) {
  //               if (TempNewData.channels[MyLevel[0]][i].subchannel[j].subchannel[k].mykey === MyLevel[3]) {
  //                 for (l = 0; l < TempNewData.channels[MyLevel[0]][i].subchannel[j].subchannel[k].subchannel.length; l++) {
  //                   if (TempNewData.channels[MyLevel[0]][i].subchannel[j].subchannel[k].subchannel[l].mykey === MyLevel[4]) {
  //                     TempNewData.channels[MyLevel[0]][i].subchannel[j].subchannel[k].subchannel[l].spends = Spends;
  //                     break
  //                   }
  //                 }
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  //   // console.log(TempNewData)
  //   setNewData(TempNewData);
  // };
  const updateSpendsRecursive = (levelData, currentLevel, spends) => {
    if (!levelData || isNaN(spends)) {
      return;
    }

    const [currentKey, ...remainingLevels] = currentLevel;

    for (let i = 0; i < levelData.length; i++) {
      if (levelData[i].mykey === currentKey) {
        if (remainingLevels.length === 0) {
          levelData[i].spends = spends;
        } else {
          updateSpendsRecursive(
            levelData[i].subchannel,
            remainingLevels,
            spends
          );
        }
        break;
      }
    }
  };

  const EditNewSpends = (MyLevel, Spends) => {
    if (isNaN(Spends)) {
      return false;
    }

    const TempNewDataCopy = JSON.parse(JSON.stringify(NewData));
    const MyLevelArray = MyLevel.split("|");

    updateSpendsRecursive(
      TempNewDataCopy.channels[MyLevelArray[0]],
      MyLevelArray.slice(1),
      Spends
    );

    setNewData(TempNewDataCopy);
  };

  const optParameter = {
    ...Parameter,
    json_cr: newDataForDownload,
    json_pr: prevDataForDownload,
  };

  // New code ..............start
  // const URLDownload = "http://15.207.123.147:8000/optimizer/";
  const DownloadOptimizer = () => {
    setLoader(true);
    axiosDownload
      .post("optimiser/", {
        ...Parameter,
        json_cr: newDataForDownload,
        json_pr: prevDataForDownload,
      })
      .then((response) => {
        var link = document.createElement("a");
        link.target = "_blank";
        link.href = response.data.data;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoader(false);
      })
      .catch((data) => {
        setLoader(false);
      });
  };

  const traverse = useCallback(
    (MyLevel, newValue, changed) => {
      // console.log("use call back called!", refresh, changed, channelID, type, newKey, newValue)
      const splitListOfChannelTypeAndKey = MyLevel.split("|");
      const channelType = splitListOfChannelTypeAndKey[0];
      // console.log("channelType: ", channelType);
      const channelKey =
        splitListOfChannelTypeAndKey[splitListOfChannelTypeAndKey.length - 1];
      // console.log("channelKey: ", channelKey);
      const updateChannel = (channel) => {
        channel[channelKey] = newValue;
        // let arr = channel[newKey];
        // channel.editSpends = arr[arr.length - 1];
        channel.changedSpends = newValue;
        channel.changed = changed;

        // channel.changed = changed;
        // channel.refresh = refresh;
      };
      const recursiveUpdate = (channelsArray) => {
        channelsArray.forEach((channel) => {
          if (channel.mykey === channelKey) {
            updateChannel(channel);
          } else if (channel.subchannel && channel.subchannel.length > 0) {
            recursiveUpdate(channel.subchannel);
          }
        });
      };

      Object.entries(allChannels.channels).forEach((item) => {
        if (channelType === item[0]) {
          recursiveUpdate(item[1]);
        }
      });

      // Update the state outside the map and recursion (if needed)
      setAllChannels((prevChannels) => ({ ...prevChannels }));
    },
    [allChannels, setAllChannels]
  );

  const EditNewSpendsOnBlur = (MyLevel, Spends) => {
    // console.log("MyLevel, Spends: ", MyLevel, Spends);
    // console.log("allChannels: ", allChannels);
    setIsExecuted(false);
    const splitListOfChannelTypeAndKey = MyLevel.split("|");
    // const channelType = splitListOfChannelTypeAndKey[0];
    // console.log("channelType: ", channelType);
    const channelKey =
      splitListOfChannelTypeAndKey[splitListOfChannelTypeAndKey.length - 1];
    // console.log("channelKey: ", channelKey);
    setUserInputKey(channelKey);
    setMyLevel(MyLevel);
    traverse(MyLevel, Spends, true);

    // console.log("keyyyyyyyyy", MyLevel.split("|").pop());
  };

  //  flat channels ................start...............

  const parent = (DATA, id) => {
    // console.log("parent running!!!!!!!!!!!!!");
    let flatObject = {};
    const flattenDATA = (channels, flatObject, id) => {
      const handleSubchannels = (subchannel, id) => {
        flatObject[subchannel.mykey] = [
          id, //brand
          // subchannel.mykey,
          subchannel.changedSpends, //changed value
          subchannel.color, //color
          subchannel.changed, //changed flag true | false
          // subchannel.P_MAX,
          // subchannel.sumofchild,
          subchannel.min_val,
          subchannel.max_val,
          subchannel.mykey,
          subchannel.spends,
        ];
        if (subchannel.subchannel && subchannel.subchannel.length > 0) {
          subchannel.subchannel.forEach((sub) => {
            handleSubchannels(sub, id);
          });
        }
      };
      Object.keys(channels).forEach((category) => {
        channels[category].forEach((channel) => {
          flatObject[channel.mykey] = [
            id,
            // channel.mykey,
            channel.changedSpends,
            channel.color,
            channel.changed,
            // channel.P_MAX,
            // channel.sumofchild,
            channel.min_val,
            channel.max_val,
            channel.mykey,
            channel.spends,
          ];
          if (channel.subchannel && channel.subchannel.length > 0) {
            channel.subchannel.forEach((subchannel) => {
              handleSubchannels(subchannel, id);
            });
          }
        });
      });
    };
    if (DATA.channels) {
      flattenDATA(DATA.channels, flatObject, id);
    }
    const handleInnerSublevel = (channels, flatObject, id) => {
      flattenDATA(channels, flatObject, id);
    };
    const handleSublevel = (sublevel) => {
      if (sublevel && sublevel.length > 0) {
        sublevel.forEach((sublevel) => {
          if (sublevel.data) {
            handleInnerSublevel(
              sublevel.data.channels,
              flatObject,
              sublevel.data.id
            );
          }
          if (sublevel.data.sublevel) {
            handleSublevel(sublevel.data.sublevel);
          }
        });
      }
    };

    handleSublevel(DATA.sublevel);
    return flatObject;
  };

  // flat channels ...............end...........

  const flattened = parent(allChannels, allChannels.brand?.split("-")[0]);

  console.log("flattened: ", flattened);

  const traverseUserInputKey = (userInputKey, flatObject) => {
    let VALUE; // for user input key --> l-hco-gar-trad-tv
    let brandId; // flatObj[0] l-hco-gar-trad-tv --> l-hco-gar
    let brandList; // [l-hco, l]
    let channelList; // [trad-tv, trad]
    let changedParentForUserInputKey = []; //which parent is changed for a userinputkey
    let changedChildrenForUserInputKey = []; // which children is  changed for a userinputkey

    Object.keys(flatObject).forEach((item) => {
      if (item === userInputKey) {
        VALUE = flatObject[item];
        brandId = VALUE[0];
        brandList = brandId.split("_");
      }
    });

    const generateParentBrandNames = (brandId) => {
      const parts = brandId?.split("_");
      const result = [];

      for (let i = 1; i < parts?.length; i++) {
        const brandName = parts?.slice(0, parts.length - i).join("_");
        result.push(brandName);
      }

      return result;
    };

    brandList = generateParentBrandNames(brandId);
    // console.log("brandlist", brandList);

    const findChannelParent = (foundKey) => {
      let str = foundKey.replace(`${brandId}_`, "");
      const parts = str.split("_");
      const output = [];

      for (let i = parts.length - 1; i > 0; i--) {
        const subArray = parts.slice(0, i);
        output.push(subArray.join("_"));
      }

      return output;
    };

    channelList = findChannelParent(userInputKey);
    const channelID = userInputKey.replace(`${brandId}_`, "");
    channelList.unshift(channelID);
    brandList.unshift(brandId);
    // console.log(channelID, brandId)
    // console.log(brandList, "brand list");
    // console.log("channel list", channelList);
    let allParentsForUserInputKey = []; // all parents  for userinputkey
    let allChildrenForUserInputKey = []; // all childs for userinputkey

    // code for find the all parents for userinputkey....start........
    brandList?.forEach((brand) => {
      // console.log("brand", brand);
      channelList?.forEach((channel) => {
        // console.log("channel", channel);
        let str = `${brand}_${channel}`;
        Object.keys(flatObject).forEach((item) => {
          if (item === str && str !== userInputKey) {
            allParentsForUserInputKey.push(item);
          }
        });
      });
    });
    // code for find the all parents for userinputkey.........end........

    // code for find the allchildren for userinputkey ............start.....
    const findAllChidlrenForUserInputKey = (flatObject, brandId, channelID) => {
      let arr = [];
      // let filterArr = [];
      Object.keys(flatObject).forEach((item) => {
        if (item.includes(brandId)) {
          arr.push(item);
        }
      });

      arr.forEach((channel) => {
        if (channel.includes(channelID)) {
          // console.log(channel);
          if (channel !== userInputKey) {
            allChildrenForUserInputKey.push(channel);
          }
        }
      });
    };

    findAllChidlrenForUserInputKey(flatObject, brandId, channelID);

    // code for find the allchildren for userinputkey ............end.....

    // console.log("brand id", brandId);
    // console.log("brand list", brandList);
    // console.log("all parents for userinputkey", allParentsForUserInputKey); // parent list of input key
    // console.log("all chidlren for userinputkey", allChildrenForUserInputKey); // child list for input key

    // which parent(s) is/are changed for user input key!

    allParentsForUserInputKey?.forEach((parent) => {
      // console.log("ppppp", parent)
      Object.keys(flatObject).forEach((innerkey) => {
        if (innerkey === parent) {
          if (flatObject[innerkey][3] === true) {
            // console.log("changed parent", parent);
            changedParentForUserInputKey.push(parent);
          }
        }
      });
    });

    //which child is changed for input key!
    allChildrenForUserInputKey?.forEach((child) => {
      // console.log("childrennnnnnnn", child)
      Object.keys(flatObject).forEach((innerkey) => {
        if (innerkey === child) {
          if (flatObject[innerkey][3] === true) {
            // console.log("changed parent", parent);
            changedChildrenForUserInputKey.push(child);
          }
        }
      });
    });

    // Logic to update P_MAX................................................................
    // for each child which parent is/are changed
    allChildrenForUserInputKey?.forEach((eachChild) => {
      // console.log("each child: ", eachChild);
      let intersection = [];
      const findParentForEachChild = (eachChild, flatObject) => {
        let VALUE; // for each child key
        let brandId;
        let brandList;
        let channelList;
        let changedParentForEachChildKey = []; //which parent is changed for eachchildkey

        Object.keys(flatObject).forEach((item) => {
          if (item === eachChild) {
            VALUE = flatObject[item];
            brandId = VALUE[0];
            brandList = brandId.split("_");
          }
        });

        const generateParentBrandNames = (brandId) => {
          const parts = brandId?.split("_");
          const result = [];

          for (let i = 1; i < parts?.length; i++) {
            const brandName = parts?.slice(0, parts.length - i).join("_");
            result.push(brandName);
          }

          return result;
        };

        brandList = generateParentBrandNames(brandId);
        // console.log("brandlist", brandList);

        const findChannelParent = (foundKey) => {
          let str = foundKey.replace(`${brandId}_`, "");
          const parts = str.split("_");
          const output = [];

          for (let i = parts.length - 1; i > 0; i--) {
            const subArray = parts.slice(0, i);
            output.push(subArray.join("_"));
          }

          return output;
        };

        channelList = findChannelParent(eachChild);
        const channelID = eachChild.replace(`${brandId}_`, "");
        channelList.unshift(channelID);
        brandList.unshift(brandId);
        // console.log(channelID, brandId)
        // console.log(brandList, "brand list");
        // console.log("channel list", channelList);
        let allParentsForEachChildKey = []; // all parents  for eachchildkey

        // code for find the all parents for userinputkey....start........
        brandList?.forEach((brand) => {
          // console.log("brand", brand);
          channelList?.forEach((channel) => {
            // console.log("channel", channel);
            let str = `${brand}_${channel}`;
            Object.keys(flatObject).forEach((item) => {
              if (item === str && str !== eachChild) {
                allParentsForEachChildKey.push(item);
              }
            });
          });
        });

        // changed parent

        allParentsForEachChildKey?.forEach((parent) => {
          // console.log("ppppp", parent)
          Object.keys(flatObject).forEach((innerkey) => {
            if (innerkey === parent) {
              if (flatObject[innerkey][3] === true) {
                // console.log("changed parent", parent);
                changedParentForEachChildKey.push(parent);
              }
            }
          });
        });

        // code for find the all parents for userinputkey.........end........
        // console.log(
        //   "all changed ParentsForEachChildKeyKey: ",
        //   eachChild,
        //   changedParentForEachChildKey
        // );

        intersection = intersectArrays(
          changedChildrenForUserInputKey,
          changedParentForEachChildKey
        );

        // console.log("intersection: ", intersection);

        if (intersection.length === 0) {
          // console.log("p max debuggggggggggg: ", flattened[userInputKey]);
          let P_MAX = 0;
          if (flattened[userInputKey][3] === true) {
            P_MAX = flattened[userInputKey][1];
          } else if (changedParentForEachChildKey?.length === 0) {
            P_MAX = DATA.spend;
          } else {
            // P_MAX = DATA.spend;
            // taking the p max from nearest parent
            P_MAX = flattened[changedParentForEachChildKey[0]]?.[1];
            // console.log("P max inside reset: ", flattened[changedParentForEachChildKey[0]][1])
          }
          // console.log("P_MAX", P_MAX, myObj[eachChild][1], myObj[eachChild][2], myObj[eachChild][3] )
          // myObj[eachChild][0] = P_MAX;
          let updatedArray = [...myObj[eachChild]];
          updatedArray = [
            P_MAX,
            myObj[eachChild][1],
            myObj[eachChild][2],
            myObj[eachChild][3],
          ];
          // console.log("updated array - P_MAX", updatedArray)
          setMyObj((prev) => ({
            ...prev,
            [eachChild]: updatedArray,
          }));
        }
        // console.log(
        //   "changed parent for each child key: ",
        //   changedParentForEachChildKey
        // );
      };
      // find parent for each child function end

      findParentForEachChild(eachChild, flatObject);
      function intersectArrays(array1, array2) {
        return array1.filter((element) => array2.includes(element));
      }
    });

    // for each child which parent is/are changed........end.......

    // .....................................................................................................................
    // ...........................................................................................................................
    allParentsForUserInputKey?.forEach((parent) => {
      // console.log("parent", parent);
      let channelChanged = []; // channel changed for each parent!
      let intersection = [];
      const traverseKey = (parent, flatObject) => {
        let brandId;

        // console.log("My Parent: ", parent);
        // console.log("Flatdict: ", flatObject[parent]);
        brandId = flatObject[parent][0];
        let channelID = parent.replace(`${brandId}_`, "");
        // console.log("bbbbbbbb", brandId, channelID);
        const findChild = (flatObject, brandId, channelID) => {
          // console.log("Inside Find Child: ", channelChanged);
          let arr = [];
          // let filterArr = [];
          Object.keys(flatObject).forEach((item) => {
            if (item.includes(brandId)) {
              arr.push(item);
            }
          });
          // console.log("array prinnt", arr);

          arr.forEach((channel) => {
            if (channel.includes(channelID)) {
              // console.log(channel);
              if (channel !== parent && flatObject[channel][3] === true) {
                channelChanged.push(channel);
              }
            }
          });
          // console.log('Travese End Log', channelChanged)
        };

        findChild(flatObject, brandId, channelID);
        function intersectArrays(array1, array2) {
          return array1.filter((element) => array2.includes(element));
        }
        intersection = intersectArrays(
          changedParentForUserInputKey,
          channelChanged
        );

        // console.log("intersection for sum of child: ", parent, intersection);
        // console.log("channel changed: ", parent, channelChanged);
        // console.log(
        //   "parent changed and child changed",
        //   parent,
        //   changedParentForUserInputKey,
        //   channelChanged
        // );
        if (intersection.length === 0) {
          // console.log("enterrrrrrrrrrrrrrrrrr", intersection.length);
          let parentChildVal = myObj[parent][1];
          // console.log("parent child value: ", parent, parentChildVal);
          let inputKeyChangedVal = 0;
          if (flattened[userInputKey][3] === true) {
            inputKeyChangedVal = flattened[userInputKey][1];
          } else {
            inputKeyChangedVal = 0;
          }
          // console.log("input key changed value: ", inputKeyChangedVal);
          let inputKeyChildVal = myObj[userInputKey][1];
          // console.log("Input key child value: ", userInputKey, inputKeyChildVal)

          Object.keys(flatObject).forEach((item) => {
            if (item === parent) {
              // console.log(item, "found parent");
              let diff = 0;

              diff = inputKeyChangedVal - myObj[userInputKey][3];
              const arr = [...myObj[userInputKey]];
              arr[2] = diff;
              // myObj[userInputKey][2] = diff;
              setMyObj((prev) => ({
                ...prev,
                [userInputKey]: arr,
              }));

              const sumofchild = parentChildVal + diff - inputKeyChildVal;

              // console.log("diff: ", userInputKey, diff);
              // console.log("prev changed value: ", myObj[userInputKey][3]);
              // console.log(
              //   "sum of child params: ",
              //   parent,
              //   parentChildVal,
              //   diff,
              //   inputKeyChildVal
              // );
              // console.log("sum of child: ", parent, sumofchild);

              // myObj[userInputKey][3] = inputKeyChangedVal;
              const arr2 = [...myObj[userInputKey]];
              arr2[3] = inputKeyChangedVal;
              // console.log("arrr33333333: ", arr2[3])

              setMyObj((prev) => ({
                ...prev,
                [userInputKey]: [
                  // DATA.spend,
                  // sumofchild,
                  // myObj[parent][2],
                  // myObj[parent][3],
                  ...arr2,
                ],
              }));

              const arr3 = [...myObj[parent]];

              arr3[1] = sumofchild;
              setMyObj((prev) => ({
                ...prev,
                [parent]: [...arr3],
              }));
            }
          });
        }
      };

      traverseKey(parent, flatObject);
    });
  };

  useEffect(() => {
    // const flattened = parent(DATA, DATA.id);
    // console.log("useeffect called!........");
    setFlatChannels(flattened);
    traverseUserInputKey(userInputKey, flattened);
    console.log(
      "all level children: ",
      findAllLevelChildren(userInputKey, flattened)
    );
  }, [userInputKey, allChannels]);

  const randomfun = () => {
    // console.log("random function called...........");
    for (const key in flatChannels) {
      // console.log("key in raondo: ", key);
      if (flatChannels.hasOwnProperty(key)) {
        myObj[key] = [allChannels.spend, 0, 0, 0]; // [budget, sumofchild, diff, prevchangedvalue]
        setRandomFunExecuted(true);
      }
    }
  };

  useEffect(() => {
    if (flatChannels !== null && !randomFunExecuted) {
      // console.log("inside useffect: ", flatChannels);
      randomfun();
    }
  }, [flatChannels, randomFunExecuted]);

  console.log("myObj", myObj);

  // validations start......................
  const getColorSum = (obj) => {
    const output = {};

    for (const key in obj) {
      // console.log("key in user bar fill: ", key);
      const changedParent = findAllChangedParents(key, flattened);
      // console.log("changedParent: ", changedParent);
      // const [color, value] = obj[key];
      const color = obj[key][2];
      const value = obj[key][1];

      if (changedParent && changedParent.length > 0) {
        // Don't add the value if a parent is changed
        continue;
      }

      if (output[color]) {
        // Check if value is undefined and set it to 0
        output[color] += value !== undefined ? value : 0;
      } else {
        // Check if value is undefined and set it to 0
        output[color] = value !== undefined ? value : 0;
      }
    }

    return output;
  };

  const userBarFillObject = getColorSum(flattened);
  // console.log("user bar sum object: ", userBarFillObject);
  // console.log("EditedAllocation: ", EditedAllocation);

  // code to fill user bar end...............

  // validations.....................start...............

  useEffect(() => {
    if (JSON.stringify(allChannels) !== "{}") {
      const allKeysTrue = allChannels?.channels[
        allChannels.brand?.split("-")[0]
      ]?.every((channelKey) => {
        // console.log("channelKey: ", channelKey);
        return flattened[channelKey.mykey][3] === true;
      });

      setAllTopLevelChanged(allKeysTrue);
    }
  }, [allChannels]);

  useEffect(() => {
    const userBarFillValues = Object.values(userBarFillObject);

    // console.log("userBarFillValues :", userBarFillValues);
    let all_keys_sum = userBarFillValues.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );

    all_keys_sum = all_keys_sum.toFixed(2);

    if (all_keys_sum > allChannels.spend) {
      // check for total budget
      setIsExecuted(true);
      setIsOpen2(true);
      setMessage2(
        `Total spend amount ${all_keys_sum} should not be more than ${allChannels.spend}`
      );
    } else if (
      !isExecuted &&
      allTopLevelChanged &&
      all_keys_sum < allChannels.spend
    ) {
      setIsExecuted(true);
      setIsOpen2(true);
      setAllTopLevelChanged(false);
      setMessage2(
        `Total spend amount ${all_keys_sum} should not be less than ${allChannels.spend}`
      );
    } else if (
      // check for p_max
      userInputKey !== "" &&
      flattened[userInputKey][1] > myObj[userInputKey][0]
    ) {
      setIsOpen2(true);
      setMessage2(
        `The entered value (${flattened[userInputKey][1]}) of ${userInputKey} is greater than its parent (${myObj[userInputKey][0]})`
      );
    } else if (userInputKey !== "") {
      Object.keys(myObj).forEach((key) => {
        if (
          // check if parent value is less than sum of children
          flattened[key][1] !== 0 &&
          myObj[key][1] !== 0 &&
          flattened[key][1] < myObj[key][1] &&
          !isExecuted
        ) {
          // console.log("key.............: ", flattened[key][1], myObj[key][1]);
          setIsOpen2(true);
          setIsExecuted(true);
          setMessage2(
            `${key} sub-channels sum (${myObj[key][1].toFixed(
              2
            )}) should be equal to ${key} spends (${flattened[key][1]})`
          );
        } else if (
          !isExecuted &&
          flattened[userInputKey][3] === true &&
          (flattened[userInputKey][1] < flattened[userInputKey][4] ||
            flattened[userInputKey][1] > flattened[userInputKey][5])
        ) {
          setIsOpen(true);
          setMessage(
            "Entered value falls outside range, Are you sure you want to do this change?"
          );
        } else {
          const allLevelChildren = findAllLevelChildren(key, flattened);
          // console.log("allLevelChildren: ", allLevelChildren["0"]);
          if (JSON.stringify(allLevelChildren) !== "{}") {
            let concatenatedArray = [];

            // Loop through each key in the data object
            for (let key in allLevelChildren) {
              // Get the keys of the current object
              let subKeys = Object.keys(allLevelChildren[key]);
              // Access the first array of the current key and concatenate it to the result array
              concatenatedArray = concatenatedArray.concat(
                allLevelChildren[key][subKeys[0]]
              );
            }

            // console.log("concatenatedArray: ", concatenatedArray, key);

            // console.log("arrarr: ", arr);

            // Loop through keys and find the first array

            // Logging the result
            // console.log("firstArray: ", key, firstArray);
            if (concatenatedArray.length === 0) {
              // console.log("firstArray: ", key, firstArray);
              setIsOpen2(true);
              setIsExecuted(true);
              if (flattened[key][3]) {
                setMessage2(
                  `${key} sub-channels sum (${myObj[key][1].toFixed(
                    2
                  )}) should be equal to ${key} spends (${flattened[key][1]})`
                );
              } else {
                setMessage2(
                  `${key} sub-channels sum (${myObj[key][1].toFixed(
                    2
                  )}) should be equal to ${key} spends (${flattened[key][7]})`
                );
              }
            }
          }
        }
      });
    }
  }, [allChannels, flattened]);

  // validatoins end........................

  // New code ..............end

  const EditNewSpendsOnBlurOld = (MyLevel, Spends) => {
    Spends = Spends === "" ? 0 : parseFloat(Spends);
    EditNewSpends(MyLevel, Spends);

    MyLevel = MyLevel.split("|");

    if (MyLevel.length === 2) {
      ValidateLevel1(MyLevel);
    }

    if (MyLevel.length === 3) {
      ValidateLevel2(MyLevel);
    }

    if (MyLevel.length === 4) {
      ValidateLevel3(MyLevel);
    }
    // console.log(MyLevel)
    // console.log(NewData)
    // console.log(NewFixedData)
  };

  const ValidateLevel1 = (MyLevel) => {
    let Index = "";
    let Index1 = "";
    let Index2 = "";
    let RangeError = false;
    let ChildTotalError = false;

    for (var i = 0; i < NewData.channels[MyLevel[0]].length; i++) {
      if (NewData.channels[MyLevel[0]][i].mykey === MyLevel[1]) {
        if (
          NewData.channels[MyLevel[0]][i].spends !==
          NewFixedData.channels[MyLevel[0]][i].spends
        ) {
          Index = i;
          const Spends = NewData.channels[MyLevel[0]][i].spends;

          if ("subchannel" in NewFixedData.channels[MyLevel[0]][i]) {
            var ChildTotal = 0;
            for (
              var s = 0;
              s < NewFixedData.channels[MyLevel[0]][i].subchannel.length;
              s++
            ) {
              if (
                NewData.channels[MyLevel[0]][i].subchannel[s].spends !==
                NewFixedData.channels[MyLevel[0]][i].subchannel[s].spends
              ) {
                ChildTotal += parseFloat(
                  NewData.channels[MyLevel[0]][i].subchannel[s].spends
                );
              }
            }

            if (ChildTotal > parseFloat(Spends)) {
              ChildTotalError = true;
            }
          }

          if (
            Spends > NewFixedData.channels[MyLevel[0]][i].max_val ||
            Spends < NewFixedData.channels[MyLevel[0]][i].min_val
          ) {
            RangeError = true;
          }
        }
        break;
      }
    }

    if (validate() > NewFixedData.spend) {
      setConfirmRange({
        ChannelName: MyLevel[0],
        Index: Index,
        Index1: Index1,
        Index2: Index2,
        message:
          "Total spend amount should not be more than " +
          parseFloat(NewFixedData.spend).toFixed(1) +
          NewFixedData.units,
        FalseText: "Okay",
      });
    } else {
      if (ChildTotalError) {
        setConfirmRange({
          ChannelName: MyLevel[0],
          Index: Index,
          Index1: Index1,
          Index2: Index2,
          message:
            "Total of " +
            MyLevel[1] +
            " sub channel spends should not be greater than " +
            MyLevel[1] +
            " spends",
          FalseText: "Okay",
        });
      } else {
        if (RangeError) {
          setConfirmRange({
            ChannelName: MyLevel[0],
            Index: Index,
            Index1: Index1,
            Index2: Index2,
            message:
              "Entered value falls outside range, Are you sure you want to do this change?",
            FalseText: "No",
          });
        }
      }
    }
  };

  const ValidateLevel2 = (MyLevel) => {
    let Index = "";
    let Index1 = "";
    let Index2 = "";
    let RangeError = false;
    let SpendNotMatchError = false;
    let SpendExceedLimitError = false;
    let ChildTotalError = false;
    let ChildChanged = 0;
    let ChildSpends = 0;

    for (var i = 0; i < NewData.channels[MyLevel[0]].length; i++) {
      if (NewData.channels[MyLevel[0]][i].mykey === MyLevel[1]) {
        for (
          var j = 0;
          j < NewData.channels[MyLevel[0]][i].subchannel.length;
          j++
        ) {
          if (
            NewData.channels[MyLevel[0]][i].subchannel[j].spends !==
            NewFixedData.channels[MyLevel[0]][i].subchannel[j].spends
          ) {
            ChildChanged += 1;
            ChildSpends += parseFloat(
              NewData.channels[MyLevel[0]][i].subchannel[j].spends
            );
          }

          if (
            NewData.channels[MyLevel[0]][i].subchannel[j].mykey === MyLevel[2]
          ) {
            Index = i;
            Index1 = j;

            const Spends = NewData.channels[MyLevel[0]][i].subchannel[j].spends;

            if ("subchannel" in NewData.channels[MyLevel[0]][i].subchannel[j]) {
              var ChildTotal = 0;
              for (
                var s = 0;
                s <
                NewData.channels[MyLevel[0]][i].subchannel[j].subchannel.length;
                s++
              ) {
                ChildTotal += parseFloat(
                  NewData.channels[MyLevel[0]][i].subchannel[j].subchannel[s]
                    .spends
                );
              }

              if (ChildTotal > parseFloat(Spends)) {
                ChildTotalError = true;
              }
            }

            if (
              Spends >
              NewFixedData.channels[MyLevel[0]][i].subchannel[j].max_val ||
              Spends <
              NewFixedData.channels[MyLevel[0]][i].subchannel[j].min_val
            ) {
              RangeError = true;
            }
            // break
          }
        }

        if (
          ChildChanged === NewData.channels[MyLevel[0]][i].subchannel.length &&
          ChildSpends !== NewData.channels[MyLevel[0]][i].spends
        ) {
          SpendNotMatchError = true;
        } else {
          if (
            NewData.channels[MyLevel[0]][i].spends !==
            NewFixedData.channels[MyLevel[0]][i].spends
          ) {
            if (ChildSpends > NewData.channels[MyLevel[0]][i].spends) {
              SpendExceedLimitError = true;
            }
          }
        }
        break;
      }
    }

    if (validate() > NewFixedData.spend) {
      setConfirmRange({
        ChannelName: MyLevel[0],
        Index: Index,
        Index1: Index1,
        Index2: Index2,
        message:
          "Total spend amount should not be more than " +
          parseFloat(NewFixedData.spend).toFixed(1) +
          NewFixedData.units,
        FalseText: "Okay",
      });
    } else {
      if (ChildTotalError) {
        setConfirmRange({
          ChannelName: MyLevel[0],
          Index: Index,
          Index1: Index1,
          Index2: Index2,
          message:
            "Total of " +
            MyLevel[2] +
            " sub channel spends should not be greater than " +
            MyLevel[2] +
            " spends",
          FalseText: "Okay",
        });
      } else {
        if (SpendExceedLimitError) {
          setConfirmRange({
            ChannelName: MyLevel[0],
            Index: Index,
            Index1: Index1,
            Index2: Index2,
            message:
              "Total of " +
              MyLevel[1] +
              " sub channel should not exceed " +
              NewData.channels[MyLevel[0]][Index].spends +
              " spends",
            FalseText: "Okay",
          });
        } else {
          if (SpendNotMatchError) {
            setConfirmRange({
              ChannelName: MyLevel[0],
              Index: Index,
              Index1: Index1,
              Index2: Index2,
              message:
                "Total of " +
                MyLevel[1] +
                " sub channel should be equal to " +
                MyLevel[1] +
                " spends",
              FalseText: "Okay",
            });
          } else {
            if (RangeError) {
              setConfirmRange({
                ChannelName: MyLevel[0],
                Index: Index,
                Index1: Index1,
                Index2: Index2,
                message:
                  "Entered value falls outside range, Are you sure you want to do this change?",
                FalseText: "No",
              });
            }
          }
        }
      }
    }
  };

  const ValidateLevel3 = (MyLevel) => {
    let Index = "";
    let Index1 = "";
    let Index2 = "";
    let RangeError = false;
    let SpendNotMatchError = false;
    let SpendExceedLimitError = false;
    let ChildChanged = 0;
    let ChildSpends = 0;

    for (var i = 0; i < NewData.channels[MyLevel[0]].length; i++) {
      if (NewData.channels[MyLevel[0]][i].mykey === MyLevel[1]) {
        for (
          var j = 0;
          j < NewData.channels[MyLevel[0]][i].subchannel.length;
          j++
        ) {
          if (
            NewData.channels[MyLevel[0]][i].subchannel[j].mykey === MyLevel[2]
          ) {
            for (
              var k = 0;
              k <
              NewData.channels[MyLevel[0]][i].subchannel[j].subchannel.length;
              k++
            ) {
              if (
                NewData.channels[MyLevel[0]][i].subchannel[j].subchannel[k]
                  .spends !==
                NewFixedData.channels[MyLevel[0]][i].subchannel[j].subchannel[k]
                  .spends
              ) {
                ChildChanged += 1;
                ChildSpends += parseFloat(
                  NewData.channels[MyLevel[0]][i].subchannel[j].subchannel[k]
                    .spends
                );
              }

              if (
                NewData.channels[MyLevel[0]][i].subchannel[j].subchannel[k]
                  .mykey === MyLevel[3]
              ) {
                Index = i;
                Index1 = j;
                Index2 = k;

                const Spends =
                  NewData.channels[MyLevel[0]][i].subchannel[j].subchannel[k]
                    .spends;
                if (
                  Spends >
                  NewFixedData.channels[MyLevel[0]][i].subchannel[j]
                    .subchannel[k].max_val ||
                  Spends <
                  NewFixedData.channels[MyLevel[0]][i].subchannel[j]
                    .subchannel[k].min_val
                ) {
                  RangeError = true;
                }
                // break
              }
            }

            if (
              ChildChanged ===
              NewData.channels[MyLevel[0]][i].subchannel[j].subchannel
                .length &&
              ChildSpends !==
              NewData.channels[MyLevel[0]][i].subchannel[j].spends
            ) {
              SpendNotMatchError = true;
            } else {
              if (
                NewData.channels[MyLevel[0]][i].subchannel[j].spends !==
                NewFixedData.channels[MyLevel[0]][i].subchannel[j].spends
              ) {
                if (
                  ChildSpends >
                  NewData.channels[MyLevel[0]][i].subchannel[j].spends
                ) {
                  SpendExceedLimitError = true;
                }
              }
            }
            break;
          }
        }
        break;
      }
    }

    if (validate() > NewFixedData.spend) {
      setConfirmRange({
        ChannelName: MyLevel[0],
        Index: Index,
        Index1: Index1,
        Index2: Index2,
        message:
          "Total spend amount should not be more than " +
          parseFloat(NewFixedData.spend).toFixed(1) +
          NewFixedData.units,
        FalseText: "Okay",
      });
    } else {
      if (SpendExceedLimitError) {
        setConfirmRange({
          ChannelName: MyLevel[0],
          Index: Index,
          Index1: Index1,
          Index2: Index2,
          message:
            "Total of " +
            MyLevel[2] +
            " sub channel should not exceed " +
            NewData.channels[MyLevel[0]][Index].subchannel[Index1].spends +
            " spends",
          FalseText: "Okay",
        });
      } else {
        if (SpendNotMatchError) {
          setConfirmRange({
            ChannelName: MyLevel[0],
            Index: Index,
            Index1: Index1,
            Index2: Index2,
            message:
              "Total of " +
              MyLevel[2] +
              " sub channel should be equal to " +
              MyLevel[2] +
              " spends",
            FalseText: "Okay",
          });
        } else {
          if (RangeError) {
            setConfirmRange({
              ChannelName: MyLevel[0],
              Index: Index,
              Index1: Index1,
              Index2: Index2,
              message:
                "Entered value falls outside range, Are you sure you want to do this change?",
              FalseText: "No",
            });
          }
        }
      }
    }
  };

  const validate = () => {
    var AllTotal = 0;
    function countTotal(NewList, FixedList) {
      var ListTotal = 0;
      for (var n = 0; n < NewList.length; n++) {
        if (NewList[n].spends !== FixedList[n].spends) {
          ListTotal += parseFloat(NewList[n].spends);
        } else {
          if ("subchannel" in NewList[n]) {
            ListTotal += countTotal(
              NewList[n].subchannel,
              FixedList[n].subchannel
            );
          }
        }
      }
      return ListTotal;
    }

    for (const [key] of Object.entries(NewData.channels)) {
      const Total = countTotal(
        NewData.channels[key],
        NewFixedData.channels[key]
      );
      AllTotal += Total;
    }
    return AllTotal;
  };

  // const validate = () => {
  //   var changed = [];
  //   var obj = {};
  //   var i = 0;
  //   var j = 0;
  //   var total = 0

  //   for (const [key] of Object.entries(NewData.channels)) {
  //     for (i = 0; i < NewData.channels[key].length; i++) {
  //       if (NewData.channels[key][i].spends !== NewFixedData.channels[key][i].spends) {
  //         obj = {};
  //         obj[key + "-" + NewData.channels[key][i].mykey] = parseFloat(NewData.channels[key][i].spends);
  //         changed.push(obj);
  //         total += parseFloat(NewData.channels[key][i].spends);
  //       }

  //       if ('subchannel' in NewData.channels[key][i]) {
  //         for (j = 0; j < NewData.channels[key][i].subchannel.length; j++) {
  //           if (NewData.channels[key][i].subchannel[j].spends !== NewFixedData.channels[key][i].subchannel[j].spends) {
  //             obj = {};
  //             obj[key + "-" + NewData.channels[key][i].mykey + "-" + NewData.channels[key][i].subchannel[j].mykey] = parseFloat(NewData.channels[key][i].subchannel[j].spends);
  //             changed.push(obj);
  //             total += parseFloat(NewData.channels[key][i].subchannel[j].spends);
  //           }
  //         }
  //       }
  //     }
  //   }
  //   return total
  // }

  // const EditNewSpendsOnBlur = (ChannelName, Index, Spends, Index1 = '') => {
  //   Spends = (Spends === '') ? 0 : parseFloat(Spends)
  //   var TempNewData = JSON.parse(JSON.stringify(NewData));
  //   var ChannelData = null
  //   if (Index1 === '') {
  //     TempNewData.channels[ChannelName][Index].spends = Spends;
  //     ChannelData = NewData.channels[ChannelName][Index]
  //   } else {
  //     TempNewData.channels[ChannelName][Index].subchannel[Index1].spends = Spends;
  //     ChannelData = NewData.channels[ChannelName][Index].subchannel[Index1]
  //   }

  //   setNewData(TempNewData);

  //   var Flag = true
  //   if (Index1 !== '' || true) {
  //     if (NewData.channels[ChannelName][Index].spends !== NewFixedData.channels[ChannelName][Index].spends) {
  //       var TempTotal = 0
  //       var SubCount = 0
  //       for (var i = 0; i < NewData.channels[ChannelName][Index].subchannel.length; i++) {
  //         if (NewData.channels[ChannelName][Index].subchannel[i].spends !== NewFixedData.channels[ChannelName][Index].subchannel[i].spends) {
  //           TempTotal += parseFloat(NewData.channels[ChannelName][Index].subchannel[i].spends)
  //           SubCount += 1
  //         }
  //       }

  //       if (SubCount === NewData.channels[ChannelName][Index].subchannel.length && parseFloat(NewData.channels[ChannelName][Index].spends) !== TempTotal) {
  //         Flag = false
  //         setConfirmRange({
  //           ChannelName: ChannelName,
  //           Index: Index,
  //           Index1: Index1,
  //           message: 'Total of ' + ChannelName + ' sub channel should be equal to ' + ChannelName + ' spends',
  //           FalseText: 'Okay'
  //         })
  //       } else {
  //         if (TempTotal > NewData.channels[ChannelName][Index].spends) {
  //           Flag = false
  //           setConfirmRange({
  //             ChannelName: ChannelName,
  //             Index: Index,
  //             Index1: Index1,
  //             message: 'Total of ' + ChannelName + ' sub channel should not exceed ' + NewData.channels[ChannelName][Index].spends,
  //             FalseText: 'Okay'
  //           })
  //         }
  //       }
  //     }
  //   }

  //   if (validate() > NewFixedData.spend) {
  //     setConfirmRange({
  //       ChannelName: ChannelName,
  //       Index: Index,
  //       Index1: Index1,
  //       message: 'Total spend amount should not be more than ' + NewFixedData.spend + NewFixedData.units,
  //       FalseText: 'Okay'
  //     })
  //   } else {
  //     if ((Spends > ChannelData.max_val || Spends < ChannelData.min_val) && Flag) {
  //       setConfirmRange({
  //         ChannelName: ChannelName,
  //         Index: Index,
  //         Index1: Index1,
  //         message: 'Entered value falls outside range, Are you sure you want to do this change?',
  //         FalseText: 'No'
  //       })
  //     }
  //   }
  // };

  const ResetSpends = (ChannelName, Index, Index1 = "") => {
    var TempNewData = JSON.parse(JSON.stringify(NewData));
    if (Index1 === "") {
      TempNewData.channels[ChannelName][Index].spends =
        NewFixedData.channels[ChannelName][Index].spends;
    } else {
      TempNewData.channels[ChannelName][Index].subchannel[Index1].spends =
        NewFixedData.channels[ChannelName][Index].subchannel[Index1].spends;
    }
    setNewData(TempNewData);
  };

  const AletrResponse = (response) => {
    if (!response) {
      var TempNewData = JSON.parse(JSON.stringify(NewData));
      if ("Index2" in ConfirmRange && ConfirmRange.Index2 !== "") {
        TempNewData.channels[ConfirmRange.ChannelName][
          ConfirmRange.Index
        ].subchannel[ConfirmRange.Index1].subchannel[
          ConfirmRange.Index2
        ].spends =
          NewFixedData.channels[ConfirmRange.ChannelName][
            ConfirmRange.Index
          ].subchannel[ConfirmRange.Index1].subchannel[
            ConfirmRange.Index2
          ].spends;
      } else {
        if (ConfirmRange.Index1 === "") {
          TempNewData.channels[ConfirmRange.ChannelName][
            ConfirmRange.Index
          ].spends =
            NewFixedData.channels[ConfirmRange.ChannelName][
              ConfirmRange.Index
            ].spends;
        } else {
          TempNewData.channels[ConfirmRange.ChannelName][
            ConfirmRange.Index
          ].subchannel[ConfirmRange.Index1].spends =
            NewFixedData.channels[ConfirmRange.ChannelName][
              ConfirmRange.Index
            ].subchannel[ConfirmRange.Index1].spends;
        }
      }
      setNewData(TempNewData);
    }
    setConfirmRange({});
  };

  useEffect(() => {
    if (JSON.stringify(NewFixedData) !== "{}") {
      var i = 0;
      var Allocation = [];
      var GrowthData = [];
      var GrowthLabel = [];
      var GrowthColor = [];
      var ContributionTemp = [];

      for (const [key] of Object.entries(NewFixedData.channels)) {
        var Total = 0;
        var Contri = 0;
        for (i = 0; i < NewFixedData.channels[key].length; i++) {
          Total += parseFloat(NewFixedData.channels[key][i].spends);
          Contri += parseFloat(NewFixedData.channels[key][i].contri);
        }
        Allocation.push({
          name: key + " " + parseInt(Total) + " " + NewFixedData.units,
          y: Total,
          color: NewFixedData.channels[key][0].color,
          dataLabels: {
            enabled: true,
          },
        });

        GrowthData.push(
          (
            (Contri * NewFixedData.growth_per) /
            NewFixedData.growth_value
          ).toFixed(2)
        );
        GrowthLabel.push(key);
        GrowthColor.push(NewFixedData.channels[key][0].color);

        ContributionTemp.push({
          label: key,
          barThickness: 12,
          backgroundColor: NewFixedData.channels[key][0].color,
          data: [
            ((Total / NewFixedData.spend) * 100).toFixed(2),
            ((Contri / NewFixedData.growth_value) * 100).toFixed(2),
          ],
        });
      }

      setGrowthDistribution({
        GrowthData: GrowthData,
        GrowthLabel: GrowthLabel,
        GrowthColor: GrowthColor,
      });

      setContribution(ContributionTemp);

      setSuggestedAllocation(Allocation);
    }
  }, [NewFixedData]);

  useEffect(() => {
    if (JSON.stringify(NewData) !== "{}") {
      // var i = 0;
      // var j = 0;
      var Allocation = [];
      var AllTotal = 0;

      function countTotal(NewList, FixedList) {
        var ListTotal = 0;
        for (var n = 0; n < NewList.length; n++) {
          if (NewList[n].spends !== FixedList[n].spends) {
            ListTotal += parseFloat(NewList[n].spends);
          } else {
            if ("subchannel" in NewList[n]) {
              ListTotal += countTotal(
                NewList[n].subchannel,
                FixedList[n].subchannel
              );
            }
          }
        }
        return ListTotal;
      }

      for (const [key] of Object.entries(NewData.channels)) {
        const Total = countTotal(
          NewData.channels[key],
          NewFixedData.channels[key]
        );
        if (Total > 0) {
          AllTotal += Total;
          Allocation.push({
            name: key + " " + parseInt(Total) + " " + NewData.units,
            y: Total,
            color: NewData.channels[key][0].color,
            dataLabels: {
              enabled: true,
            },
          });
        }
      }

      // console.log(AllTotal)

      // for (const [key] of Object.entries(NewData.channels)) {
      //   var Total = 0;
      //   for (i = 0; i < NewData.channels[key].length; i++) {
      //     if (NewData.channels[key][i].spends !== NewFixedData.channels[key][i].spends && NewFixedData.channels[key][i].fillflag === 1) {
      //       Total += parseFloat(NewData.channels[key][i].spends);
      //     } else {
      //       if ('subchannel' in NewData.channels[key][i]) {
      //         for (j = 0; j < NewData.channels[key][i].subchannel.length; j++) {
      //           if (NewData.channels[key][i].subchannel[j].spends !== NewFixedData.channels[key][i].subchannel[j].spends && NewFixedData.channels[key][i].fillflag === 1) {
      //             Total += parseFloat(NewData.channels[key][i].subchannel[j].spends);
      //           }
      //         }
      //       }
      //     }
      //   }

      //   if (Total > 0) {
      //     AllTotal += Total
      //     Allocation.push({
      //       name:
      //         key + " " +
      //         parseInt(Total) +
      //         " " +
      //         NewData.units,
      //       y: Total,
      //       color: NewData.channels[key][0].color,
      //       dataLabels: {
      //         enabled: true,
      //       },
      //     });
      //   }
      // }

      Allocation.push({
        name: " " + parseInt(NewData.spend - AllTotal) + " " + NewData.units,
        y: NewData.spend - AllTotal > 0 ? NewData.spend - AllTotal : 0,
        color: "rgba(0,0,0,0)",
        dataLabels: {
          enabled: true,
        },
      });

      setEditedAllocation(Allocation);
    }
  }, [NewData, NewFixedData]);

  const [Re, setRe] = useState(1);
  console.log("Re inside Main.js: ", Re);

  const Optimise = () => {
    var changed = [];
    var obj = {};
    // var i = 0;
    // var j = 0;
    var total = 0;

    function countChanged(MyLevel, NewList, FixedList) {
      var ListTotal = 0;
      for (var n = 0; n < NewList.length; n++) {
        const NewLevel = MyLevel + "-" + NewList[n].mykey;

        let add = true;
        if (NewList[n].spends !== FixedList[n].spends) {
          ListTotal += parseFloat(NewList[n].spends);
          obj = {};
          obj[NewLevel] = parseFloat(NewList[n].spends);
          changed.push(obj);
          add = false;
        }

        if ("subchannel" in NewList[n]) {
          const SubTotal = countChanged(
            NewLevel,
            NewList[n].subchannel,
            FixedList[n].subchannel
          );
          if (add === true) {
            ListTotal += SubTotal;
          }
        }
      }
      return ListTotal;
    }

    for (const [key] of Object.entries(NewData.channels)) {
      total += countChanged(
        key,
        NewData.channels[key],
        NewFixedData.channels[key]
      );
    }

    // for (const [key] of Object.entries(NewData.channels)) {
    //   for (i = 0; i < NewData.channels[key].length; i++) {
    //     if (NewData.channels[key][i].spends !== NewFixedData.channels[key][i].spends) {
    //       obj = {};
    //       obj[key + "-" + NewData.channels[key][i].mykey] = parseFloat(NewData.channels[key][i].spends);
    //       changed.push(obj);
    //       total += parseFloat(NewData.channels[key][i].spends);
    //     }

    //     if ('subchannel' in NewData.channels[key][i]) {
    //       for (j = 0; j < NewData.channels[key][i].subchannel.length; j++) {
    //         if (NewData.channels[key][i].subchannel[j].spends !== NewFixedData.channels[key][i].subchannel[j].spends) {
    //           obj = {};
    //           obj[key + "-" + NewData.channels[key][i].mykey + "-" + NewData.channels[key][i].subchannel[j].mykey] = parseFloat(NewData.channels[key][i].subchannel[j].spends);
    //           changed.push(obj);
    //           total += parseFloat(NewData.channels[key][i].subchannel[j].spends);
    //         }
    //       }
    //     }
    //   }
    // }

    if (total > NewFixedData.spend) {
      setAlertMessage(
        "Total spend amount should not be more than " +
        NewFixedData.spend +
        NewFixedData.units
      );
    } else {
      const parameter = Parameter;
      parameter.optimization_type = "budget";
      parameter.budget = NewFixedData.spend;
      parameter.changed = changed;
      Parameter.ddtag = SelectedYear;
      // console.log(parameter);
      setLoader(true);
      axios
        .post("optimise/", parameter)
        .then((response) => {
          if (response.data.error === 1) {
            toast.error(response.data.erroMsg);
            setLoader(false);
          } else {
            // setNewData(response.data.data);
            // setNewFixedData(response.data.data);
            // setCurrentResponse(response.data)
            setNewSaveData(response.data.data);
            setNewData(response.data.data.data);
            setNewFixedData(response.data.data.data);
            setNewDataForDownload(response.data.data);
            setAllChannels(response.data.data.data);
            setDATA(response.data.data.data);
            setNotes(response.data.notes);
            setCaraouselChartdict(response.data.data.caraouselchartdict);
            setCaraouselSummarydict(response.data.data.caraouselsummarydict);
            setOptChartdict(response.data.data.optchartdict);
            setOptSummarydict(response.data.data.optsummarydict);
            setAllowEdit(false);
            setLoader(false);
          }
        })
        .catch(() => {
          setLoader(false);
        });
    }
  };

  const DownloadData = () => {
    setLoader(true);
    axiosDownload
      .post("optimiser/", {
        currentdict: {
          data: NewFixedData,
        },
        prevdict: {
          data: PreviousData,
        },
      })
      .then((response) => {
        var link = document.createElement("a");
        link.target = "_blank";
        link.href = response.data.data;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoader(false);
      })
      .catch((data) => {
        setLoader(false);
      });
  };

  const handleExpandChannel = (channel, index) => {
    const Expand = JSON.parse(JSON.stringify(ChannelExpand));
    if (channel in Expand) {
      Expand[channel].push(index);
    } else {
      const temp = [];
      temp.push(index);
      Expand[channel] = temp;
    }
    setChannelExpand(Expand);
  };

  const handleShrinkChannel = (channel, index) => {
    const Expand = JSON.parse(JSON.stringify(ChannelExpand));
    if (channel in Expand) {
      const i = Expand[channel].indexOf(index);
      if (i > -1) {
        Expand[channel].splice(i, 1);
      }
    }
    setChannelExpand(Expand);
  };

  const handleExpandChannel1 = (channel, mykey, index1) => {
    const Expand = JSON.parse(JSON.stringify(ChannelExpand1));
    if (channel in Expand) {
      // Expand[channel].push(index)
      Expand[channel][mykey].push(index1);
    } else {
      const temp = [];
      temp.push(index1);

      const channelTemp = {};
      channelTemp[mykey] = temp;
      Expand[channel] = channelTemp;
    }
    setChannelExpand1(Expand);
  };

  const handleShrinkChannel1 = (channel, mykey, index1) => {
    const Expand = JSON.parse(JSON.stringify(ChannelExpand1));
    if (channel in Expand) {
      const i = Expand[channel][mykey].indexOf(index1);
      if (i > -1) {
        Expand[channel][mykey].splice(i, 1);
      }
    }
    setChannelExpand1(Expand);
  };

  const AddLevel = (MyLevel) => {
    const LevelsTemp = JSON.parse(JSON.stringify(Levels));
    LevelsTemp.push(MyLevel);
    setLevels(LevelsTemp);
  };

  const RemoveLevel = (MyLevel) => {
    const LevelsTemp = JSON.parse(JSON.stringify(Levels));
    LevelsTemp.splice(LevelsTemp.indexOf(MyLevel), 1);
    setLevels(LevelsTemp);
  };

  // useEffect(() => {
  //   if (SelectedYear !== '' && CurrentResponse !== null && PreviousResponse !== null) {
  //     const ParamData = {
  //       "ddtag": SelectedYear,
  //       "curjson": CurrentResponse,
  //       "prevjson": PreviousResponse
  //     }

  //     setLoader(true);
  //     axios
  //       .post("get_opt_prev_json_summary/", ParamData)
  //       .then((response) => {
  //         if (response.data.error === 1) {
  //           toast.error(response.data.erroMsg);
  //           setLoader(false);
  //         } else {
  //           // setOptSummarydict(response.data.data)
  //           setLoader(false);
  //         }
  //       })
  //       .catch(() => {
  //         setLoader(false);
  //       });
  //   }
  // }, [SelectedYear, CurrentResponse, PreviousResponse])

  const [ShowPopup, setShowPopup] = useState(false);
  const [ChartData, setChartData] = useState({});
  const [LineFilter, setLineFilter] = useState("");
  const setChartDataMain = (data, Filter) => {
    setChartData(data);
    setLineFilter(Filter);
    setShowPopup(true);
  };

  return (
    <>

      <ToastContainer theme="colored" />
      {loader ? <Loader /> : null}


      {ShowPopup ? (
        <PopUp setShowPopup={setShowPopup} height="70%">
          {JSON.stringify(ChartData) !== "{}" ? (
            <>
              {ChartData.type === "column" &&
                JSON.stringify(ChartData.data) !== "{}" ? (
                <ColumnChart
                  data={ChartData.data}
                  ChartHeight={"100%"}
                  ChartWidth={"100%"}
                />
              ) : null}
              {ChartData.type === "line" &&
                JSON.stringify(ChartData.data) !== "{}" ? (
                <LineChart
                  data={ChartData.data[LineFilter]}
                  ChartHeight={"50%"}
                  ChartWidth={"100%"}
                />
              ) : null}
              {ChartData.type === "table" &&
                JSON.stringify(ChartData.data) !==
                "{}" ? (
                <Table
                  data={ChartData.data}
                  ChartHeight={"90%"}
                  ChartWidth={"100%"}
                />
              ) : null}
              {ChartData.type === "stack" &&
                JSON.stringify(ChartData.data) !== "{}" ? (
                <StackChart
                  data={ChartData.data}
                  ChartHeight={"100%"}
                  ChartWidth={"100%"}
                />
              ) : null}
              {ChartData.type === "waterfall" &&
                JSON.stringify(ChartData.data) !== "{}" ? (
                <WaterfallChart
                  data={ChartData.data}
                  ChartHeight={"90%"}
                  ChartWidth={"100%"}
                />
              ) : null}
            </>
          ) : null}
        </PopUp>
      ) : null}

      {JSON.stringify(ConfirmRange) !== "{}" ? (
        <RangeConfirmAlert
          AletrResponse={AletrResponse}
          message={ConfirmRange.message}
          FalseText={ConfirmRange.FalseText}
        />
      ) : null}
      {AlertMessage ? (
        <AlertBox
          AlertMessage={AlertMessage}
          setAlertMessage={setAlertMessage}
        />
      ) : null}
      {SaveResult ? (
        <SaveResultAlert
          SaveResponse={SaveResponse}
          SaveOptimisation={SaveOptimisation}
          Parameter={Parameter}
          result_name={result_name}
          setResultName={setResultName}
          setSaveResult={setSaveResult}
        />
      ) : null}
      <Topbar />
      <Menubar />
      <div className="main-content">
        <div className="page-content">
          {PreviousData.length > 0 &&
            JSON.stringify(NewData) !== "{}" &&
            JSON.stringify(NewFixedData) !== "{}" &&
            DeepDive ? (
            <SkewbSimulator
              EditNewSpends={EditNewSpends}
              EditNewSpendsOnBlur={EditNewSpendsOnBlur}
              ResetSpends={ResetSpends}
              Optimise={Optimise}
              setAllowEdit={setAllowEdit}
              setChartActiveTab={setChartActiveTab}
              YearHandler={YearHandler}
              setSaveResult={setSaveResult}
              DownloadData={DownloadData}
              PreviousData={PreviousData}
              NewData={NewData}
              NewFixedData={NewFixedData}
              AllowEdit={AllowEdit}
              SuggestedAllocation={SuggestedAllocation}
              EditedAllocation={EditedAllocation}
              ChartActiveTab={ChartActiveTab}
              GrowthDistribution={GrowthDistribution}
              Contribution={Contribution}
              SelectedYear={SelectedYear}
              Summary={Summary}
              Notes={Notes}
              ChannelExpand={ChannelExpand}
              ChannelExpand1={ChannelExpand1}
              handleExpandChannel={handleExpandChannel}
              handleShrinkChannel={handleShrinkChannel}
              handleExpandChannel1={handleExpandChannel1}
              handleShrinkChannel1={handleShrinkChannel1}
              Levels={Levels}
              AddLevel={AddLevel}
              RemoveLevel={RemoveLevel}
              OptChartdict={OptChartdict}
              OptSummarydict={OptSummarydict}
              setChartDataMain={setChartDataMain}
              traverse={traverse}
              DownloadOptimizer={DownloadOptimizer}
              flattened={flattened}
              setUserInputKey={setUserInputKey}
              setIsExecuted={setIsExecuted}
              optParameter={optParameter}
              setRe={setRe}
              Re={Re}
            />
          ) : (
            BrandName && <OptimiseForm
              OptimizedData={OptimizedData}
              BrandName={BrandName}
              hirarchy_level={hirarchy_level}
              setHirarchy_level={setHirarchy_level}
              setBrandName={setBrandName}
              KPIName={KPIName}
              setKPIName={setKPIName}
              PreviousData={PreviousData}
              NewData={NewData}
              NewFixedData={NewFixedData}
              setDeepDive={setDeepDive}
              CaraouselChartdict={CaraouselChartdict}
              CaraouselSummarydict={CaraouselSummarydict}
              clickedOptmize={clickedOptmize}
              KPIMatch={KPIMatch}
              setKPIMatch={setKPIMatch}


            />
          )}
          <Alert2 message={message2} />
          <Alert message={message} />
        </div>
      </div>
    </>
  );
};

export default Main;
