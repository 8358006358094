import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useLayoutEffect,
} from "react";
import Topbar from "../Components/Topbar";
import Menubar from "../Components/Menubar";
import axios from "../Axios";
import axiosDownload from "../Axios"; // will change in future
import Waterfall from "../Components/Waterfall";
import Piechart from "../Components/PieChart";
import Loader from "../Components/Loader";
import { useLocation, Link } from "react-router-dom";
import Download from "../images/download-icon.png";
import { Tooltip } from "react-tooltip";
import InfoIcon from "../images/icons8-info.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PopUp from "../Components/PopUp";
import ChannelFilter from "../Components/ChannelFilter";
import AllHeirarchyFilter from "../Components/HeirarchyFilter";
import SummaryAdstock from "../Components/SummaryAdstock";
import LightThemeHexCode from "../Components/LightThemeColorCode";
import useDownloadChart from "../Components/DownloadCharts";
import domtoimage from "dom-to-image";
import axiosChartDownload from "../Axios";
import downloadFlag from "../DownloadFlag";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import configDriven from "../ConfirgDriven";
import CustomSelect from "../Components/CustomSelect";
import MultiCal from "../Components/MultiCal";

import ShimmerBarChart from '../Components/ShimmerBarChart';


const Main = () => {
  const location = useLocation();
  const refGraphDiv = useRef(null);
  const divHeight = useRef(null);

  const displayNames = JSON.parse(localStorage.getItem("displayNames"));

  const returnDisplayName = (id) => {
    if (configDriven.show_display_name && displayNames?.hasOwnProperty(id)) {
      return displayNames[id];
    } else {
      return id;
    }
  };

  function getFormattedString(id, obj) {
    console.log("id: ", id);
    if (configDriven.show_display_name) {
      // Split the id by hyphens
      const keys = id.split("-");

      // Map the keys to their corresponding values in the object
      const values = keys.map((key) => obj[key]);

      // Join the values with hyphens and return the result
      return values.join("-");
    } else {
      return id;
    }
  }

  const displayNamesChart = JSON.parse(localStorage.getItem("displayNames"));
  const DefaultLevel = JSON.parse(localStorage.getItem('DefaultLevel'));

  const [DivHeight, setDivHeight] = useState("");
  const Analyse = JSON.parse(localStorage.getItem("Analyse"));
  const [loader, setLoader] = useState(false);
  const [TimeFramesAll, setTimeFramesAll] = useState([]);
  const [timeframe, setTimeframe] = useState("");

  const [WaterfallData, setWaterfallData] = useState([]);
  const [Summary, setSummary] = useState({ self: {} });
  const [SummaryFetched, setSummaryFetched] = useState([]);
  const [Stack, setStack] = useState({});
  const [SelectedLabel, setSelectedLabel] = useState("");
  const [Channel, setChannel] = useState([]);
  const [Title, setTitle] = useState("");
  const [xtitle, setxtitle] = useState("");
  const [ytitle, setytitle] = useState("");
  const [Notes, setNotes] = useState("");
  const [AlternateNotes, setAlternateNotes] = useState("");

  const [Categories, setCategories] = useState([]);
  const [FetchCall, setFetchCall] = useState(false);
  const [Reset, setReset] = useState(0);
  const [IsAllEnabled, setIsAllEnabled] = useState(0);

  // on change of hierarchy or channel filter
  const [changeFilter, setChangeFilter] = useState(false);

  const [AllChannelCategory, setAllChannelCategory] = useState([]);
  const [ChannelCategory, setChannelCategory] = useState("");
  const [ChannelCategoryFlag, setChannelCategoryFlag] = useState(false);

  //Analytical filters state
  const AnalyticFilter = JSON.parse(localStorage.getItem("AnalyticFilter"));
  const CampaignAnalyticFilter = JSON.parse(
    localStorage.getItem("CampaignAnalyticFilter")
  );
  const [AnalyticFilterFlag, setAnalyticFilterFlag] = useState(true);
  const [FooterNotes, setFooterNotes] = useState("");

  // const [FilterType, setFilterType] = useState(false)
  const [AllFilters, setAllFilters] = useState([]);

  const [ActiveFilters, setActiveFilters] = useState([]);

  const Fixed = localStorage.getItem("AnalyticFilter")
    ? AnalyticFilter.Fixed
    : [];

  const [Chartdata, setChartdata] = useState({});

  // const [ClickedCategory, setClickedCategory] = useState(null);
  const [NotAllow, setNotAllow] = useState([]);

  const [FilterTabShow, setFilterTabShow] = useState(false);
  const [Unit, setUnit] = useState("");

  //Comp filters state
  const [CompAllFilters, setCompAllFilters] = useState([]);
  const [CompActiveFilters, setCompActiveFilters] = useState([]);

  const [Downloadchart, setDownloadchart] = useState(false);
  const [BreadCrumbs, setBreadCrumbs] = useState([]);
  const [ShowSummary, setShowSummary] = useState(0);
  const [ResetFilterFlag, setResetFilterFlag] = useState(false);

  const [isDownloadingChart, setIsDownloadingChart] = useState(false);
  const [chartContainerID, setChartContainerID] = useState("");
  const [downloadedImageName, setDownloadedImageName] = useState("");
  const [downloadingChartTitle, setDownloadingChartTitle] = useState("");
  const userName = localStorage.getItem("NameSkewb");

  const [IsAllEnabledDownload, setIsAllEnabledDownload] = useState(0);

  const [isRightSidebarVisible, setIsRightSidebarVisible] = useState(false);
  const [hierarchySelectionBreadCrumb, setHierarchySelectionBreadCrumb] =
    useState("");
  const [channelSelectionBreadCrumb, setChannelSelectionBreadCrumb] =
    useState("");

  const [startdate, setStartdate] = useState("");
  const [enddate, setEnddate] = useState("");
  const [DefaultStartDate, setDefaultStartDate] = useState("");
  const [DefaultEndDate, setDefaultEndDate] = useState("");

  const [isSubmitEnabled, setIsSubmitEnabled] = useState(true);
  const [submittedStartDate, setSubmittedStartDate] = useState("");
  const [submittedEndDate, setSubmittedEndDate] = useState("");
  const [submittedTimeFrame, setSubmittedTimeFrame] = useState(timeframe);
  const [isRefreshEnabled, setIsRefreshEnabled] = useState(false);

  const [level, setLevel] = useState(null);

  useEffect(() => {
    if (DefaultStartDate !== submittedStartDate || DefaultEndDate !== submittedEndDate) {
      setIsRefreshEnabled(true);
    }

    if (DefaultStartDate === submittedStartDate && DefaultEndDate === submittedEndDate) {
      setIsRefreshEnabled(false);
    }

  }, [DefaultStartDate, DefaultEndDate])

  useEffect(() => {
    setLevel(DefaultLevel);
  }, [])

  // useEffect(()=>{
  //   setIsSubmitEnabled(!isRefreshEnabled);
  // },[isRefreshEnabled])


  // console.log("IsAllEnabledDownload: ", IsAllEnabledDownload);

  useDownloadChart(
    downloadedImageName,
    downloadingChartTitle,
    isDownloadingChart,
    chartContainerID,
    BreadCrumbs,
    setLoader,
    Analyse.Brand,
    timeframe,
    "",
    "",
    setIsDownloadingChart,
    Analyse.KPI
  );

  const downloadPDF = async (e) => {
    e.preventDefault();
    setLoader(true);
    const chartContainers = [document.getElementById("growth_driver_chart")];
    if (chartContainers.every((container) => container !== null)) {
      try {
        // setLoader(true);
        const dataUrls = await Promise.all(
          chartContainers.map((container) => domtoimage.toPng(container))
        );
        // setLoader(true);
        const response = await axiosDownload.post("contribution/", {
          brand: Analyse.Brand,
          kpi: Analyse.KPI,
          timeframe: timeframe,
          chartdata: Chartdata,
          category: ChannelCategory,
          is_all_enabled: IsAllEnabledDownload,
          reset: Reset,
          level: -1,
          theme: localStorage.getItem("CurrentTheme"),
          download_type: "PDF",
        });
        // setLoader(false);
        // console.log("excel: ", response.data);
        const pdfResponse = await axiosChartDownload.post(
          "download_pdf/create/",
          {
            images: dataUrls.map((dataUrl) => dataUrl.split(",")[1]),
            document: {
              table1_data: response.data.table1_data,
              titlename: "Growth Drivers",
              // textcontent:
              // "We take digital marketing analytics one step further with unparalleled prescriptive deep-dive. Our innovative end-to-end analytics solution gives you relevant data & helps in strategy formulation & planning at the lowest cuts.",
              // header_content: "Loreal Haircolor Garnier- Sales",
              footer_content: "Private & Confidential",
              table_heading1: "Channel Contribution",
              image_heading1: "Channel Contribution",
              username: userName,
              timeframe: timeframe,
              start_date: "",
              end_date: "",
              category: ChannelCategory,
              chartdata: Chartdata,
              brand: Analyse.Brand,
              kpi: Analyse.KPI,
              tabname: "contri",
            },
          }
        );
        if (pdfResponse.data.error === 1) {
          toast.error(pdfResponse.data.erroMsg);
          setLoader(false);
        } else {
          // console.log("response pdf: ", pdfResponse.data);
          setLoader(false);
          var link = document.createElement("a");
          link.target = "_blank";
          link.href = pdfResponse.data.pdf_url;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (error) {
        setLoader(false);
        console.error("Error while generating pdf:", error);
      }
    }
  };

  useEffect(() => {
    const url = window.location.href;
    const text = url.split("/");
    const tab = text[text.length - 1];
    for (var i = 0; i < Analyse.Menubar.length; i++) {
      if (Analyse.Menubar[i].key === tab) {
        setNotes(Analyse.Menubar[i].notes);
      }
    }
  }, [Analyse]);

  useEffect(() => {
    const localAnalyticFilter = JSON.parse(
      localStorage.getItem("StoreAnalyticFilter")
    );
    const localCampaignAnlayticFilter = JSON.parse(
      localStorage.getItem("StoreCampaignAnalyticFilter")
    );
    setAllFilters(localAnalyticFilter.AllFilters);
    setActiveFilters(localAnalyticFilter.ActiveFilters);
    setAnalyticFilterFlag(false);
    setCompActiveFilters(localCampaignAnlayticFilter.CompActiveFilters);
    setCompAllFilters(localCampaignAnlayticFilter.CompAllFilters);
  }, [Analyse.Brand, Analyse.KPI]);

  useEffect(() => {
    setLoader(true);
    axios
      .post("get_timeframes/", {
        page: "contrubution",
        brand: Analyse.Brand,
        // brand: "org",
        kpi: Analyse.KPI,
        // kpi: "totalsales"
      })
      .then((response) => {
        if (response.data.error === 1) {
          toast.error(response.data.erroMsg);
          setLoader(false);
        } else {
          setTimeFramesAll(response.data.data);
          setTimeframe(response.data.data[0].key);
          setDefaultStartDate(response.data.data[0].default_startdate);
          setDefaultEndDate(response.data.data[0].default_enddate);
          setSubmittedStartDate(response.data.data[0].default_startdate);
          setSubmittedEndDate(response.data.data[0].default_enddate);
          setSubmittedTimeFrame(response.data.data[0].key);
          setStartdate(response.data.data[0].start_date);
          setEnddate(response.data.data[0].end_date);
          setFetchCall(true);
          // setLoader(false);
        }
      })
      .catch((data) => {
        // setLoader(false);
      });
  }, [Analyse.Brand, Analyse.KPI]);

  useEffect(() => {
    if (timeframe && FetchCall) {
      setLoader(true);
      axios
        .post("get_category/", {
          brand: Analyse.Brand,
          kpi: Analyse.KPI,
          timeframe: timeframe,
          page: "contribution",
        })
        .then((response) => {
          if (response.data.error === 1) {
            toast.error(response.data.erroMsg);
            setLoader(false);
          } else {
            setAllChannelCategory(response.data.data);
            if (response.data.data.length > 0) {
              setChannelCategory(response.data.data[0].value);
            } else {
              setChannelCategory("");
            }
            setChannelCategoryFlag(true);
            setFetchCall(true);
            // setLoader(false);
          }
        })
        .catch((data) => {
          setLoader(false);
        });
    }
  }, [timeframe, Analyse.Brand, Analyse.KPI,FetchCall]);

  // Handle Initial Level

  useEffect(() => {

    const handleInitailLevel = (level) => {

      const keysArrayOfChartData = Object.keys(Chartdata);
      const ChartdataTemp = structuredClone(Chartdata);
      const result = keysArrayOfChartData.map(() => []);


      let SkipData = JSON.parse(
        localStorage.getItem("StoreAnalyticFilter")
      ).Fixed;

      console.log("SkipData.length initialLevel", SkipData.length);

      // const len=SkipData.length-InitialLevel-1;
      // Assign Selection to Skipdata
      // for(let i=0;i<len;i++){

      SkipData.pop(); // Removes the first element

      // }
      // Split each item in NotAllow by "-" and push the resulting arrays into result
      NotAllow.forEach((item) => {
        console.log("item ", item);
        const splitArray = item.split("-");
        result.forEach((res, index) => {
          // For each result array, push the corresponding slice of splitArray
          if (index <= splitArray.length - 1) {
            res.push(splitArray.slice(0, index + 1).join("-"));
          }
        });
      });

      // Create the payload array, filtering out duplicates
      let payloadArray = result.map((item) => {
        return [...new Set(item)]; // Remove duplicates
      });

      console.log(
        "Payload Array after nonclicklist:",
        payloadArray.slice(0, payloadArray.length - 1)
      );


      SkipData?.forEach((skipItem) => {
        const skipId = skipItem.id;

        // Check if ChartdataTemp includes the skipId as a key
        if (ChartdataTemp.hasOwnProperty(skipId)) {
          // Make the selection array same as the filter array
          ChartdataTemp[skipId].selection = ChartdataTemp[skipId].Filter;
        }
      });

      payloadArray = payloadArray.slice(0, payloadArray.length - 1);
      let SkipLength = SkipData?.length;

      // Step 2: Update `ChartdataTemp` with `payloadArray` after handling `SkipData`
      keysArrayOfChartData.slice(0, level).forEach((key, index) => {
        if (!SkipData?.some((skipItem) => skipItem.id === key)) {
          if (ChartdataTemp.hasOwnProperty(key)) {
            // Set the selection to the corresponding payloadArray values
            console.log("nonclicklist ", key, "   ", payloadArray[index]);
            ChartdataTemp[key].selection = payloadArray[index - SkipLength] || [];
          }
        }
      });




      console.log("ChartdataTemp nonclicklist initialLevel", ChartdataTemp, "    ", Chartdata);
      // setFetchCall(true);
      setChartdata(ChartdataTemp);
      // setShowChat(true);

    }
    // console.log("handleInitailLevel Calls ",NotAllow, " ",level);
    if (level !== -1 && NotAllow.length > 0) {
      // console.log("handleInitailLevel Calls");
      handleInitailLevel(level);
    }

  }, [NotAllow, Analyse.Brand, Analyse.KPI])

  useEffect(() => {
    const controller = new AbortController();
    if (
      ChannelCategoryFlag &&
      FetchCall &&
      timeframe &&
      JSON.stringify(Chartdata) !== "{}"
    ) {
      setLoader(true);
      setCategories([]);
      setWaterfallData([]);
      axios
        .post("get_contributions_new/", {
          timeframe: timeframe,
          brand: Analyse.Brand,
          starttime: DefaultStartDate,
          endtime: DefaultEndDate,
          kpi: Analyse.KPI,
          chartdata: Chartdata,
          category: ChannelCategory,
          is_all_enabled: IsAllEnabled,
          reset: Reset,
          level: level,
          theme: localStorage.getItem("CurrentTheme"),
          LightThemeColorCode: [
            localStorage.getItem("ColorCode"),
            LightThemeHexCode[localStorage.getItem("ColorCode")],
          ],
        },
         {
              signal: controller.signal,
            }
      )
        .then((response) => {
          
          if (response.data.error !== 1) {
            setTitle(response.data.chart.charttitle);
            setxtitle(response.data.chart.xtitle);
            setytitle(response.data.chart.ytitle);
            setCategories(response.data.chart.categories);

            if (response.data.chart.summary) {
              setSummaryFetched(response.data.chart.summary);
            }
            if (response.data.chart.series[0].data) {
              setWaterfallData(response.data.chart.series[0].data);
            } else {
              setWaterfallData([]);
            }
            if (response.data.chart.stack) {
              setStack(response.data.chart.stack);
            }
            setNotAllow(response.data.chart.nonclicklist);
            setShowSummary(response.data.chart.show_summary);
            setFooterNotes(response.data.chart.footernotes);
          setUnit(response.data.chart.units);
          } else {
            setWaterfallData([]);
            // toast.error(response.data.erroMsg);
            toast.error(
              "No data found for the following filter, please reset the filter"
            );
          }
          // setLoader(false);
          setFetchCall(false);
          console.log("No data found for the following filter, please reset the filter radio btn");
          setSelectedLabel("");
          setSummary({ self: {} });
          setChannel([]);
          // setLoader(false);
          // setFetchCall(false);
          setReset(0);
          setIsAllEnabled(0);
          // localStorage.setItem("DefaultLevel", -1);
        })
        .catch((data) => {
          // setLoader(false);
        });
    }
     return () => {
      controller.abort();
    };
  }, [
    ChannelCategoryFlag,
    FetchCall,
    timeframe,
    Analyse.Brand,
    Analyse.KPI,
    Chartdata,
    IsAllEnabled,
    DefaultStartDate,
    DefaultEndDate,
    Reset,
    ChannelCategory,
    isRightSidebarVisible,
  ]);

  const removeDash = useCallback((label) => {
    label = label.slice(0, -1);
    return label[label.length - 1] === "-" ? removeDash(label) : label;
  }, []);

  const dataplotRollOverHandler = (data) => {
    let my = data.substring(0, data.length);
    setSelectedLabel(my);
    // if (data.id) {
    //   let label = data.id;
    //   if (label[label.length - 1] === "-") {
    //     label = removeDash(label);
    //   }
    //   setSelectedLabel(label);
    // }
  };

  useEffect(() => {
    var flag = true;
    for (const [key, value] of Object.entries(Stack)) {
      if (SelectedLabel === key) {
        var temp = [];
        var otherPercentage = 0;
        var SummeryData = {};

        for (var i = 0; i < value.length; i++) {
          temp.push({
            name: value[i].name,
            y: value[i].value,
            color: value[i].color,
            dataLabels: {
              enabled: true,
            },
          });

          for (const [key, value] of Object.entries(WaterfallData)) {
            let label = value.id;
            if (label[label.length - 1] === "-") {
              label = removeDash(label);
            }

            if (SelectedLabel === label) {
              SummeryData = WaterfallData[key];
              break;
            }
          }
          otherPercentage += value[i].percentage;
        }
        otherPercentage = otherPercentage * 100;
        SummeryData.otherPercentage = otherPercentage.toFixed(2);

        flag = false;
        setChannel(temp);
        setSummary(SummeryData);
        break;
      }
    }

    if (flag === true) {
      setChannel([]);
      setSummary({});
      for (const [key, value] of Object.entries(WaterfallData)) {
        if (SelectedLabel === value.id) {
          setAlternateNotes(WaterfallData[key].notes);
          break;
        }
      }
    }
  }, [SelectedLabel, Stack, WaterfallData, removeDash]);

  const DownloadData = (e) => {
    e.preventDefault();
    setLoader(true);
    axiosDownload
      .post("contribution/", {
        brand: Analyse.Brand,
        kpi: Analyse.KPI,
        timeframe: timeframe,
        chartdata: Chartdata,
        category: ChannelCategory,
        is_all_enabled: IsAllEnabledDownload,
        reset: Reset,
        level: -1,
        theme: localStorage.getItem("CurrentTheme"),
      })
      .then((response) => {
        var link = document.createElement("a");
        link.target = "_blank";
        link.href = response.data.data;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoader(false);
      })
      .catch((data) => {
        setLoader(false);
      });
  };

  const [ChartHeight, setChartHeight] = useState("");

  useEffect(() => {
    if (refGraphDiv.current !== null) {
      setChartHeight(refGraphDiv.current.clientHeight);
    }
  }, [WaterfallData]);
  const [ShowPopup, setShowPopup] = useState(false);

  useEffect(() => {
    if (divHeight.current !== null) {
      setDivHeight(divHeight.current.clientHeight);
    }
  }, [WaterfallData]);

  //   useEffect(() => {
  //     const chartdata = {};
  //     const ChartdataTemp = structuredClone(Chartdata);
  //     // console.log("ChartdataTemp: ", ChartdataTemp);

  //     if (Fixed.length !== 0) {
  //       for (var r = 0; r < Fixed.length; r++) {
  //         const Filter = [];
  //         Filter.push(Fixed[r].value);
  //         chartdata[Fixed[r].id] = {
  //           selection: [],
  //           Filter: Filter,
  //         };
  //       }
  //     }

  //     if (ActiveFilters.length !== 0) {
  //       for (var i = 0; i < ActiveFilters.length; i++) {
  //         const selection = [];
  //         if ("Selected" in ActiveFilters[i]) {
  //           if (ActiveFilters[i]["Selected"].length > 0) {
  //             setResetFilterFlag(true);
  //           }

  //           for (var j = 0; j < ActiveFilters[i].Selected.length; j++) {
  //             var temp_arr = ActiveFilters[i].Selected[j].value.split("-");
  //             selection.push(temp_arr[temp_arr.length - 1]);
  //           }
  //         }

  //         // chartdata[ActiveFilters[i].name] = {
  //         //   selection:
  //         //     typeof Chartdata[ActiveFilters[i].name] !== "undefined"
  //         //       ? Chartdata[ActiveFilters[i].name].selection
  //         //       : [],
  //         //   // Filter: selection,
  //         //   Filter:
  //         //     typeof Chartdata[ActiveFilters[i].name] !== "undefined"
  //         //       ? Chartdata[ActiveFilters[i].name].Filter
  //         //       : [],
  //         // };

  //         chartdata[ActiveFilters[i].name] = {
  //           selection:

  //                [],
  //           // Filter: selection,
  //           Filter:

  //                [],
  //         };
  //       }
  //     }

  //     for (var k = 0; k < CompActiveFilters.length; k++) {
  //       const selection = [];
  //       if ("Selected" in CompActiveFilters[k]) {
  //         if (CompActiveFilters[k]["Selected"].length > 0) {
  //           setResetFilterFlag(true);
  //         }

  //         for (var l = 0; l < CompActiveFilters[k].Selected.length; l++) {
  //           selection.push(CompActiveFilters[k].Selected[l].value);
  //         }
  //       }
  //       chartdata[CompActiveFilters[k].name] = {
  //         selection: [],
  //         Filter: selection,
  //       };
  //     }

  //     const keys = Object.keys(chartdata);
  //     const lastKeyIndex = keys.length - 1;

  //     for (let i = 0; i < lastKeyIndex; i++) {
  //       const key = keys[i];
  //       if (ChartdataTemp.hasOwnProperty(key)) {
  //         chartdata[key].selection = ChartdataTemp[key].selection;
  //       }
  //     }

  //     // for (const key in ChartdataTemp) {
  //     //   if(chartdata.hasOwnProperty(key)) {
  //     //     chartdata[key].selection = ChartdataTemp[key].selection;
  //     //   }
  //     // }

  //     setChartdata(chartdata);
  //     setFetchCall(true);
  //     setSelectedCheckboxes([]);
  //     setSelectedCheckboxesC([]);
  //   }, [ActiveFilters, CompActiveFilters]);

  useEffect(() => {
    console.log("resultt first time before ");
    if (changeFilter === false) {
      const chartdata = JSON.parse(localStorage.getItem("Chartdata"));
      console.log("resultt first time ", chartdata);
      if (JSON.stringify(chartdata) !== JSON.stringify(Chartdata)) {
        setChartdata(chartdata);
      }
      // setIsChartdataReady(true);
      setFetchCall(true);
      setSelectedCheckboxes([]);
      setSelectedCheckboxesC([]);
    }
  }, [Analyse.Brand, Analyse.KPI]);

  const AddHeirarchyFilter = (index) => {
    const ActiveFiltersTemp = JSON.parse(JSON.stringify(ActiveFilters));
    let filter = AllFilters[index];

    console.log("Add H");
    // Assuming you want to select the first filter by default as in AddCompFilter
    // filter.Selected = [filter?.filters[0]];

    ActiveFiltersTemp.push(filter);
    setActiveFilters(ActiveFiltersTemp);
    setFilterTabShow(true);

    // Update the localStorage with the new filter data
    const AnalyticFilterTemp = {
      AllFilters: AllFilters,
      Fixed: Fixed,
      ActiveFilters: ActiveFiltersTemp,
    };

    localStorage.setItem("AnalyticFilter", JSON.stringify(AnalyticFilterTemp));
    updateChartData();
  };

  const RemoveHeirarchyFilter = (item) => {
    const ActiveFiltersCompTemp = JSON.parse(JSON.stringify(ActiveFilters));
    for (let i = 0; i < ActiveFiltersCompTemp.length; i++) {
      if (ActiveFiltersCompTemp[i].name === item.name) {
        ActiveFiltersCompTemp.splice(i, 1);
        setActiveFilters(ActiveFiltersCompTemp);
      }
    }
    setFilterTabShow(true);

    //Set analytical filters data in localstorage
    const AnalyticFilterTemp = {
      AllFilters: AllFilters,
      Fixed: Fixed,
      ActiveFilters: ActiveFiltersCompTemp,
    };

    localStorage.setItem("AnalyticFilter", JSON.stringify(AnalyticFilterTemp));
    updateChartData();
  };

  const IsInAllHeirarchyFilters = (item) => {
    for (let i = 0; i < ActiveFilters.length; i++) {
      if (ActiveFilters[i].name === item.name) {
        return true;
      }
    }
    return false;
  };

  //CHANNEL FILTERS
  const AddCompFilter = (index) => {
    const ActiveFiltersCompTemp = JSON.parse(JSON.stringify(CompActiveFilters));
    let filter = CompAllFilters[index];
    filter.Selected = [filter.filters[0]];

    ActiveFiltersCompTemp.push(filter);
    setCompActiveFilters(ActiveFiltersCompTemp);
    setFilterTabShow(true);

    //Set campaign filter data in localstorage
    const CampaignAnalyticFilter = {
      CompAllFilters: CompAllFilters,
      CompActiveFilters: ActiveFiltersCompTemp,
    };

    localStorage.setItem(
      "CampaignAnalyticFilter",
      JSON.stringify(CampaignAnalyticFilter)
    );
    updateChartData();
  };

  const RemoveCompFilter = (item) => {
    const ActiveFiltersCompTemp = JSON.parse(JSON.stringify(CompActiveFilters));
    for (let i = 0; i < ActiveFiltersCompTemp.length; i++) {
      if (ActiveFiltersCompTemp[i].name === item.name) {
        ActiveFiltersCompTemp.splice(i, 1);
        setCompActiveFilters(ActiveFiltersCompTemp);
      }
    }
    setFilterTabShow(true);

    //Set campaign filter data in localstorage
    const CampaignAnalyticFilter = {
      CompAllFilters: CompAllFilters,
      CompActiveFilters: ActiveFiltersCompTemp,
    };

    localStorage.setItem(
      "CampaignAnalyticFilter",
      JSON.stringify(CampaignAnalyticFilter)
    );
    updateChartData();
  };

  const IsInAllCompFilters = (item) => {
    for (let i = 0; i < CompActiveFilters.length; i++) {
      if (CompActiveFilters[i].name === item.name) {
        return true;
      }
    }
    return false;
  };

  //  prepare BreadCrumbs......................................
  useEffect(() => {
    if (JSON.stringify(Chartdata) !== "{}") {
      const cloneOfChartdata = structuredClone(Chartdata);
      const allKeysAlreadyInChartdata = Object.keys(cloneOfChartdata);

      // Retrieve SkipData from local storage
      let SkipData = JSON.parse(
        localStorage.getItem("StoreAnalyticFilter")
      ).Fixed;
      SkipData.pop();

      const skipIds = SkipData.map((item) => item.id);

      // Prepare a list to store lengths of valid breadcrumb selections
      const listToStoreLength = [];

      for (const key in cloneOfChartdata) {
        const list = cloneOfChartdata[key]["selection"];
        console.log(
          "allKeysAlreadyInChartdata list ",
          list,
          "    ",
          !skipIds.includes(key),
          "  ",
          cloneOfChartdata[key]
        );
        // Only process if the list length is greater than 0 and the key is not in skipIds
        if (list.length > 0 && !skipIds.includes(key)) {
          for (const name of list) {
            const arr = name.split("-");
            listToStoreLength.push(arr.length);
            console.log("allKeysAlreadyInChartdata arr", arr);
          }
        }
      }

      let maxNumber = Math.max(...listToStoreLength);

      // If listToStoreLength is empty, set maxNumber to 0
      if (listToStoreLength.length === 0) {
        maxNumber = 0;
      }

      // Set the breadcrumbs based on the maximum length
      console.log(
        "allKeysAlreadyInChartdata ",
        allKeysAlreadyInChartdata,
        "  ",
        maxNumber,
        " "
      );
      setBreadCrumbs(
        allKeysAlreadyInChartdata.slice(
          SkipData?.length,
          maxNumber + 1 + SkipData?.length
        )
      );
    }
  }, [Chartdata]);

  // for point click
  const DrillDown = (category) => {
    console.log("clicked category point click: ", category);

    const splitNameList = category.split("-");
    const Length = splitNameList.length;

    console.log("Length point click: ", Length);

    let SkipData = JSON.parse(
      localStorage.getItem("StoreAnalyticFilter")
    ).Fixed;

    SkipData.pop();

    let SkipLength = SkipData?.length;
    console.log("SKIPDATA ", SkipData, SkipLength);

    const ChartdataTemp = structuredClone(Chartdata);
    const allKeysAlreadyInChartdata = Object.keys(Chartdata);
    // console.log("allKeysAlreadyInChartdatra: ", allKeysAlreadyInChartdata);

    const keyToAddSelectionFilterInChartdata =
      allKeysAlreadyInChartdata[Length - 1 + SkipLength];

    console.log(
      "keyToAddSelectionFilterInChartdata: ",
      keyToAddSelectionFilterInChartdata
    );

    let cloneOfChartdata = structuredClone(Chartdata);

    SkipData?.forEach((skipItem) => {
      const skipId = skipItem.id;

      // Check if ChartdataTemp includes the skipId as a key
      if (cloneOfChartdata.hasOwnProperty(skipId)) {
        // Make the selection array same as the filter array
        cloneOfChartdata[skipId].selection = cloneOfChartdata[skipId].Filter;
      }
    });

    if (cloneOfChartdata.hasOwnProperty(keyToAddSelectionFilterInChartdata)) {
      cloneOfChartdata[keyToAddSelectionFilterInChartdata].selection.push(
        category
      );
    }

    setChartdata(cloneOfChartdata);
    setFetchCall(true);
    // setClickedCategory(Category);
    setIsAllEnabledDownload(0);
    setLevel(-1);
  };

  const convertLabelToId = (label, displayNames) => {
    // Split the label by hyphens
    const labelParts = label.split("-");

    // Convert each part into its corresponding id
    const idParts = labelParts.map((part) => {
      // Find the key where the value matches the part

      const foundId = Object.keys(displayNames).find(
        (id) => displayNames[id] === part
      );
      return foundId || part; // Use the part itself if no matching id is found
    });

    // Join the id parts with hyphens
    const resultId = idParts.join("-");

    return resultId;
  };

  // for label click
  const DrillUp = (category) => {
    // console.log("category: ", category.value);
    var span = document.createElement("span");
    span.innerHTML = category.value;
    const label = span.firstChild.getAttribute("id");
    // const labelIDdiv=category.value.match(/id="([^"]+)"/)[1]

    let label_id = label;
    console.log("label: before", label_id);

    const convertedId = label;

    console.log("label: ", convertedId);
    const ChartdataTemp = structuredClone(Chartdata);
    console.log("label: chartDatatemp before", ChartdataTemp);
    let parentClear = false;
    for (const key in ChartdataTemp) {
      // Remove the label from the selection array if it is present
      ChartdataTemp[key].selection = ChartdataTemp[key].selection.filter(
        (value) => !value.startsWith(convertedId)
      );
    }

    console.log("label: chartDatatemp ", ChartdataTemp);

    setChartdata(ChartdataTemp);
    setFetchCall(true);
    setIsAllEnabledDownload(0);
    setLevel(-1);
  };

  // for down arrow
  const DrillDownAll = () => {
    // Get the keys in Chartdata
    const keysAlreadyInChartData = Object.keys(Chartdata);

    // Clone Chartdata to avoid mutating the original object
    const ChartdataTemp = structuredClone(Chartdata);

    // Retrieve SkipData from local storage
    let SkipData = JSON.parse(
      localStorage.getItem("StoreAnalyticFilter")
    ).Fixed;

    SkipData.pop();

    let SkipLength = SkipData?.length;
    // Extract the IDs from SkipData
    const skipIds = SkipData?.map((item) => item.id);

    // Create empty arrays based on the number of keys in Chartdata
    const result = keysAlreadyInChartData.map(() => []);

    // Process the NotAllow array
    NotAllow.forEach((item) => {
      const splitArray = item.split("-");
      console.log("item PayloadArray DrillDown", item, " ", splitArray);
      const idx = splitArray.length;

      result.forEach((res, index) => {
        // For each result array, push the corresponding slice of splitArray
        if (index <= splitArray.length - 1) {
          res.push(splitArray.slice(0, index + 1).join("-"));
        }
      });
    });

    // Create the payload array, filtering out duplicates
    let payloadArray = result.map((item) => {
      return [...new Set(item)]; // Remove duplicates
    });
    console.log("PayloadArray DrillDown", payloadArray);
    payloadArray = payloadArray.slice(0, payloadArray.length - 1);

    // Find the next element that is not in SkipData and has no selection or shorter selection
    let nextKey = null;
    let nextIndex = -1;
    SkipData?.forEach((skipItem) => {
      const skipId = skipItem?.id;

      // Check if ChartdataTemp includes the skipId as a key
      if (ChartdataTemp.hasOwnProperty(skipId)) {
        // Make the selection array same as the filter array
        ChartdataTemp[skipId].selection = ChartdataTemp[skipId].Filter;
      }
    });

    for (let i = 0; i < keysAlreadyInChartData.length - 1; i++) {
      const key = keysAlreadyInChartData[i];
      if (
        ChartdataTemp.hasOwnProperty(key) &&
        !skipIds?.includes(key) &&
        ChartdataTemp[key].selection.length === 0
      ) {
        nextKey = key;
        nextIndex = i;
        break;
      }
    }

    // If a nextKey is found and it's not in SkipData, set its selection with processedNotAllow data
    if (nextKey && ChartdataTemp.hasOwnProperty(nextKey)) {
      ChartdataTemp[nextKey].selection = payloadArray[nextIndex - SkipLength];
    }

    console.log("DrillDownAll - Updated ChartdataTemp:", ChartdataTemp);

    // Update the state with the modified ChartdataTemp
    setChartdata(ChartdataTemp);
    setFetchCall(true);
    setIsAllEnabledDownload(0);
    setLevel(-1);
  };

  // for up arrow
  const DrillUpAll = () => {
    // Get the keys in Chartdata
    const keysAlreadyInChartData = Object.keys(Chartdata);

    // Clone Chartdata to avoid mutating the original object
    const ChartdataTemp = structuredClone(Chartdata);

    // Retrieve SkipData from local storage
    let SkipData = JSON.parse(
      localStorage.getItem("StoreAnalyticFilter")
    ).Fixed;
    SkipData.pop();
    // Extract the IDs from SkipData
    const skipIds = SkipData?.map((item) => item.id);

    // Identify the last key in ChartdataTemp that has a non-empty selection and is not in SkipData
    const lastKey = keysAlreadyInChartData.reverse().find((key) => {
      return (
        ChartdataTemp[key].selection &&
        ChartdataTemp[key].selection.length > 0 &&
        !skipIds?.includes(key)
      );
    });

    // If a lastKey is found and it's not in SkipData, clear its selection
    if (lastKey && ChartdataTemp.hasOwnProperty(lastKey)) {
      ChartdataTemp[lastKey].selection = [];
    }

    console.log("DrillUpAll - Updated ChartdataTemp:", ChartdataTemp);

    // Update the state with the modified ChartdataTemp
    setChartdata(ChartdataTemp);
    setFetchCall(true);
    setIsAllEnabledDownload(0);
    setLevel(-1);
  };

  // tree button
  const DrillAll = () => {
    console.log("nonclicklist ", NotAllow);
    const keysArrayOfChartData = Object.keys(Chartdata);
    const ChartdataTemp = structuredClone(Chartdata);

    // Create an array of empty arrays for each key in Chartdata
    const result = keysArrayOfChartData.map(() => []);

    // Split each item in NotAllow by "-" and push the resulting arrays into result
    NotAllow.forEach((item) => {
      console.log("item ", item);
      const splitArray = item.split("-");
      result.forEach((res, index) => {
        // For each result array, push the corresponding slice of splitArray
        if (index <= splitArray.length - 1) {
          res.push(splitArray.slice(0, index + 1).join("-"));
        }
      });
    });

    // Create the payload array, filtering out duplicates
    let payloadArray = result.map((item) => {
      return [...new Set(item)]; // Remove duplicates
    });

    console.log(
      "Payload Array after nonclicklist:",
      payloadArray.slice(0, payloadArray.length - 1)
    );

    let SkipData = JSON.parse(
      localStorage.getItem("StoreAnalyticFilter")
    ).Fixed;

    // Assign Selection to Skipdata
    SkipData.pop();
    // Step 1: Update `ChartdataTemp` for ids in `SkipData`
    // Loop through the sliced SkipData
    SkipData?.forEach((skipItem) => {
      const skipId = skipItem.id;

      // Check if ChartdataTemp includes the skipId as a key
      if (ChartdataTemp.hasOwnProperty(skipId)) {
        // Make the selection array same as the filter array
        ChartdataTemp[skipId].selection = ChartdataTemp[skipId].Filter;
      }
    });

    payloadArray = payloadArray.slice(0, payloadArray.length - 1);
    let SkipLength = SkipData?.length;

    // Step 2: Update `ChartdataTemp` with `payloadArray` after handling `SkipData`
    keysArrayOfChartData.slice(0, -1).forEach((key, index) => {
      if (!SkipData?.some((skipItem) => skipItem.id === key)) {
        if (ChartdataTemp.hasOwnProperty(key)) {
          // Set the selection to the corresponding payloadArray values
          console.log("nonclicklist ", key, "   ", payloadArray[index]);
          ChartdataTemp[key].selection = payloadArray[index - SkipLength] || [];
        }
      }
    });

    console.log("ChartdataTemp nonclicklist", ChartdataTemp);

    setIsAllEnabled(1);
    setFetchCall(true);
    setChartdata(ChartdataTemp);
    setLevel(-1);
  };

  // for reset button
  const ResetAll = () => {
    let ChartdataTemp = {};
    for (const filter in Chartdata) {
      ChartdataTemp[filter] = Chartdata[filter];
      ChartdataTemp[filter].selection = [];
    }

    setLevel(DefaultLevel);
    setReset(1);
    setChartdata(ChartdataTemp);
    setFetchCall(true);
    setIsAllEnabledDownload(0);
  };

  const ResetFilters = () => {
    var active_filter = [];
    for (let i = 0; i < ActiveFilters.length; i++) {
      var obj = ActiveFilters[i];
      obj["Selected"] = [];
      active_filter.push(obj);
    }

    var comp_active_filter = [];
    for (let k = 0; k < CompActiveFilters.length; k++) {
      var obj1 = CompActiveFilters[k];
      obj1["Selected"] = [];
      comp_active_filter.push(obj1);
    }

    setResetFilterFlag(false);
    //Set analytical filters data in localstorage
    const AnalyticFilterTemp = {
      AllFilters: AllFilters,
      Fixed: Fixed,
      ActiveFilters: active_filter,
    };

    localStorage.setItem("AnalyticFilter", JSON.stringify(AnalyticFilterTemp));

    const CampaignAnalyticFilter = {
      CompAllFilters: CompAllFilters,
      CompActiveFilters: comp_active_filter,
    };

    localStorage.setItem(
      "CampaignAnalyticFilter",
      JSON.stringify(CampaignAnalyticFilter)
    );

    setActiveFilters(active_filter);
    setCompActiveFilters(comp_active_filter);
  };

  const [full, setFull] = useState(true);
  const [divWidth, setDivWidth] = useState("");

  const handler = () => {
    setFull(!full);
  };

  // console.log("divWidth: ", divWidth);

  useLayoutEffect(() => {
    const updateDivHeight = () => {
      if (divHeight.current !== null) {
        const newWidth = divHeight.current.clientWidth;
        setDivWidth(newWidth);
      }
    };

    updateDivHeight(); // Initial update
    window.addEventListener("resize", updateDivHeight);

    return () => {
      window.removeEventListener("resize", updateDivHeight);
    };
  }, []);

  const [HFilters, setHFilters] = useState([]);
  // console.log("HFilters: ", HFilters);
  // console.log("Chartdata: ", Object.keys(Chartdata));

  const [selectedCheckboxes, setSelectedCheckboxes] = useState({});
  // console.log("selectedCheckboxes: ", selectedCheckboxes);

  const filteredKeys = Object.keys(Chartdata).filter(
    (key) => !key.startsWith("C")
  );

  useEffect(() => {
    const fetchHierarchyFilters = async () => {
      if (JSON.stringify(Chartdata) !== "{}") {
        try {
          const response = await axios.post("hieracrhy_data/", {
            hierarchy_list: filteredKeys,
            brand: Analyse.Brand,
          });

          const data = response.data;
          // console.log(data);
          setHFilters(data.data);
          // localStorage.setItem("hierarchyFilters", JSON.stringify(data.data));
        } catch (error) {
          console.error("Failed to fetch:", error);
        }
      }
    };
    fetchHierarchyFilters();
  }, [Chartdata]);

  const [CFilters, setCFilters] = useState([]);
  // console.log("HFilters: ", HFilters);
  // console.log("Chartdata: ", Object.keys(Chartdata));

  const [selectedCheckboxesC, setSelectedCheckboxesC] = useState({});
  // console.log("selectedCheckboxes: ", selectedCheckboxes);

  const filteredKeysC = Object.keys(Chartdata).filter(
    (key) => !key.startsWith("H")
  );

  useEffect(() => {
    const fetchChannelFilters = async () => {
      if (JSON.stringify(Chartdata) !== "{}") {
        try {
          const response = await axios.post("channel_hierarcy/", {
            channel_list: filteredKeysC,
            brand: Analyse.Brand,
          });

          const data = response.data;
          // console.log(data);
          setCFilters(data.data);
          // localStorage.setItem("channelFilters", JSON.stringify(data.data));
        } catch (error) {
          console.error("Failed to fetch:", error);
        }
      }
    };

    fetchChannelFilters();
  }, [Chartdata]);

  const updateChartData = (changeFilter = [], channelFilter = []) => {
    // Initialize the result object
    const result = {};
    console.log("resultt updatedChartData");
    const filterdata = JSON.parse(localStorage.getItem("AnalyticFilter"));
    const ChannelFilterData = JSON.parse(
      localStorage.getItem("CampaignAnalyticFilter")
    );

    // Populate the result object with Fixed filters
    filterdata.Fixed.forEach((item) => {
      result[item.id] = {
        selection: [],
        Filter: [item.value],
      };
    });

    if (changeFilter.length === 0) {
      // Populate the result object with AllFilters that are not in Fixed
      filterdata.ActiveFilters.forEach((item) => {
        if (!result[item.name]) {
          result[item.name] = {
            selection: [],
            Filter: [],
          };
        }
      });
    } else {
      changeFilter.forEach((item) => {
        if (!result[item.name]) {
          result[item.name] = {
            selection: [],
            Filter: [],
          };
        }
      });
    }

    if (channelFilter.length === 0) {
      // Populate the result object with AllFilters that are not in Fixed
      ChannelFilterData.CompActiveFilters.forEach((item) => {
        if (!result[item.name]) {
          result[item.name] = {
            selection: [],
            Filter: [],
          };
        }
      });
    } else {
      channelFilter.forEach((item) => {
        if (!result[item.name]) {
          result[item.name] = {
            selection: [],
            Filter: [],
          };
        }
      });
    }
    console.log("resultt ", result, " ", Chartdata);
    setFetchCall(true);
    setChartdata(result);
    setChangeFilter(true);
    setSelectedCheckboxes([]);
    setSelectedCheckboxesC([]);
    setLevel(DefaultLevel);

  };

  console.log("TimeFramesAll in growth", TimeFramesAll);

  useEffect(() => {
    console.log("FetchCall in driver", FetchCall);

  }, [FetchCall])


  return (
    <>
      {loader ? <Loader /> : null}
      {ShowPopup ? (
        <PopUp
          setShowPopup={setShowPopup}
          SelectedLabel={getFormattedString(SelectedLabel, displayNamesChart)}
          width={"25%"}
          height={"70%"}
        >
          {Channel.length ? <Piechart Channel={Channel} /> : null}
        </PopUp>
      ) : null}
      <ToastContainer theme="colored" />
      <Topbar />
      <Menubar />
      <div className="main-content">
        <div className="page-content">
          <div className="filter-class">
            <AllHeirarchyFilter
              setHierarchySelectionBreadCrumb={setHierarchySelectionBreadCrumb}
              key={Chartdata}
              updateChartData={updateChartData}
              OnlyHeirarchyFilter={false}
              Fixed={Fixed}
              ActiveFilters={ActiveFilters}
              AllFilters={AllFilters}
              AddHeirarchyFilter={AddHeirarchyFilter}
              RemoveHeirarchyFilter={RemoveHeirarchyFilter}
              IsInAllHeirarchyFilters={IsInAllHeirarchyFilters}
              Chartdata={Chartdata}
              setChartdata={setChartdata}
              setFetchCall={setFetchCall}
              HFilters={HFilters}
              setActiveFilters={setActiveFilters}
              selectedCheckboxes={selectedCheckboxes}
              setSelectedCheckboxes={setSelectedCheckboxes}
            />
            <ChannelFilter
              setChannelSelectionBreadCrumb={setChannelSelectionBreadCrumb}
              key={filteredKeysC}
              updateChartData={updateChartData}
              CompActiveFilters={CompActiveFilters}
              CompAllFilters={CompAllFilters}
              AddCompFilter={AddCompFilter}
              RemoveCompFilter={RemoveCompFilter}
              IsInAllCompFilters={IsInAllCompFilters}
              Chartdata={Chartdata}
              setChartdata={setChartdata}
              setFetchCall={setFetchCall}
              setCompActiveFilters={setCompActiveFilters}
              CFilters={CFilters}
              selectedCheckboxesC={selectedCheckboxesC}
              setSelectedCheckboxesC={setSelectedCheckboxesC}
            />
          </div>

          <div
            className="container-fluid main-content-w pt-1"
            style={{ paddingTop: "10px" }}
          >
            <div className="row">
              <div className={isRightSidebarVisible ? "col-xl-9" : "col-xl-12"}>
                <div
                  className="card  card-min-height"
                  ref={divHeight}
                  style={{
                    height: `${window.innerHeight < 850
                        ? window.innerHeight * 0.82
                        : window.innerHeight * 0.87
                      }px`,
                  }}
                >
                  <div className="ef-drivers " style={{ padding: "10px" }}>
                    <div className="row align-items-center justify-content-sm-between">
                      <div
                        className="col-sm-4 d-flex"
                      // style={{ border: "1px solid red" }}
                      >
                        {/* <CustomSelect
                          value={timeframe}
                          onChange={(e) => {
                            setTimeframe(e.target.value);
                            setChannelCategory("");
                            // setCategoryFlag(true);
                          }}
                          options={TimeFramesAll.map((item) => ({
                            key: item.key,
                            value: item.value,
                          }))}
                          isDetailPopUp={false}
                        /> */}

                        {
                          DefaultStartDate &&
                          DefaultEndDate &&
                          startdate &&
                          enddate &&

                          (
                            <MultiCal
                              // key={refreshKey}
                              setDefaultStartDate={setDefaultStartDate}
                              setDefaultEndDate={setDefaultEndDate}
                              DefaultStartDate={DefaultStartDate}
                              DefaultEndDate={DefaultEndDate}
                              rangeStartDate={startdate}
                              rangeEndDate={enddate}
                              setTimeframe={setTimeframe}
                              // onChangeHandler={onChangeHandler}
                              TimeFramesAll={TimeFramesAll}
                              timeframe={timeframe}
                              // SelectedChart={SelectedChart}
                              setIsSubmitEnabled={setIsSubmitEnabled}
                              // handleRefreshClick={handleRefreshClick}
                              submittedStartDate={submittedStartDate}
                              submittedEndDate={submittedEndDate}
                              submittedTimeFrame={submittedTimeFrame}
                              isRefreshEnabled={isRefreshEnabled}
                            />
                          )}
                        <span style={{ marginLeft: "5px" }}>
                          <button
                            className="submit-btn textSize"
                            disabled={isSubmitEnabled}
                            // className="btn-class"
                            type="button"
                            style={{
                              // marginTop:'-40px !important',

                              width: "80px",
                              height: "20px",
                              // fontSize: "10px"
                            }}
                            onClick={() => {
                              setSubmittedStartDate(DefaultStartDate);
                              setSubmittedEndDate(DefaultEndDate);
                              setSubmittedTimeFrame(timeframe);
                              setIsRefreshEnabled(false);
                              setFetchCall(true);
                              // setLevel(DefaultLevel);
                              setChannelCategory("");

                            }}
                          >
                            Submit
                          </button>
                        </span>

                        {AllChannelCategory.length !== 0 &&
                          AllChannelCategory.length > 1 ? (
                          // <div className="col-sm-2">
                          <select
                            className="form-select custom-input-box mx-4"
                            onChange={(e) => {
                              setChannelCategory(e.target.value);
                              setFetchCall(true);
                            }}
                            value={ChannelCategory}
                          >
                            {AllChannelCategory.map((item, i) => {
                              return (
                                <option key={i} value={item.value}>
                                  {item.label}
                                </option>
                              );
                            })}
                          </select>
                        ) : // </div>
                          null}
                      </div>

                      <div className="col-sm-5">
                        <p className="card-title-lable">{Title}</p>
                      </div>
                      <div className="col-sm-3 d-flex align-items-center justify-content-sm-end">
                        {Notes !== "" ? (
                          <>
                            <p
                              className="m-0 icon-class textSize"
                              id="notesdiv"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                // fontSize: "10px",
                                height: "max-content",
                              }}
                            >
                              <svg
                                src={InfoIcon}
                                id="notes"
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="25"
                                class="bi bi-info-circle "
                                viewBox="0 0 16 16"
                              >
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                              </svg>
                            </p>

                            <Tooltip anchorSelect="#notesdiv" clickable>
                              {/* <div className="api-notes">
                                  {Notes}
                                </div> */}
                              <div
                                className="api-notes"
                                style={{
                                  height: "max-content",
                                  color: "white",
                                }}
                                dangerouslySetInnerHTML={{ __html: Notes }}
                              ></div>
                            </Tooltip>
                          </>
                        ) : null}
                        {(downloadFlag.chart ||
                          downloadFlag.data ||
                          downloadFlag.pdf) && (
                            <span className="flex-shrink-0 dropdown">
                              <Link
                                to="#"
                                className="d-flex align-items-center text-decoration-none dropdown-toggle text-white"
                                id="dropdownUser2"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <p
                                  className="m-0 icon-class"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    fontSize: "20px",
                                  }}
                                >
                                  <svg
                                    src={Download}
                                    title="Download"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="25"
                                    height="25"
                                    className="bi bi-download"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                                  </svg>
                                </p>
                              </Link>
                              <ul
                                className="dropdown-menu text-small shadow"
                                aria-labelledby="dropdownUser2"
                              >
                                {downloadFlag.chart && (
                                  <li onClick={(e) => { }}>
                                    <Link
                                      className="dropdown-item"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        if (WaterfallData.length > 0) {
                                          // setLoader(true)
                                          // setDownloadchart(true)
                                          setIsDownloadingChart(true);
                                          setChartContainerID(
                                            "growth_driver_chart"
                                          );
                                          setDownloadedImageName(
                                            "Growth Drivers"
                                          );
                                          setDownloadingChartTitle(
                                            "Growth Drivers"
                                          );
                                        } else {
                                          toast.error("Chartdata not available.");
                                        }
                                      }}
                                    >
                                      Download Chart
                                    </Link>
                                  </li>
                                )}
                                {downloadFlag.data && (
                                  <li onClick={(e) => { }}>
                                    <Link
                                      className="dropdown-item"
                                      onClick={(e) => DownloadData(e)}
                                    >
                                      Download Data
                                    </Link>
                                  </li>
                                )}
                                {downloadFlag.pdf && (
                                  <li onClick={(e) => { }}>
                                    <Link
                                      className="dropdown-item"
                                      onClick={(e) => downloadPDF(e)}
                                    >
                                      Download PDF
                                    </Link>
                                  </li>
                                )}
                              </ul>
                            </span>
                          )}
                        {/* full screen icon */}
                        <span
                          className="icon-class"
                          // className="cursor-pointer text-white chart-zoom-icon"
                          style={{
                            cursor: "pointer",
                            color: "white",
                            display: "flex",
                            justifyContent: "center",
                            alignContent: "center",
                            display: "none" // hardcoded for angelone

                          }}
                          title={
                            isRightSidebarVisible
                              ? "Full Screen"
                              : "Exit Full Screen"
                          }
                          onClick={() => {
                            setIsRightSidebarVisible(!isRightSidebarVisible);
                            setFetchCall(true);
                          }}
                        >
                          {isRightSidebarVisible ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="currentColor"
                              className="bi-fullscreen"
                              viewBox="0 0 16 16"
                            >
                              <path d="M1.5 1a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4A1.5 1.5 0 0 1 1.5 0h4a.5.5 0 0 1 0 1h-4zM10 .5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 16 1.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zM.5 10a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 0 14.5v-4a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v4a1.5 1.5 0 0 1-1.5 1.5h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5z" />
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="currentColor"
                              class="bi bi-fullscreen-exit"
                              viewBox="0 0 16 16"
                            >
                              <path d="M5.5 0a.5.5 0 0 1 .5.5v4A1.5 1.5 0 0 1 4.5 6h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5m5 0a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 10 4.5v-4a.5.5 0 0 1 .5-.5M0 10.5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 6 11.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5m10 1a1.5 1.5 0 0 1 1.5-1.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0z" />
                            </svg>
                          )}
                        </span>

                        {/* full screen icon */}
                      </div>
                    </div>

                    <div className="row my-1">
                      <div className="col-sm-12">
                        <div className="row align-items-center sorting-btn-ui">
                          <div className="col-sm-3">
                            <ul>
                              <li onClick={() => DrillUpAll()} title="Drill Up">
                                <i
                                  className="fa fa-arrow-up icon-class"
                                  aria-hidden="true"
                                ></i>
                              </li>
                              <li
                                onClick={() => DrillDownAll()}
                                title="Drill Down"
                              >
                                <i
                                  className="fa fa-arrow-down icon-class"
                                  aria-hidden="true"
                                ></i>
                              </li>
                              <li onClick={() => DrillAll()} title="Expand All">
                                <i
                                  className="fa fa-sitemap icon-class"
                                  aria-hidden="true"
                                ></i>
                              </li>
                              <li
                                onClick={() => {
                                  ResetAll();
                                  setFull(false);
                                }}
                                title="Reset"
                              >
                                <i className="fa icon-class" aria-hidden="true">
                                  &#8635;
                                </i>
                              </li>
                              <li>
                                {full ? (
                                  // <Tooltip2 title="Zoom Out">

                                  <li
                                    onClick={() => {
                                      handler();
                                    }}
                                    title="Zoom Out"
                                  >
                                    <i
                                      className="fas fa-magnifying-glass-minus icon-class"
                                      aria-hidden="true"
                                    ></i>
                                  </li>
                                ) : (
                                  // </Tooltip2>
                                  // <Tooltip2 title="Zoom In">
                                  <li
                                    onClick={() => {
                                      handler();
                                    }}
                                    title="Zoom Out"
                                  >
                                    <i
                                      className="fas fa-magnifying-glass-plus icon-class"
                                      aria-hidden="true"
                                    ></i>
                                  </li>
                                  // </Tooltip2>
                                )}
                              </li>
                            </ul>
                          </div>
                          {/* <div className="col-sm-9 custom-brdcrum">
                            {BreadCrumbs.map((item) => {
                              return returnDisplayName(item);
                            }).join(" / ")}
                          </div> */}
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-sm-12"
                      style={{ display: "flex", alignContent: "center" }}
                    >
                      <div
                        className="col-sm-6 custom-brdcrum2"
                        style={{
                          display: "flex",
                          marginLeft: "2px",
                          alignContent: "center",
                          justifyContent: "start",
                          wordBreak: "break-word",
                          flexWrap: "wrap",
                          minHeight: "min-content",
                        }}
                      >
                        {hierarchySelectionBreadCrumb ||
                          channelSelectionBreadCrumb ? (
                          <>
                            <i
                              className="fas fa-filter"
                              style={{ marginRight: "5px", fontSize: "12px" }} // Adds spacing and adjusts icon size
                            ></i>
                            {hierarchySelectionBreadCrumb &&
                              channelSelectionBreadCrumb
                              ? `${hierarchySelectionBreadCrumb} / ${channelSelectionBreadCrumb}`
                              : hierarchySelectionBreadCrumb ||
                              channelSelectionBreadCrumb}
                          </>
                        ) : null}
                      </div>

                      <div className="col-sm-6 custom-brdcrum">
                        {BreadCrumbs.map((item) => {
                          return returnDisplayName(item);
                        }).join(" / ")}
                      </div>
                    </div>

                    <div className="row">
                    {/* {loader ? console.log("loader call") ||<ShimmerBarChart mode="dark" chartType="linear" barWidth={"4%"} /> : null} */}
                      {WaterfallData.length > 0 && Categories.length > 0 ? (
                        <div
                          className=""
                          ref={refGraphDiv}
                          style={{ height: "100%" }}
                        >
                           
                          <Waterfall
                            WaterfallData={WaterfallData}
                            xtitle={xtitle}
                            ytitle={ytitle}
                            dataplotRollOverHandler={dataplotRollOverHandler}
                            DrillDown={DrillDown}
                            DrillUp={DrillUp}
                            height={ChartHeight}
                            Categories={Categories}
                            NotAllow={NotAllow}
                            Downloadchart={Downloadchart}
                            setDownloadchart={setDownloadchart}
                            setLoader={setLoader}
                            loader={loader}
                            Unit={Unit}
                            Fixed={Fixed}
                            ActiveFilters={ActiveFilters}
                            Timeframe={timeframe}
                            Brand={Analyse.brand}
                            BreadCrumbs={BreadCrumbs}
                            full={full}
                            divWidth={divWidth - 40}
                            divHeight={divHeight}
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>

              {/* {
                parseInt(ShowSummary) === 1 || Channel.length > 0 ? */}
              {isRightSidebarVisible && (
                <SummaryAdstock
                  setShowPopup={setShowPopup}
                  DivHeight={DivHeight}
                  Analyse={Analyse}
                  AlternateNotes={AlternateNotes}
                  Summary={Summary}
                  SummaryFetched={SummaryFetched}
                  ShowSummary={ShowSummary}
                  Channel={Channel}
                  SelectedLabel={SelectedLabel}
                  Unit={Unit}
                />
              )}
              {/* : ""
              } */}
            </div>

            <div className="row custom-note">
              <div className="col-sm-1">
                {FooterNotes !== "" ? (
                  <>
                    <i
                      className="fas fa-mail-bulk chart_info_icon footer-notes-icon icon-class"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "15px",
                        marginLeft: "6px",
                        marginBottom: "10px",
                      }}
                      id="clickable"
                    ></i>
                    <Tooltip anchorSelect="#clickable" clickable>
                      <div
                        className="footer-notes"
                        style={{ height: "max-content" }}
                        dangerouslySetInnerHTML={{ __html: FooterNotes }}
                      ></div>
                    </Tooltip>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Main;
