import React,{useState, useRef} from 'react'

import configDriven from '../ConfirgDriven';
import ChannelMultiCascader from "../Components/ChannelMultiCascader";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';



const AllCampaignFilter = (props) => {

  const [reset, setReset] = useState(false);
  console.log("channel filter props", props);
 
  const [channelCheckBox, setChannelCheckBox] = useState(
    props?.selectedCheckboxesC
  );
  const scrollRef= useRef(null);

  const enableFetching = () => {
    
  };
  console.log("channel filter props", props);
  // console.log("props.CompActiveFilters: ", props.CompActiveFilters);
  // console.log("props.CompAllFilters: ", props.CompAllFilters);

  const bgColor = (node) => {
    if (props.Chartdata?.length === 1) {
      if (props.Chartdata[0].hasOwnProperty(node)) {
        if (props.Chartdata[0][node].Filter?.length > 0) {
          return true;
        } else {
          return false;
        }
      }
    } else if (props.Chartdata?.length === 2) {
      if (props.Chartdata[0].hasOwnProperty(node)) {
        if (props.Chartdata[0][node].Filter?.length > 0) {
          return true;
        } else {
          if (props.Chartdata[1].hasOwnProperty(node)) {
            if (props.Chartdata[1][node].Filter?.length > 0) {
              return true;
            } else {
              return false;
            }
          }
        }
      }
    }
  };

  const displayNames = JSON.parse(localStorage.getItem("displayNames"));

  const returnDisplayName = (id) => {
    if (configDriven.show_display_name && displayNames?.hasOwnProperty(id)) {
      return displayNames[id];
    } else {
      return id;
    }
  }

  // const data = JSON.parse(localStorage.getItem("channelFilters"));

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const newItems = Array.from(props.CompActiveFilters);
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);
    props.setCompActiveFilters(newItems);
    props.updateChartData([], newItems);
    // console.log("newItems: ", newItems);
  };


  return (
    <div className={props.ActiveChart === "Comparison" ? "col-xl-6" : "col-xl-7"}
      style={{

        borderRadius: '10px',

        marginTop: 0,
        height: 'max-content'
      }}>
      <div className="heirarchy-class" style={{ position: "relative", border: "none", width: '99.9%' }} ref={scrollRef}>
        <div className="heirarchy-class-div" style={{ position: "relative" }}>
        
          <h6 className="m-0 text-center cm-filter-title  textSize" >Channel Filter</h6>
{props.SelectedChart!=='trends'?
          <p
              className="m-0 px-1 textSize"
              style={{ color: "black" }}
            >
              <i
                className="fas fa-sync-alt"
                style={{
                  cursor:
                   !reset
                      ? "default"
                      : "pointer",
                  color:
                    !reset
                      ? "black"
                      : "white",
                }}
                onClick={() => {
                  if ( props?.selectedCheckboxesC.length!=0) {
                    props.setSelectedCheckboxesC([]);
                    
                    // props.setFetchCall(true);
                    // setReset(true);
 
                    
                  }
                }}
                aria-hidden="true"
              ></i>
            </p>
            :null}

          {props.SelectedChart!=='trends' && props.CFilters &&
            (props.ActiveChart === undefined ||
              props.ActiveChart === "Measurement" ||
              props.ActiveChart === "Comparison" ) && (
              <div style={{ paddingLeft: "0.1rem", }}>
                <ChannelMultiCascader
                scrollRef={scrollRef}
                      setChannelSelectionBreadCrumb={props.setChannelSelectionBreadCrumb}
                  data={props.CFilters}
                  Chartdata={props.Chartdata}
                  setChartdata={props.setChartdata}
                  setFetchCall={props.setFetchCall}
                  selectedCheckboxes={props?.selectedCheckboxesC}
                  setSelectedCheckboxes={props?.setSelectedCheckboxesC}
                  reset={reset}
                  setReset={setReset}
                  enableFetching={enableFetching}
                  fetchData={true}
                />
              </div>
            )}
          <div id="scroller" className="d-flex" style={{ }}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="changeable" direction="horizontal">
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    {props.CompActiveFilters.map((item, index) => (
                      <Draggable
                        key={item.name}
                        draggableId={item.name.toString()}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                          >
                            <li key={index} className={""} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '35px', width: 'auto', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '150px' }} onClick={() => props.RemoveCompFilter(item)}>
                              <span className="btn cm-drag-btn p-1 mx-2 active tab-class-clicked" style={{ height: '24px', padding: '5px' }}>
                                <p className="m-0 text-left px-1 textSize" >{returnDisplayName(item.name)}</p>
                                <p className="m-0 px-1">
                                  <i className="fa fa-minus" aria-hidden="true"></i>
                                </p>
                              </span>
                            </li>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            {
              props.CompAllFilters.map((item, index) => (
                !props.IsInAllCompFilters(item) ? (
                  <li key={index} className={""} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '35px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '150px' }} onClick={() => props.AddCompFilter(index)}>
                    <span className="btn cm-drag-btn p-1 mx-2 tab-class-unclicked" style={{ width: 'auto' }}>
                      <p className="m-0 text-left px-1 textSize"  >{returnDisplayName(item.name)}</p>
                      <p className="m-0 px-1">
                        <i className="fa fa-plus" aria-hidden="true"></i>
                      </p>
                    </span>
                  </li>
                ) : null
              ))
            }
          </div>

        </div>
      </div>
      {/* </div> */}
    </div>
  )
}

export default AllCampaignFilter