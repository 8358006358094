import React, { useEffect } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import '../assets/ManualCSS/custom-stocktools.css';

// Import CSS
import "highcharts/css/stocktools/gui.css";
import "highcharts/css/annotations/popup.css";

// Import and initialize Highcharts modules
import HighchartsMore from "highcharts/highcharts-more";
import IndicatorsAll from "highcharts/indicators/indicators-all";
import DragPanes from "highcharts/modules/drag-panes";
import AnnotationsAdvanced from "highcharts/modules/annotations-advanced";
import PriceIndicator from "highcharts/modules/price-indicator";
import FullScreen from "highcharts/modules/full-screen";
import StockTools from "highcharts/modules/stock-tools";
import HeikinAshi from "highcharts/modules/heikinashi";
import HollowCandle from "highcharts/modules/hollowcandlestick";
import Accessibility from "highcharts/modules/accessibility";

// Initialize Highcharts modules
HighchartsMore(Highcharts);
IndicatorsAll(Highcharts);
DragPanes(Highcharts);
AnnotationsAdvanced(Highcharts);
PriceIndicator(Highcharts);
FullScreen(Highcharts);
StockTools(Highcharts);
HeikinAshi(Highcharts);
HollowCandle(Highcharts);
Accessibility(Highcharts);

const StockChartComponent = ({ data, TrendsHeight }) => {
  const storedDisplayNames = localStorage.getItem("displayNames");
  const displayNames = JSON.parse(storedDisplayNames);

  // Utility function to get display name
  const getDisplayName = (key) => {
    const parts = key.split("-");
    return parts.map(part => displayNames[part] || part).join(" - ");
  };

  useEffect(() => {
    const ohlc = [],
      volume = [];

    for (let i = 0; i < data.data.length; i += 1) {
      const date = new Date(data.data[i].date).getTime(); // Convert date to timestamp
      const totalSales = data.data[i]["sales"];
      const lowerThreshold = data.data[i]["Lower Threshold"];
      const upperThreshold = data.data[i]["Upper Threshold"];
      const normalizedLimit = data.data[i]["Normalized Limit"];
      const sustenance = data.data[i]["Sustenance"];

      ohlc.push([
        date, // the date (timestamp)
        totalSales, // open
        lowerThreshold, // high
        upperThreshold, // low
        normalizedLimit, // close
      ]);

      volume.push([
        date, // the date (timestamp)
        sustenance, // the volume (using sustenance as volume for this example)
      ]);
    }

    Highcharts.stockChart("container", {
      chart: {
        backgroundColor: "#1c2427", // Set the background color
        zoomType: "xy",
        resetZoomButton: {
          position: {
            align: 'center', // Align the button to the center horizontally
            verticalAlign: 'top', // Keep it at the top vertically
            x: 0, // No horizontal offset, keep it centered
            y: 10, // Optional: Adjust vertical offset if needed
          },
          theme: {
            fill: "",
            stroke: "#d6ff41",
            style: {
              color: "white",
            },
          },
        },
      },
      credits: {
        enabled: false, // Disable Highcharts watermark (credits)
      },
      yAxis: [
        {
          labels: {
            align: "left",
            style: {
              color: "#ffffff", // Optional: change text color for better contrast
            },
          },
          height: "80%",
          resize: {
            enabled: true,
          },
        },
        {
          labels: {
            align: "left",
            style: {
              color: "#ffffff", // Optional: change text color for better contrast
            },
          },
          top: "80%",
          height: "20%",
          offset: 0,
        },
      ],
      tooltip: {
        shared: true,
        followPointer: true,
        backgroundColor: "#ffffff",
        style: {
          color: "#000000",
        },
        useHTML: true,
        formatter: function () {
          // Find the data point that matches the x value (date)
          const pointData = data.data.find(
            item => new Date(item.date).getTime() === this.x
          );

          // Format the tooltip text
          let tooltipText = "<b>" + Highcharts.dateFormat("%Y-%m-%d", this.x) + "</b><br/>";
          tooltipText += `<b>${getDisplayName(data.chart_config.yAxis0)}</b><br/>`;
          tooltipText += `Sales: <b>${pointData["sales"]}</b><br/>`;
          tooltipText += `Lower Threshold: <b>${pointData["Lower Threshold"]}</b><br/>`;
          tooltipText += `Upper Threshold: <b>${pointData["Upper Threshold"]}</b><br/>`;
          tooltipText += `Normalized Limit: <b>${pointData["Normalized Limit"]}</b><br/>`;
          tooltipText += `Sustenance: <b>${pointData["Sustenance"]}</b><br/>`;

          return tooltipText;
        },
      },
      series: [
        {
          type: "line",
          id: "aapl-ohlc",
          name: data.chart_config.yAxis0,
          data: ohlc,
          color: "#d6ff41",
          
          
        },
        {
          type: "column",
          id: "aapl-volume",
          name: data.chart_config.yAxis0,
          data: volume,
          yAxis: 1,
        },
      ],
      rangeSelector: {
        enabled: false, // Disable the range selector buttons (1m, 3m, etc.)
      },
      navigator: {
        enabled: true, // Enable the navigator for zooming
        height: 20, // Set the height of the navigator
        scrollbar: {
          enabled: false, // Remove the scrollbar from the navigator
        },
      },
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 800,
            },
            chartOptions: {
              rangeSelector: {
                inputEnabled: false,
              },
            },
          },
        ],
      },
    });
  }, [data]);

  return <div id="container" className="chart" style={{ height: window.innerHeight < 850 ? window.innerHeight * 0.78 : window.innerHeight * 0.84, width: "100%" }}></div>;
};

export default StockChartComponent;
