import React, { useEffect, useState, useRef, useLayoutEffect, useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "../Axios";
import Loader from "./Loader";
import { redirect, useNavigate } from "react-router";
import { Link } from "react-router-dom";
// import PopUp from "./PopUp"  ;
import CustomSelection from "./CustomSelect.js"
import CascaderWrapper from "./SingleCascading.js";
import appContext from "../context/appContext";
import configDriven from "../ConfirgDriven.js";
import { UndoRounded } from "@mui/icons-material";


const DetailsPopUp = () => {
  // console.log("Data: ", Data);
  const navigate = useNavigate();
  const DivRef = useRef(null);
  const mainDef = useRef(null);

  const casRef = useRef(null);

  const [divWidth, setDivWidth] = useState("");

  const context = useContext(appContext);
  const { isOpen, Analyse, setIsOpen, DetailsId, setDetailsId, Chartdata, Details, setDetails, node, setAnalyticFilter, setDefaultLevel, setChartdata, setAnalyse, setCampaignAnalyticFilter, setBrandDetails } = context;

  const displayNames = JSON.parse(localStorage.getItem("displayNames"));

  const returnDisplayName = (id) => {
    if (configDriven.show_display_name && displayNames?.hasOwnProperty(id)) {
      return displayNames[id];
    } else {
      return id;
    }
  }

  // console.log("divWidth: ", divWidth);

  useLayoutEffect(() => {
    const updateDivHeight = () => {
      if (casRef.current !== null) {
        const newWidth = casRef.current.clientWidth;
        setDivWidth(newWidth);
      }
    };

    updateDivHeight(); // Initial update
    window.addEventListener("resize", updateDivHeight);

    return () => {
      window.removeEventListener("resize", updateDivHeight);
    };
  }, []);

  const [loader, setLoader] = useState(false);
  const [KPIAll, setKPIAll] = useState([]);
  const [KPI, setKPI] = useState("");
  const [KPIName, setKPIName] = useState("");
  const [AnalyticsToolAll, setAnalyticsToolAll] = useState([]);
  const [AnalyticsTool, setAnalyticsTool] = useState("");

  // console.log("AnalyticsTool: ", AnalyticsTool);

  const [tag1, settag1] = useState("");
  const [tag_unit1, settag_unit1] = useState("");
  const [tag_val1, settag_val1] = useState();
  const [tag2, settag2] = useState("");
  const [tag_unit2, settag_unit2] = useState("");
  const [tag_val2, settag_val2] = useState("");

  const [Redirect1, setRedirect1] = useState(false);
  const [showPopup, setIsOpenPopup] = useState(false);

  const [getBrand, setGetBrand] = useState("");

  const [BrandFullName, setBrandFullName] = useState("");


  useEffect(() => {
    if (getBrand !== "") {
      setDetailsId(getBrand?.id);
      setDetails({
        id: getBrand?.id,
        name: getBrand?.name,
        hirarchy_level: getBrand?.hirarchy_level,
        img: getBrand?.img,
      });


      setBrandDetails(
        {
          id: getBrand?.id,
          name: getBrand?.name,
          hirarchy_level: getBrand?.hirarchy_level,
          img: getBrand?.img,
        }
      );
    }
  }, [getBrand]);



  const SubmitAnalyse = () => {
    // console.log("Clicked on Analyse: ")
    setLoader(true);

    axios
      .post("get_hirarchy_dropdown/", {
        hirarchy: Details.id,
        // hirarchy: getBrand.value
      })
      .then((response) => {
        var all_heirarchy_filter = [];
        var active_heirarchy_filter = [];

        var heirarchy_sortable = [...response.data.data.sortable];

        for (let i = 0; i < heirarchy_sortable.length; i++) {
          if (parseInt(heirarchy_sortable[i].default) === 1) {
            active_heirarchy_filter.push(heirarchy_sortable[i]);
          }
          all_heirarchy_filter.push(heirarchy_sortable[i]);
        }

        const chartdata = {};
        for (var k = 0; k < response.data.data.fixed.length; k++) {
          chartdata[response.data.data.fixed[k].id] = {
            selection: [],
            Filter: [response.data.data.fixed[k].value],
          };
        }

        var temp_active_heirarchy = [];
        for (var j = 0; j < active_heirarchy_filter.length; j++) {
          var obj = active_heirarchy_filter[j];
          obj["Selected"] = [];
          obj["filters"] = active_heirarchy_filter[j].options;
          temp_active_heirarchy.push(obj);
          chartdata[active_heirarchy_filter[j].name] = {
            selection: [],
            Filter: [],
          };
        }

        console.log("All-data Filter ", all_heirarchy_filter);
        console.log("All-data Fixed ", response.data.data.fixed);
        console.log("All-data ActiveFilters ", temp_active_heirarchy);

        const AnalyticFilter = {
          AllFilters: all_heirarchy_filter,
          Fixed: response.data.data.fixed,
          ActiveFilters: temp_active_heirarchy,
        };

        setAnalyticFilter((AnalyticFilter));
        localStorage.setItem('StoreAnalyticFilter', JSON.stringify(AnalyticFilter));
        setDefaultLevel(
          (response.data.data.DefaulLevel)
        );

        console.log("Analyse button clicked3 on clicked ", chartdata);
        setChartdata(chartdata);
        setLoader(false);
        isOpen(false);


      })
      .catch((data) => {
        setLoader(false);
      });



    const Analyse = {
      KPI: KPI,
      KPIName: KPIName,
      kpiID: getKPI?.id,
      AnalyticsTool: AnalyticsTool,
      Brand: Details.id,
      // Brand: getBrand.value,
      BrandName: Details.name,
      // BrandName: getBrand.name,
      Menubar: AnalyticsToolAll,
    };
    console.log("Analyse ", Analyse);
    setAnalyse(Analyse);
    setRedirect1(true);
    if (DetailsId !== '') {
      localStorage.setItem('Header-name', DetailsId);
    }
    // if(Details.id && KPI)
    // {
    //   Nextpage();
    // }
    // setAnalysisClicked(true);
  };



  useEffect(() => {


    if (Redirect1 === true) {
      console.log("Analyse button clicked inside");
      setLoader(true);
      axios
        .post("get_grain_contrib_new_filters/", {
          brand: Details.id,
          kpi: KPI,
        }
        )
        .then((response) => {
          if (response.data.error === 1) {
            toast.error(response.data.erroMsg);
            setLoader(false);
          } else {
            console.log("Analyse button clicked response");
            console.log("ANalysse button ", response.data);
            var active_campaign_filter = [];
            var all_campaign_filter = [];
            var campaign_sortable = [...response.data.data];

            for (let i = 0; i < campaign_sortable.length; i++) {
              if (parseInt(campaign_sortable[i].default) === 1) {
                active_campaign_filter.push(campaign_sortable[i]);
              }
              all_campaign_filter.push(campaign_sortable[i]);
            }
            console.log("Analyse button clicked after first loop", active_campaign_filter);
            // // Prepare chartdata for selected comp filters
            var ChartdataTemp = Chartdata;
            for (var m = 0; m < active_campaign_filter.length; m++) {
              const selection = [];
              if ("Selected" in active_campaign_filter[m]) {
                for (
                  var j = 0;
                  j < active_campaign_filter[m].Selected.length;
                  j++
                ) {
                  selection.push(active_campaign_filter[m].Selected[j].value);
                }
              }
              ChartdataTemp[active_campaign_filter[m].name] = {
                selection: [],
                Filter: selection,
              };
            }
            console.log("Analyse button clicked length", ChartdataTemp);


            console.log("Analyse button clicked1");
            const CampaignAnalyticFilter = {
              CompAllFilters: all_campaign_filter,
              CompActiveFilters: active_campaign_filter,
            };

            setCampaignAnalyticFilter(
              (CampaignAnalyticFilter)
            );
            console.log("Analyse button clicked2", ChartdataTemp);
            console.log("chardata on useEffect call ", ChartdataTemp);
            setChartdata(ChartdataTemp);
            setLoader(false);

            const output = Analyse.Menubar.find(item =>
              item.children?.find(child => child.id === AnalyticsTool.id)
            )?.id;


            console.log("output: ", output, " ", AnalyticsTool);


            navigate("/" + AnalyticsTool.new_id);


            localStorage.setItem('Chartdata', JSON.stringify(ChartdataTemp));
            localStorage.setItem("CampaignAnalyticFilter", JSON.stringify(CampaignAnalyticFilter));

            localStorage.setItem('StoreCampaignAnalyticFilter', JSON.stringify(CampaignAnalyticFilter));
            // navigate('/eda');
            setIsOpen(false);
            // window.location.reload(false);
            // setAnalysisClicked(false);
          }
        })
        .catch((data) => {
          setLoader(false);
          toast.error(data);

        });

      setRedirect1(false);


    }

  }, [Chartdata]);



  const handleFilterClick = (event) => {
    event.stopPropagation(); // Prevent the click event from propagating
    if (mainDef.current && !mainDef.current.contains(event.target)) {
      // setIsOpen(false);
      // setDetailsId("");
      if (typeof isOpen !== "undefined") {
        setIsOpen(false);
        // console.log("ISOPEN false ");
      }
    }
  };

  const AllBrands = JSON.parse(localStorage.getItem("allBrands"));

  // console.log("kpiID: ", Analyse.kpiID);


  // kpi cascader api call
  const [allKPI, setAllKPI] = useState([]);
  const [getKPI, setGetKPI] = useState("");
  const [matchKPI, setMatchKPI] = useState("");


  const Analyse1 = JSON.parse(localStorage.getItem('Analyse')) || undefined;

  useEffect(() => {
    const fetchData = async () => {

      if (Details.hirarchy_level !== "" && Details.name !== "") {
        const payload = {
          level: Details.hirarchy_level,
          name: Details.id,
        };

        // console.log("details Payload", payload)
        try {
          console.log("kpi option: before");
          const response = await axios.post("get_kpi_tools/", payload);
          console.log("kpi option: ", response.data.data, Analyse1, Analyse1?.KPI);
          
          setAllKPI(response.data.data);
          let filter = [];
          // if (Analyse1!=undefined && Analyse1?.KPI != undefined ) {

          //   // Loop through each KPI object and filter its children
          //   response.data.data.forEach(kpi => {
          //     console.log("kpi option: inside", Analyse1?.KPI, "  ", kpi.children);
          //     const matchedChildren = kpi.children.filter(child => child.value === Analyse1?.KPI);
          //     if (matchedChildren.length > 0) {
          //       filter = matchedChildren;

          //     }
          //   });
          //   console.log("kpi option: 2", filter);
          //   setGetKPI(filter[0]);
          // }

          if (filter.length == 0) {
            const findLastChildWithoutChildren = (node) => {
              // Base case: if the current node has no children, return the node
              if (!node.children || node.children.length === 0) {
                return node;
              }
            
              // Recursively call the function on the last child
              return findLastChildWithoutChildren(node.children[0]);
            };
            
            const data = response?.data?.data[0];
            const lastChildWithoutChildren = findLastChildWithoutChildren(data);
            setGetKPI(lastChildWithoutChildren);
            localStorage.setItem("KPIs",JSON.stringify(response.data.data));
          }




        } catch (error) {
          console.log(error);
        }
      }
    };

    fetchData();
  }, [Details]); // Add Details as a dependency to run the effect when it changes

  useEffect(() => {

    const fetchData = async () => {

        
      if (Details.hirarchy_level !== "" && Details.name !== "" && getKPI!="") {
        console.log("KPI before",getKPI);
        const payload = {
          name: Details.id,
          kpi:getKPI.id
        };

        // console.log("details Payload", payload)
        try {

          const response = await axios.post("new_get_kpi_tools/", payload);
          // setAllKPI(response.data.data);
          setAnalyticsToolAll(response.data.data);
          // let filter = [];
          // if (Analyse1 != undefined && Analyse1?.AnalyticsTool != undefined) {
          //     // Loop through each KPI object and filter its children
          //   response.data.data.forEach(item => {
              
              
          //     const matchedChildren = item.children.filter(child => child.id === Analyse.AnalyticsTool?.id);
          //     if (matchedChildren.length > 0) {
          //       filter = matchedChildren;
          //     }
          //   });
          // //   console.log("kpi option: 2", filter);
          // //   setGetKPI(filter[0]);
          //   console.log("setAnalyticsTool local",filter[0]);
          //   setAnalyticsTool(filter[0]);
          // }
          // else if(filter.length==0) {
          //   console.log("setAnalyticsTool ",response.data.data[0].id);
            
          // }
          setAnalyticsTool(response.data.data[0].children?.[0]);
        } catch (error) {
          console.log(error);
        }
      }
    };

    fetchData();

  }, [Details,getKPI])


  useEffect(() => {

    if (getKPI !== "") {
      console.log("getKPI kpi ", getKPI);
      settag_unit1(getKPI?.tag_unit1);
      settag_val1(getKPI?.tag_val1);
      settag2(getKPI?.tag2);
      settag1(getKPI?.tag1);
      settag_unit2(getKPI?.tag_unit2);
      settag_val2(getKPI?.tag_val2);
      setKPIName(getKPI?.kpi_display_name);
      // setAnalyticsToolAll(getKPI?.analysis_tools);
      // setAnalyticsTool(getKPI?.analysis_tools?.[0].value);
      setKPI(getKPI?.value);
      setKPIName(getKPI?.name);
      setMatchKPI(getKPI?.id);
    }

  }, [getKPI, getBrand])




  const handleLogout = () => {
    // console.log("logout");
    localStorage.clear();
  }


  useEffect(() => {
    if (DivRef.current && isOpen === false) {

      setTimeout(() => {
        // console.log("Scrolling to top", DivRef.current);
        // setDivDisplay('none');
        DivRef.current.scrollIntoView({ behavior: "instant", block: "end" });
      }, 400)
    }

  }, [isOpen]);


  useEffect(() => {
    if (mainDef.current) {
      mainDef.current.style.right = isOpen ? '0' : '-350px';
    }
  }, [isOpen]);
  return (
    <>
      {loader ? <Loader /> : null}
      <ToastContainer theme="colored" />
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100vh",

          // background: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black background
          // backdropFilter: 'blur(4px)', // Apply blur effect
          // opacity:'.1',
          // overflowX:"hidden",
          zIndex: "999",
          // transition:'backdropFilter .4 ease-in-out',

          // border: "1px solid red"
        }}


      >
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100vh",
            background: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black background
            backdropFilter: 'blur(3px)', // Apply blur effect
            // opacity:'.1',
            // overflowX:"hidden",
            zIndex: "999",
            // transition:'backdropFilter .4 ease-in-out',

            // border: "1px solid red"
          }}

          onClick={(e) => {
            handleFilterClick(e);
          }}
        >

        </div>
        <div
          className={'right-bar'}
          ref={mainDef}
          style={{
            width: '320px',
            maxWidth: '400px',
            paddingLeft: '5px',
            right: '-350px',
            transition: 'right .4s ease-in-out',
            position: 'fixed',
            background: '#1c2427'




          }}
        >

          <div data-simplebar className="h-100" style={{ height: "100%", overflowY: "scroll", }}>
            <div className="rightbar-title d-flex align-items-center pt-4 pb-2" ref={DivRef}>

            </div>

            <div className="row justify-content-center">
              <div className="col-sm-12 text-center" >
                <img
                  src={`${Details.img}?v=${new Date().getTime()}`}
                  alt=""
                  style={{
                    width: "200px",
                    height: "100px",
                    objectFit: "contain",
                  }}
                />
              </div>
              <div
                className="col-sm-12 text-center"

              >
                <h4 className="h-menu-user-name" style={{ marginTop: '15px', marginBottom: '15px' }}>
                  {returnDisplayName(Details.name)}
                </h4>
              </div>
              {/* <hr className="" /> */}
            </div>

            <div  >
              <div className="" style={{
                display: 'flex',
                justifyContent: 'space-around',

              }}>
                <div className="tag-class" style={{ marginRight: '6px',width:'50%' }} >
                  <div
                    className="brand-exp-txt"
                    style={{ display: "flex", flexDirection: "column", gap: 7 }}
                  >
                    <p className="m-0">
                      <u> {tag1}</u>
                      {/* <u>{"Aug 2022 - Jul 2023 Spends"}</u> */}
                    </p>
                    <p className="m-0">
                      {tag_val1} {tag_unit1}
                    </p>
                  </div>
                </div>
                <div className="tag-class" style={{width:'50%' }}>
                  <div
                    className="brand-exp-txt"
                    style={{ display: "flex", flexDirection: "column", gap: 7 }}
                  >
                    <p className="m-0">
                      <u> {tag2}</u>
                      {/* <u>{"Aug 2022 - Jul 2023 Spends"}</u> */}
                    </p>
                    <p className="m-0">
                      {/* <img src={Vector} className="p-1" alt="" /> */}
                      {tag_val2} {tag_unit2}
                    </p>
                  </div>
                </div>
              </div>

              <div className="my-3">
                <div className="form-group row my-2">
                  {/* for brand selection */}
                  <div className="col-12" style={{}} ref={casRef}>

                    <label className="login-lable h-menu-label" style={{ fontWeight: 'normal', padding: '3px', }}>
                      Select or Search Brand
                    </label>

                    {/* <SelectBrand
                      setGetBrand={setGetBrand}
                      match={Details.id}
                      setDetailsId={setDetailsId}
                      setDetails={setDetails}
                    /> */}
                    <CascaderWrapper
                      data={[AllBrands]}
                      divWidth={divWidth}
                      setGetBrand={setGetBrand}
                      match={Details.id}
                      setDetailsId={setDetailsId}
                      setDetails={setDetails}
                    />
                  </div>

                  {/* for brand selection */}

                  {/* for kpi selection */}
                  <div className="col-12">

                    <label className="login-lable h-menu-label" style={{ fontWeight: 'normal', padding: '3px', paddingTop: '18px' }}>
                      Select or Search KPI
                    </label>

                    {/* <select
                      className="form-select"
                      value={KPI}
                      onChange={KPIHandler}
                    >
                      {KPIAll.map((item) => {
                        return (
                          <option
                            key={item.kpi_short_code}
                            value={item.kpi_short_code}
                          >
                            {item.kpi_display_name}
                          </option>
                        );
                      })}
                    </select> */}
                    {/* <CascaderWrapper
                      data={[AllBrands]}
                      divWidth={divWidth}
                      setGetBrand={setGetBrand}
                      match={Details.id}
                      setDetailsId={setDetailsId}
                      setDetails={setDetails}
                    /> */}
                    {getKPI !== "" && matchKPI &&
                      <CascaderWrapper
                        key={matchKPI}
                        data={allKPI}
                        setGetBrand={setGetKPI}
                        match={matchKPI}
                      />}
                  </div>
                  <div className="col-12">

                    <label className="login-lable h-menu-label" style={{ fontWeight: 'normal', padding: '3px', paddingTop: '18px' }}>
                      Select Analytics Tool
                    </label>

                    {/* <select  onFocus={(e) => e.target.size = 1}
                           onBlur={(e) => e.target.size = 1}
                    className="form-select"
                    value={AnalyticsTool}
                    onChange={(e) => setAnalyticsTool(e.target.value)}
                  >
                    {AnalyticsToolAll.map((item) => {
                      return (
                        <option key={item.key} value={item.key} className="optionHover">
                          {item.value}
                          
                        </option>
                      );
                    })}
                  </select> */}

                    {/* <CustomSelection
                      value={AnalyticsTool}
                      onChange={(e) => setAnalyticsTool(e.target.value)}
                      options={AnalyticsToolAll}
                      barWidth={274}
                    /> */}

                    {getKPI !== "" && matchKPI &&
                      <CascaderWrapper
                        key={AnalyticsTool?.id}
                        data={AnalyticsToolAll}
                        setGetBrand={setAnalyticsTool}
                        match={AnalyticsTool?.id}
                      />}

                  </div>

                </div>



                <div className="form-group row my-2 text-center">
                  <div className="col-12 mt-3">
                    <button
                      className="btn-class"
                      type="button"
                      onClick={SubmitAnalyse}
                    >
                      Analyse
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <footer className="right-bar-footer"  >
              {/* onClick={()=>{
                  window.Change_Theme()
                }} */}

              {window.innerWidth < 600 && <div className="row py-2 ">
                <div className="col-sm-12">
                  <Link to="/gpt" className="h-menu-link">
                    <p className="mb-0">
                      <u>Skewb GPT</u>
                    </p>
                  </Link>
                </div>
              </div>
              }
              {window.innerWidth < 600 &&
                <div className="row py-2 ">
                  <div className="col-sm-12">
                    <Link to="/portfolio" className="h-menu-link">
                      <p className="mb-0">
                        <u>Back To Portfolio Landscape</u>
                      </p>
                    </Link>
                  </div>
                </div>
              }
              <div className=" gautam" >
                {localStorage.getItem("is_superuser") === "1" ? (
                  <div className="row py-2 ">
                    <div className="col-sm-12">
                      <Link to="#" className="h-menu-link">
                        <p className="mb-0">
                          <u>User Management </u>
                        </p>
                      </Link>
                    </div>
                  </div>
                ) : null}
                {/* <div className="row py-2 ">
                  <div className="col-sm-12" onClick={() => {
                    setIsOpenPopup(true);
                  }}>
                    <Link to="" className="h-menu-link">
                      <p className="mb-0">
                        <u>Change Theme</u>
                      </p>
                    </Link>
                  </div>
                </div> */}

                {/* <div className="row py-2 ">
                  <div className="col-sm-12">
                    <Link to="#" className="h-menu-link">
                      <p className="mb-0">
                        <u>Theme</u>
                      </p>
                    </Link>
                  </div>
                </div> */}
                <div className="row py-2 ">
                  <div className="col-sm-12">
                    <Link
                      to=""
                      className="h-menu-link"
                      onClick={(e) => e.preventDefault()}
                    >
                      <p className="mb-0">
                        <u onClick={() => setIsOpenPopup(true)}>
                          Contact Support
                        </u>
                      </p>
                    </Link>
                  </div>
                </div>
                <div className="row py-2"
                >
                  <div className="col-sm-12">
                    <Link to="/login" className="h-menu-link" onClick={() => { handleLogout() }}>
                      <p className="mb-0">
                        <u >Logout</u>

                      </p>
                    </Link>
                  </div>
                </div>
              </div>
            </footer>
          </div>

        </div>
        {/* <div className="rightbar-overlay"></div> */}
      </div>
      {/* {showPopup && (
        <PopUp
          setIsOpenPopup={setIsOpenPopup}
          width={"300px"}
          height={"160px"}
          children={<h3 style={{ color: "#d6ff41" }}>info@skewb.ai</h3>}
        />
      )} */}
    </>
  );
};

export default DetailsPopUp;
