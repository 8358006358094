import React, { useState, useEffect, useRef } from "react";
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import configDriven from "../ConfirgDriven";



const Cascader = ({
  data,
  searchValue,
  setSearchValue,
  selectedPath,
  setSelectedPath,
  setIdList,
  pathName,
  level = 0,
  st=10000,
  setGetBrand
}) => {
  const displayNames = JSON.parse(localStorage.getItem("displayNames"));

  const returnDisplayName = (id) => {
      if (configDriven.show_display_name && displayNames?.hasOwnProperty(id)) {
          return displayNames[id];
      } else {
          return id;
      }
  };
  const expandedCategoryRef = useRef(null);
  const timerRef = useRef(null);
  const rootRef = useRef(null);
  const child=useRef(null);
  const span=useRef(null);
  const [isBottom,setIsBottom]=useState(false);
  const [flag,setFlag]=useState(false);
  const [style, setStyle] = useState({});
  const[height,setHeight]=useState(0);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedExpandIcon, setSelectedExpandIcon] = useState(null);
  const [hoveredCategory, setHoveredCategory] = useState(null);
  const[hit,setHit]=useState(false);
  const [hover,setHover]=useState(false);
  

  const handleCategoryButtonClick = (categoryId, category,event) => {
    console.log("categoryy ",category);

    if(category.access==1)
        {setGetBrand(category)

        
    
    
    console.log("categoryID: ",categoryId);
    
    event.stopPropagation();
    setSelectedExpandIcon((prevCategory) =>
      prevCategory === categoryId ? null : categoryId
    );
    
    // expandedCategoryRef.current = event.currentTarget;
    const pathSegments = categoryId.split("-");
    const newPath = pathSegments.map((segment, index) =>
      pathSegments.slice(0, index + 1).join("-")
    );

    setIdList(newPath);
    setSearchValue(pathName);

   


    if (categoryId !== selectedCategory) {
      setSelectedCategory(null);
      setSelectedExpandIcon(null);
      setSelectedPath(newPath);
      setSelectedCategory(categoryId);
      setSelectedExpandIcon(categoryId);
    } else {
      setSelectedExpandIcon(null);
      setSelectedPath((prevSelectedPath) => {
        if (prevSelectedPath.length >= newPath.length) {
          return newPath.slice(0, pathSegments.length);
        }
        return newPath;
      });
      setSelectedCategory((prevSelectedCategory) => {
        const prevPathSegments = prevSelectedCategory
          ? prevSelectedCategory.split("-")
          : [];
        if (prevPathSegments.length >= pathSegments.length) {
          return pathSegments.join("-");
        }
        return prevSelectedCategory;
      });
    }
}
  };

  const handleExpandIconHover = (categoryId, event) => {

    console.log("CatergoryId on hover hoveredCategorycheckpos ",categoryId);

    setHover(true);

    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    setHoveredCategory(categoryId);
    setSelectedExpandIcon(categoryId);
    
    expandedCategoryRef.current = event.currentTarget;
    

    setSelectedCategory(categoryId);
  };

  const handleExpandIconLeave = (event) => {
    timerRef.current = setTimeout(() => {
        // setFlag(false)
        setHover(false);
      setHoveredCategory(null);
      setSelectedExpandIcon(null);
      setSelectedCategory(null);
    //   console.log(event.target?.parentNode)
    //   rootRef.current=event.target.parentNode;
    }, 0);
  };


  useEffect(() => {
    console.log("hoveredCategorycheckpos ", hoveredCategory);
  
    const checkPosition = () => {
      if (!rootRef.current || hoveredCategory == null) return;
  
      const store = rootRef.current;
      const bottomBoundary = store.getBoundingClientRect().bottom;
      const childDivs = document.querySelectorAll(`.${hoveredCategory}`);
      
      console.log("Max height:", bottomBoundary);
      console.log("Child Divs for hovered category:", childDivs);
      
      // Reset styles for previously hovered elements
      childDivs.forEach((div) => {
        div.style.border = ''; // Reset custom border
        div.style.right='';
        div.style.left='calc(100% + 20px)'
        // div.style.bottom = ''; // Reset positioning
      });
  
      // Update height for level 1 categories if not already set
      if(level==0)
        {
          if(height==0){
          setHeight(childDivs.length>0? childDivs[childDivs.length-1].getBoundingClientRect().bottom:st);
          
          console.log("asfoihasifjhask;jfhasjdhf",childDivs, "  ",level,st,height) ;
          }
        }
  
      // Iterate through each hovered child div to adjust styling based on its position
      childDivs.forEach((div) => {
        const divBottom = div.getBoundingClientRect().bottom;
        const divRight = div.getBoundingClientRect().right;
        
        console.log("Comparing bottom positions:", divBottom, " vs ", st);
  
        // Check if the child div exceeds the root container or window height
        if (divBottom > st || divBottom > window.innerHeight) {
          // div.style.top = `${div.getBoundingClientRect().top}px`;
          // div.style.bottom = '0px'; // Adjust position to bottom
          // div.style.border = '2px solid orange'; // Add custom border
        }
  
        // Check if the child div exceeds the window's width (right boundary)
        if (divRight > window.innerWidth) {
          div.style.left = '';
          div.style.right = '122%';
          // div.style.top = `${divBottom - div.getBoundingClientRect().top - 10}px`;
          div.style.top=0;
          div.style.zIndex = '1000'; // Ensure higher z-index to prevent overlap issues
          // rootRef.current.style.backgroundColor = 'gray'; // Example background change
          // div.style.border=`solid red 2px`;
  
          console.log("Div touched the right boundary:", div);
          // setHit(true); // Flag that right boundary has been hit
        }
      });
  
      setFlag(true); // Set flag once all adjustments are done
    };
  
    // Perform initial position check
    checkPosition();
    
  }, [rootRef.current, child.current, span.current, hoveredCategory]);
  

  


  return (
    <div style={{ position: "relative" }} >
      <div
        ref={rootRef}
        style={{
          
          paddingLeft:"2px",
          backgroundColor: "#d6ff41",
          overflowY: "auto",
        //   borderRadius: "8px",
          whiteSpace: "nowrap",
          minWidth: "calc(100% + 20px)",
          // border:"1px solid blue",
          overflow:'hidden'
          
        }}
        className="root"
        
        
      >
        { 
        data?.map((category) => (
          <div key={category.id} style={{ marginBottom: "0px", width: "100%" }}>
            <div style={{ display: "flex", width: "100%" }}>
              <div
                style={{
                  color: selectedPath?.includes(category.id) ? "black" : "white",
                  padding: "2px 3px 2px 2px",

                  
                  height:'28px',

                  fontSize:'small',
                  cursor: "pointer",
                  backgroundColor: selectedPath?.includes(category.id)
                    ? "#D6FF41"
                    : selectedExpandIcon === category.id
                    ? 'gray'
                    : hit==false?"#3e5056":'#1c2427',
                  display: "flex",
                  width: "100%",
                  cursor: category.access === 0 ? 'not-allowed' : 'pointer',
                  
                }}
                
                onMouseEnter={(e) => handleExpandIconHover(category.id, e)}
                onClick={(event) => handleCategoryButtonClick(category.id, category,event)}
                onMouseLeave={handleExpandIconLeave}
              >

                <span style={{ flexGrow: 1, textAlign: "left",
                  display: 'flex',
                  alignItems: 'center',
                  paddingLeft:'2px',
                  // justifyContent: 'center',
                  fontSize:'11px',
                  
                 }}>

                 
                  {returnDisplayName(category.id.split('-').pop())}
                </span>
                {category?.children?.length>0 && (
                  <span ref={span}
                    style={{

                      cursor: "pointer",
                      textAlign: "right",
                      color: selectedPath?.includes(category.id)
                        ? "black"
                        : "#D6FF41",

                      font:'small',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      
                      
                      

                    }}
                    onMouseEnter={(e) => handleExpandIconHover(category.id, e)}
                    className={console.log("hover",hover) ||selectedExpandIcon === category.id && hover===true ?`arrow-icon-hovered`:''}
                  >
                  {selectedExpandIcon === category.id && hover==false ? <KeyboardArrowLeftOutlinedIcon /> : <KeyboardArrowRightOutlinedIcon />}
                  
                  </span>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      
      {selectedExpandIcon &&
        data?.find((cat) => cat.id === selectedExpandIcon)?.children?.length>0 &&
        expandedCategoryRef.current 
        && (
            
              
              <div
                ref={child} 
                onMouseEnter={(e) => handleExpandIconHover(selectedExpandIcon, e)}
                onMouseLeave={handleExpandIconLeave}
                
                style={{
                  position: "absolute",
                  // background: "white",
                  left: "calc(100% + 20px)",
                  
                  
                  top:`${expandedCategoryRef.current?.offsetTop}px`,
                  
                  // top: level === 0 ? `${expandedCategoryRef.current?.offsetTop}px` : `0px`,
                //   ...( !flag
                //     ? { top: level === 0 ? `${expandedCategoryRef.current?.offsetTop}px` : `${expandedCategoryRef.current?.offsetTop}px` }
                //     : { bottom: "0px" }),
                  zIndex: 1000 + level,
                }}
                className={console.log("selectedExpandIcon",selectedExpandIcon)||selectedExpandIcon ? selectedExpandIcon : ''}
                // className="child"

              >
                <Cascader
                  data={data.find((cat) => cat.id === selectedExpandIcon).children}
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                  selectedPath={selectedPath}
                  setSelectedPath={setSelectedPath}
                  setIdList={setIdList}
                  pathName={pathName}
                  level={level + 1}
                  st={level==1?height:st}
                  setGetBrand={setGetBrand}

                  
                  
                  
                />
              </div>
            
          )}
          
    </div>
  );
};

const CascaderWrapper = ({ data, match,setGetBrand }) => {

  const displayNames = JSON.parse(localStorage.getItem("displayNames"));

  const returnDisplayName = (id) => {
      if (configDriven.show_display_name && displayNames?.hasOwnProperty(id)) {
          return displayNames[id];
      } else {
          return id;
      }
  };

  // console.log("MATCH cascader",match);
  const [searchValue, setSearchValue] = useState("");
  const [isCascaderVisible, setIsCascaderVisible] = useState(false);
  const [selectedPath, setSelectedPath] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [isDropDownVisible, setIsDropDownVisible] = useState(false);
  const dropdownRef = useRef(null);
  const cascaderRef = useRef(null);
  const searchBarRef = useRef(null);
  const [idList, setIdList] = useState([]);
  const [nameList, setNameList] = useState([]);
  const [pathName, setPathName] = useState("");

  function flattenDataToObject(data) {
    let result = {};

    function flatten(item) {

      result[item?.id] = returnDisplayName(item?.id.split('-').pop());
      if (item?.children) {
        item?.children?.forEach(flatten);
      }
    }

    data?.forEach(flatten);
    return result;
  }

  const flattenedData = flattenDataToObject(data);

  function getTeaList(idList, flattenedData) {
    return idList?.map((key) => flattenedData[key]);
  }

  useEffect(() => {
    const result = getTeaList(idList, flattenedData);
    setNameList(result);
  }, [idList]);

  useEffect(() => {
    if (nameList) {
      const names = nameList.join(" / ");
      setPathName(names);
      setSearchValue(names);
    } else {
      setPathName("");
      setSearchValue("");
    }
  }, [nameList]);

  useEffect(() => {
    const pathSegments = match?.split("-");
    const newPath = pathSegments?.map((segment, index) =>
      pathSegments.slice(0, index + 1).join("-")
    );
    setSelectedPath(newPath);
    const result = getTeaList(newPath, flattenedData);
    setNameList(result);

    if (nameList) {
      const names = nameList.join(" / ");
      setPathName(names);
      setSearchValue(names);
    } else {
      setPathName("");
      setSearchValue("");
    }
  }, []);

  const toggleCascaderVisibility = () => {
    setIsCascaderVisible(!isCascaderVisible);
  };

  const handleSearchInputChange = (event) => {
    const searchTerm = event.target.value;
    setSearchValue(searchTerm);
    if (searchTerm.trim() === "") {
      setSearchResults([]);
      setSearchValue("");
      setIsDropDownVisible(false);
    } else {
      const matchingResults = findMatchingResults(data, searchTerm);
      setSearchResults(matchingResults);
      setIsDropDownVisible(true);
    }
  };

  const handleSearchResultClick = (result) => {

    
    console.log("results ",result);
    const findCategoryById = (data, id) => {
        for (let category of data) {
            if(category.id===id && category.access==0)
                    return null;
          if (category.id === id && category.access==1) {
            return category;
            
          }
          if (category.children) {
            const found = findCategoryById(category.children, id);
            if (found) {
              return found;
            }
          }
        }
        return null;
      };
     
      const fullCategory = findCategoryById(data, result.id);
      console.log("fullCategory ",fullCategory);
      if(fullCategory!=null){
      setGetBrand(fullCategory);
      

    console.log("resultss ",result);
    const pathSegments = [];
    const idSegments = result.id.trim().toLowerCase().split("-");
    for (let i = idSegments.length; i > 0; i--) {
      pathSegments.push(idSegments.slice(0, i).join("-"));
    }

    result.path.forEach((segment) => {
      const segments = segment.trim().toLowerCase().split("-");
      for (let i = segments.length; i > 0; i--) {
        pathSegments.push(segments.slice(0, i).join("-"));
      }
    });

    const n = pathSegments.length / 2;
    pathSegments.splice(-n);

    const pathName = result.path.join(" / ");
    setSearchValue(pathName);
    setSelectedPath(pathSegments);
    setIsDropDownVisible(false);
}
  };

  const findMatchingResults = (data, searchTerm) => {
    const matchingResults = [];
    const traverse = (children, path) => {
      children.forEach((item) => {
        const currentPath = path ? `${path} / ${returnDisplayName(item?.id.split('-').pop())}` : returnDisplayName(item?.id.split('-').pop());
        if (item.access==1 && returnDisplayName(item?.id).toLowerCase().includes(searchTerm.toLowerCase())) {
          matchingResults.push({ path: currentPath.split(" / "), id: item.id });
        }
        if (item.children) {
          traverse(item.children, currentPath);
        }
      });
    };
    traverse(data, "");
    return matchingResults;
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropDownVisible(false);
      }
      if (cascaderRef.current && !cascaderRef.current.contains(event.target)) {
        if (
          !searchBarRef.current ||
          !searchBarRef.current.contains(event.target)
        ) {
          if (!event.target.classList.contains("cascader-toggle-button")) {
            setIsCascaderVisible(false);
          }
        }
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <div style={{ position: "relative" }}>
      <div ref={cascaderRef}>
        <div style={{ display: "flex", alignchildren: "center" }}>
          <div style={{ position: "relative", display: "flex"  }}>
            <input
              ref={searchBarRef}
              id="search-bar"

              className="form-control"

              type="text"
              value={searchValue}
              onChange={handleSearchInputChange}
              placeholder='Select'
              autocomplete="off"
              style={{
                flexGrow: 1,
                position: "relative",

                
                borderRadius: "4px",
                autocomplete:"off",
                background:'#1c2427',

                color: "white",
                fontSize:'12px',
                width:'17rem',
                padding:'4px',
                outline: "none"
              }}
            />
            <button
              onClick={toggleCascaderVisibility}
              className="cascader-toggle-button"
              style={{
                position: "absolute",
                right: "0px",
                top: "50%",
                transform: "translateY(-50%)",
                backgroundColor: "transparent",
                border: "none",
                color: "white",
                cursor: "pointer",
                fontSize:'small',
              }}
            >
               {isCascaderVisible ? <KeyboardArrowUpOutlinedIcon/> :<KeyboardArrowDownOutlinedIcon/>}
            </button>
            {searchValue && searchResults.length > 0 && isDropDownVisible ? (
              <div
                ref={dropdownRef}
                style={{
                  position: "absolute",
                  top: "calc(100% + 4px)",
                  left: 0,
                  backgroundColor: "#1C2427",
                  border: "1px solid #3E5056",
                  borderRadius: "4px",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  zIndex: 100000000,
                  width: "100%",
                  maxHeight: "200px",
                  overflowY: "auto",
                  fontSize:'small',
                  color: "white",
                }}
              >
                {searchResults.map((result, index) => (
                  <div
                    key={index}
                    style={{
                      padding: "8px",
                      cursor: 'pointer'
                    }}
                    onClick={() => handleSearchResultClick(result)}
                  >
                    {result.path.map((part, index) => {
                      const regex = new RegExp(`(${searchValue})`, "gi");
                      const parts = part.split(regex);
                      return (
                        <span key={index}>
                          {parts.map((p, i) => (
                            <span
                              key={i}
                              style={{
                                color:
                                  p.toLowerCase() === searchValue.toLowerCase()
                                    ? "#D6FF41"
                                    : "white",
                                fontWeight:
                                  p.toLowerCase() === searchValue.toLowerCase()
                                    ? "bold"
                                    : "normal",
                              }}
                            >
                              {p}
                            </span>
                          ))}
                          {index < result.path.length - 1 && " / "}
                        </span>
                      );
                    })}
                  </div>
                ))}
              </div>
            ) : searchValue &&
              searchResults.length === 0 &&
              isDropDownVisible ? (
              <div
                ref={dropdownRef}
                style={{
                  position: "absolute",
                  top: "calc(100% + 4px)",
                  left: 0,
                  backgroundColor: "#1C2427",
                  border: "1px solid #3E5056",
                  borderRadius: "4px",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  zIndex: 100000000,
                  width: "100%",
                  maxHeight: "100px",
                  overflowY: "auto",
                  color: "white",
                  fontSize:'small',
                }}
              >
                No data
              </div>
            ) : null}
          </div>
        </div>

        {isCascaderVisible && (
          <div
            ref={cascaderRef}
            style={{
              position: "absolute",
              backgroundColor: "#1C2427",
            //   borderRadius: "8px",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              zIndex: 1000,
              // border:"1px solid red"
              
              
            }}
          >
            <Cascader
              data={data}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              selectedPath={selectedPath}
              setSelectedPath={setSelectedPath}
              setIdList={setIdList}
              pathName={pathName}
              setGetBrand={setGetBrand}
            />
          </div>
        )}
      </div>
      
    </div>
  );
};

export default CascaderWrapper;