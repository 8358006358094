import React, { useState, useRef } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import MultiCascader from "../Components/MultiCascader";
import configDriven from '../ConfirgDriven';
import { Box } from '@mui/material';
import { useEffect } from 'react';

const AllHeirarchyFilter = (props) => {
  const [reset, setReset] = useState(false);

  const [hierarchyCheckBox, setHierarchyCheckBox] = useState(
    props?.selectedCheckboxes
  );
  const scrollRef= useRef(null);

  console.log("SelectedCheckboxes ", hierarchyCheckBox);
  console.log("props HeirarchyFIlter ", props, "    ", reset);
  const enableFetching = () => {

  };
  const bgColor = (node) => {
    if (props.Chartdata.length === 1) {
      if (props.Chartdata[0].hasOwnProperty(node)) {
        return props.Chartdata[0][node].Filter?.length > 0;
      }
    } else if (props.Chartdata.length === 2) {
      if (props.Chartdata[0].hasOwnProperty(node)) {
        if (props.Chartdata[0][node].Filter?.length > 0) {
          return true;
        } else if (props.Chartdata[1].hasOwnProperty(node)) {
          return props.Chartdata[1][node].Filter?.length > 0;
        }
      }
    }
    return false;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const newItems = Array.from(props.ActiveFilters);
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);
    props.setActiveFilters(newItems);
    console.log("newItems: ", newItems);
    props.updateChartData(newItems);
  };

  // const data = JSON.parse(localStorage.getItem("allBrands"));

  const displayNames = JSON.parse(localStorage.getItem("displayNames"));
  const returnDisplayName = (id) => {
    if (configDriven.show_display_name && displayNames?.hasOwnProperty(id)) {
      return displayNames[id];
    } else {
      return id;
    }
  }

  return (
    <div className={props.OnlyHeirarchyFilter ? "col-xl-12" : props.ActiveChart === "Comparison" ? "col-xl-6" : "col-xl-5"}
      style={{

        borderRadius: '10px',

        marginTop: 0,
        height: '100%'
      }}
    >

      <div className="heirarchy-class" style={{ border: "none" }} ref={scrollRef}>
        <div className="heirarchy-class-div" style={{ position: "relative" }}>
          {/* <div className="row align-items-center" style={{border: "none"}}> */}

        {/* fontSize: '10px', */}
          <span className="m-0 text-center textSize" style={{  color: 'white', whiteSpace: 'nowrap' }}>
            Hierarchy Filter
          </span>


          <p className="m-0 px-1 textSize" style={{ color: "black" }}>
            <i
              className="fas fa-sync-alt"
              style={{
                cursor:
                  !reset
                    ? "default"
                    : "pointer",
                color:
                  !reset
                    ? "black"
                    : "white",
              }}
              onClick={() => {
                if (props.selectedCheckboxes.length != 0) {
                  props.setSelectedCheckboxes([]);
                  // props.setFetchCall(true);
                  // setReset(true);
                }

                // innerWidth 1470
                // innerHeight 799

              }}
              aria-hidden="true"
            ></i>
          </p>

          {(props.ActiveChart === undefined ||
            props.ActiveChart === "Measurement" ||
            props.ActiveChart === "Comparison" ||
            props.ActiveChart === "Trend_Analysis") && (
              <div style={{ paddingLeft: "0.1rem", }}>
                <MultiCascader
                  scrollRef={scrollRef}
                  setHierarchySelectionBreadCrumb={props.setHierarchySelectionBreadCrumb}
                  data={props.HFilters}
                  Chartdata={props.Chartdata}
                  setChartdata={props.setChartdata}
                  setFetchCall={props.setFetchCall}
                  selectedCheckboxes={props.selectedCheckboxes}
                  setSelectedCheckboxes={props.setSelectedCheckboxes}
                  fiexedFilters={props.fiexedFilters}
                  ActiveFilters={props.ActiveFilters}
                  reset={reset}
                  setReset={setReset}
                  fetchData={true}
                  enableFetching={enableFetching}
                />
              </div>
            )}
          <div className='d-flex'>
            {
              props.Fixed.map((item, index) => (
                // style={{ fontSize: '10px !important' }}
                <li key={index}  className={
                  true
                    ? "cm-filter-option-li-filter-active textSize"
                    : "cm-filter-option-li textSize"
                }>
                  <span className="btn cm-drag-btn p-1 mx-2 active filter-class-item" style={{}}>
                  {/* style={{ fontSize: '10px' }}  */}
                    <p className="m-0 text-left px-1 textSize"  >{returnDisplayName(item.id)}</p>
                    <p className="m-0 px-1">
                      {/* <i className="fa fa-minus" aria-hidden="true"></i> */}
                    </p>
                  </span>
                </li>
              ))
            }
          </div>
          <div id="scroller" className="d-flex" style={{ height: 'max-content' }}>

            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="changeable" direction="horizontal">
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    {props.ActiveFilters.map((item, index) => (
                      <Draggable
                        key={item.name}
                        draggableId={item.name.toString()}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                          >
                            <li key={index} className={""} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '35px' }} onClick={() => props.RemoveHeirarchyFilter(item)}>
                              <span className="btn cm-drag-btn p-1 mx-2 active  filter-class-item filter-class-item-active" style={{ height: '20px', }}>
                                {/* fontSize: '10px', */}
                                <p className="m-0 text-left px-1 textSize" style={{
                                   whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }} >{returnDisplayName(item.name)}</p>
                                <p className="m-0 px-1">
                                  <i className="fa fa-minus" aria-hidden="true"></i>
                                </p>
                              </span>
                            </li>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            {
              props.AllFilters.map((item, index) => (
                !props.IsInAllHeirarchyFilters(item) ? (
                  <li key={index} className={""} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '35px' }} onClick={() => props.AddHeirarchyFilter(index)}>
                    <span className="btn cm-drag-btn p-1 mx-2 active  filter-class-item filter-class-item-inactive" >
                      {/*  fontSize: '10px', */}
                      <p className="m-0 text-left px-1 textSize" style={{
                        color: 'white !important', whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}>{returnDisplayName(item.name)}</p>
                      <p className="m-0 px-1" style={{ color: 'white !important' }}>
                        <i className="fa fa-plus" aria-hidden="true"></i>
                      </p>
                    </span>
                  </li>
                ) : null
              ))
            }
          </div>



          {/* {parseInt(props.ShowComp) === 1 &&
          props.SelectedChart === "overview" ? (
            <div
            className="d-flex align-items-center gap-2 heirarchy-class-div"
            style={{ position: "absolute", right: 0, width: 200 }}
          >
            <h6 className="m-0 cm-filter-title">Show Competition</h6>
            <input
              type="checkbox"
              className=""
              name="check"
              style={{marginTop:'-5px',cursor:'pointer'}}
              checked={parseInt(props.ShowCompValue) === 1}
              onChange={(e) => {
                if (parseInt(props.ShowCompValue) === 1) {
                  props.setShowCompValue(0);
                } else {
                  props.setShowCompValue(1);
                }
                props.setFetchCall(true);
              }}
            />
          </div>
          
        ) : null} */}
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default AllHeirarchyFilter;
