import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import debounce from "lodash.debounce";
import Topbar from "../Components/Topbar";
import Menubar from "../Components/Menubar";
import axios from "../Axios";
import axiosDownload from "../Axios"; // download url will be change
import Sunburst from "../Components/Sunburst";
import Loader from "../Components/Loader";
import { useLocation, Link } from "react-router-dom";
import Download from "../images/download-icon.png";
import { Tooltip } from "react-tooltip";
import InfoIcon from "../images/icons8-info.svg";
import { ToastContainer, toast } from "react-toastify";
import TrendChart from "../Components/TrendChart";
import AllHeirarchyFilter from "../Components/HeirarchyFilter";
// import ActiveHeirarchyAndCampaignFilter from "../ActiveHeirarchyAndCampaignFilter";
import ChannelFilter from "../Components/ChannelFilter";
import axiosChartDownload from "../Axios"; // download url will be change
import domtoimage from "dom-to-image";
import LightThemeHexCode from "../Components/LightThemeColorCode";
import useDownloadChart from "../Components/DownloadCharts";
import downloadFlag from "../DownloadFlag";
import appContext from "../context/appContext";
import MultiCal from "../Components/MultiCal";
import configDriven from "../ConfirgDriven";
import DetailsPopUp from "../Components/DetailsPopUp";
import MultiSelect from "../Components/MultiSelect";
import NewMultiCascader from "../Components/NewMultiCascader";
import NewMultiCascader2 from "../Components/NewMultiCascader2";
import TrendChart2 from "../Components/TrendChart2";



import { compareSync } from "bcryptjs";

const Main = () => {
  const refGraphDiv = useRef(null);
  const TrendChartRef = useRef(null);
  const location = useLocation();
  const refSpends = useRef(null);
  const refSales = useRef(null);
  const [SpendsHeight, setSpendsHeight] = useState("");
  const [SalesHeight, setSalesHeight] = useState("");

  const Analyse = JSON.parse(localStorage.getItem("Analyse"));
  // console.log("Analyse: ", Analyse);
  const AnalyticFilter = JSON.parse(localStorage.getItem("AnalyticFilter"));
  const CampaignAnalyticFilter = JSON.parse(
    localStorage.getItem("CampaignAnalyticFilter")
  );
  const [AnalyticFilterFlag, setAnalyticFilterFlag] = useState(true);
  const [loader, setLoader] = useState(false);
  const [EdaTabs, setEdaTabs] = useState([]);
  // console.log("EdaTabs: ", EdaTabs);
  const [SunburstData, setSunburstData] = useState([]);
  const [Summary, setSummary] = useState([]);
  const [MediaSunburstData, setMediaSunburstData] = useState([]);
  const [DecimalPoint, setDecimalPoint] = useState("");
  const [TimeFramesAll, setTimeFramesAll] = useState([]);
  const [timeframe, setTimeframe] = useState("");
  const [FromDatesAll, setFromDatesAll] = useState([]);
  const [ToDatesAll, setToDatesAll] = useState([]);
  const [DefaultStartDate, setDefaultStartDate] = useState("");
  const [DefaultEndDate, setDefaultEndDate] = useState("");
  const [sb1_name, setSb1_name] = useState("");
  const [sb2_name, setSb2_name] = useState("");
  const [sb1_tag, setSb1_tag] = useState("");
  const [sb2_tag, setSb2_tag] = useState("");
  const [Legends, setLegends] = useState([]);
  const [FooterNotes, setFooterNotes] = useState("");
  // console.log("FooterNotes: ", FooterNotes);
  const [FetchCall, setFetchCall] = useState(false);
  const [FetchTrendsFilters, setFetchTrendsFilters] = useState(false);

  const [SelectionAll1, setSelectionAll1] = useState([]);
  const [SelectionAll2, setSelectionAll2] = useState([]);

  const [SelectionAll1Copy, setSelectionAll1Copy] = useState([]);
  const [SelectionAll2Copy, setSelectionAll2Copy] = useState([]);

  const [Selection1, setSelection1] = useState("");
  const [Selection2, setSelection2] = useState("");
  const [TrendChartdata, setTrendChartdata] = useState({});

  const [SelectedChart, setSelectedChart] = useState("");
  // const [TimePeriodError, setTimePeriodError] = useState('');
  const [KpiPerc, setKpiPerc] = useState("");
  const [MediaPerc, setMediaPerc] = useState("");
  const [ShowComp, setShowComp] = useState(0);
  const [ShowCompValue, setShowCompValue] = useState(0);

  const [changeFilter, setChangeFilter] = useState(false);
  const [resetKey, setResetKey] = useState(0); // State used to reset components

  //Channel filters state
  const [CompAllFilters, setCompAllFilters] = useState([]);
  const [CompActiveFilters, setCompActiveFilters] = useState([]);
  const [CFilters, setCFilters] = useState([]);
  const [selectedCheckboxesC, setSelectedCheckboxesC] = useState({});

  const handleReset = () => {
    setResetKey((prevKey) => prevKey + 1); // Increment the reset key
  };

  //Analytical filters state
  const [AllFilters, setAllFilters] = useState([]);
  const [ActiveFilters, setActiveFilters] = useState([]);
  const Fixed = localStorage.getItem("AnalyticFilter")
    ? AnalyticFilter.Fixed
    : [];

  const [Chartdata, setChartdata] = useState({});

  const [GetTabFlag, setGetTabFlag] = useState(true);
  const [FilterTabShow, setFilterTabShow] = useState(false);
  const [ShowSummary, setShowSummary] = useState(0);
  const [ChartHeight, setChartHeight] = useState("");
  const [Notes, setNotes] = useState("");
  const [ResetFilterFlag, setResetFilterFlag] = useState(false);
  const [spendData, setSpendData] = useState([]);
  const [salesData, setSalesData] = useState([]);
  const [selectionData, setSelectionData] = useState([]);
  const [selectionData2, setSelectionData2] = useState([]);



  // chart download states for hook
  const [isDownloadingChart, setIsDownloadingChart] = useState(false);
  const [chartContainerID, setChartContainerID] = useState("");
  const [downloadedImageName, setDownloadedImageName] = useState("");
  const [downloadingChartTitle, setDownloadingChartTitle] = useState("");
  const [isChartdataReady, setIsChartdataReady] = useState(false);


  // Calendar
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(true);
  const [submittedStartDate, setSubmittedStartDate] = useState("");
  const [submittedEndDate, setSubmittedEndDate] = useState("");
  const [submittedTimeFrame, setSubmittedTimeFrame] = useState(timeframe);
  const [isRefreshEnabled, setIsRefreshEnabled] = useState(false);

  // useEffect(()=>{
  //   setSubmittedStartDate(DefaultStartDate);
  // },[DefaultStartDate])
  // useEffect(()=>{
  //   setSubmittedEndDate(DefaultEndDate);
  // },[DefaultEndDate])

  useEffect(() => {
    if (DefaultStartDate !== submittedStartDate || DefaultEndDate !== submittedEndDate) {
      setIsRefreshEnabled(true);
    }

    if (DefaultStartDate === submittedStartDate && DefaultEndDate === submittedEndDate) {
      setIsRefreshEnabled(false);
    }

  }, [DefaultStartDate, DefaultEndDate])

  console.log("submittedStartDate", submittedStartDate);


  const userName = localStorage.getItem("NameSkewb");

  let filteredKeysC = Object.keys(Chartdata).filter(
    (key) => !key.startsWith("H")
  );

  // console.log(sb1_name, sb2_name);

  //chandni
  const [primaryAxis, setPrimaryAxis] = useState([]);
  const [secondaryAxis, setSecondaryAxis] = useState([]);

  const [startdate, setStartdate] = useState("");
  const [enddate, setEnddate] = useState("");

  const [fiexedFilters, setFixedFilters] = useState({});

  const [idsNotBeInCascader1, setIdsNotBeInCascader1] = useState([]);
  const [idsNotBeInCascader2, setIdsNotBeInCascader2] = useState([]);
  const [commonData, setCommonData] = useState([]);

  const [initialSelection1, setInitialSelection1] = useState([]);
  const [initialSelection2, setInitialSelection2] = useState([]);

  // console.log("commonData",commonData);
  // console.log("fiexedFilters: ", fiexedFilters);

  // console.log("primary axis", primaryAxis, "secondary axis", secondaryAxis);

  // useEffect(()=>{
  //   const newData = commonData
  //   console.log("newData: ", commonData, spendData);
  //   setSelectionData(newData);
  // },[spendData])

  const fnDisabledSubmitBtn = () => {
    if (
      DefaultStartDate !== "NaN-NaN-NaN" &&
      DefaultEndDate !== "NaN-NaN-NaN"
    ) {
      return false;
    } else if (
      DefaultStartDate === "NaN-NaN-NaN" ||
      DefaultEndDate === "NaN-NaN-NaN"
    ) {
      return true;
    }
  };

  // update Activefilters on reload in localStorage

  useEffect(() => {
    // Step 1: Retrieve data
    let analyticFilter = JSON.parse(localStorage.getItem("AnalyticFilter"));
    let campaignAnalyticFilter = JSON.parse(
      localStorage.getItem("CampaignAnalyticFilter")
    );
    let chartData = JSON.parse(localStorage.getItem("Chartdata"));

    // Step 2: Extract keys from chartData for matching
    let chartDataKeys = Object.keys(chartData);

    // Step 3: Filter ActiveFilters and CompActiveFilters
    let filteredActiveFilters = analyticFilter.ActiveFilters.filter((filter) =>
      chartDataKeys.includes(filter.name)
    );

    let filteredCompActiveFilters =
      campaignAnalyticFilter.CompActiveFilters.filter((compFilter) =>
        chartDataKeys.includes(compFilter.name)
      );

    // Step 4: Update the filters
    analyticFilter.ActiveFilters = filteredActiveFilters;
    campaignAnalyticFilter.CompActiveFilters = filteredCompActiveFilters;

    // Step 5: Save back to localStorage
    localStorage.setItem("AnalyticFilter", JSON.stringify(analyticFilter));
    localStorage.setItem(
      "CampaignAnalyticFilter",
      JSON.stringify(campaignAnalyticFilter)
    );
  }, []);

  useEffect(() => {
    if (primaryAxis?.length > 0) {
      const filteredData = SelectionAll2.filter(
        (item) => !primaryAxis.includes(item.value)
      );
      setSelectionAll2Copy(filteredData);
    } else {
      setSelectionAll2Copy(SelectionAll2);
    }
  }, [primaryAxis, SelectionAll2]);

  useEffect(() => {
    if (secondaryAxis?.length > 0) {
      const filteredData = SelectionAll1.filter(
        (item) => !secondaryAxis.includes(item.value)
      );
      setSelectionAll1Copy(filteredData);
    } else {
      setSelectionAll1Copy(SelectionAll1);
    }
  }, [secondaryAxis, SelectionAll1]);

  // function removeNodesByIds(data, idsToRemove) {
  //   // Helper function to recursively search and remove nodes by id
  //   function removeNode(data, id) {
  //     // Recursively create a new data structure without the node with the given id
  //     return data
  //       .map((node) => {
  //         if (node.id === id) {
  //           return null; // Skip the node that needs to be removed
  //         }
  //         if (node.children && node.children.length > 0) {
  //           // Recursively filter the children
  //           const newChildren = removeNode(node.children, id);
  //           return { ...node, children: newChildren }; // Create a new node with filtered children
  //         }
  //         return { ...node }; // Copy the node as it is
  //       })
  //       .filter((node) => node !== null); // Remove the null values (nodes to be removed)
  //   }

  //   // Create a new data structure without mutating the original one
  //   let newData = JSON.parse(JSON.stringify(data));

  //   // Iterate over each id in idsToRemove array and remove corresponding nodes
  //   idsToRemove.forEach((id) => {
  //     newData = removeNode(newData, id);
  //   });

  //   return newData; // Return the new modified data
  // }

  function removeNodes(data, idsToRemove) {
    // Create a deep copy of the data to avoid modifying the original
    const newData = JSON.parse(JSON.stringify(data));

    function removeRecursively(node) {
      if (Array.isArray(node)) {
        return node
          .filter((item) => !idsToRemove.includes(item.id))
          .map((item) => ({
            ...item,
            children: removeRecursively(item.children),
          }));
      }
      return node;
    }

    return removeRecursively(newData);
  }

  const [checkedList1, setCheckedList1] = useState([]);
  const [checkedList2, setCheckedList2] = useState([]);

  console.log("salesData hoo", salesData, "checkedList1", checkedList1);
  console.log("spendData hoo", spendData, "checkedList2", checkedList2);

  useEffect(() => {
    const data = removeNodes(commonData, idsNotBeInCascader1);
    // console.log("hello ji",  data, commonData);
    setSelectionData(data);
  }, [commonData, idsNotBeInCascader1]);

  console.log("idsNotBeInCascader1:", idsNotBeInCascader1);

  useEffect(() => {
    const data = removeNodes(commonData, idsNotBeInCascader2);
    // console.log("hello ji",  data, commonData);
    setSelectionData2(data);
  }, [commonData, idsNotBeInCascader2]);

  useEffect(() => {
    setIdsNotBeInCascader2((prevChannelData) => {
      // Create a new set with the previous data and the new checkedList to ensure uniqueness
      const updatedChannelData = new Set([...checkedList1]);

      // Convert the set back to an array and return it
      return Array.from(updatedChannelData);
    });
    // console.log("idsNotBeInCascader2",idsNotBeInCascader2);
    const data2 = removeNodes(commonData, idsNotBeInCascader2);
    // console.log("hello ji",  data, commonData);
    setSelectionData2(data2);
  }, [commonData, salesData]);

  useEffect(() => {
    setIdsNotBeInCascader1((prevChannelData) => {
      // Create a new set with the previous data and the new checkedList to ensure uniqueness
      const updatedChannelData = new Set([...checkedList2]);

      // Convert the set back to an array and return it
      return Array.from(updatedChannelData);
    });
    // console.log("idsNotBeInCascader2",idsNotBeInCascader2);
    const data = removeNodes(commonData, idsNotBeInCascader1);
    // console.log("hello ji",  data, commonData);
    setSelectionData(data);
  }, [commonData, spendData]);

  useDownloadChart(
    downloadedImageName,
    downloadingChartTitle,
    isDownloadingChart,
    chartContainerID,
    Fixed,
    ActiveFilters,
    setLoader,
    Analyse.Brand,
    timeframe,
    DefaultStartDate,
    DefaultEndDate,
    setIsDownloadingChart,
    Analyse.KPI
  );

  useEffect(() => {
    const localAnalyticFilter = JSON.parse(
      localStorage.getItem("StoreAnalyticFilter")
    );
    const localCampaignAnlayticFilter = JSON.parse(
      localStorage.getItem("StoreCampaignAnalyticFilter")
    );

    setAllFilters(localAnalyticFilter.AllFilters);
    setActiveFilters(localAnalyticFilter.ActiveFilters);
    setAnalyticFilterFlag(false);
    setCompActiveFilters(localCampaignAnlayticFilter.CompActiveFilters);
    setCompAllFilters(localCampaignAnlayticFilter.CompAllFilters);
  }, [Analyse.Brand, Analyse.KPI,SelectedChart]);

  const isFirstRender = useRef(true);
  useEffect(() => {
    console.log("channel_hierarcy ", Chartdata);
    
    
    const fetchChannelFilters = async () => {
      filteredKeysC = Object.keys(Chartdata).filter(
        (key) => !key.startsWith("H")
      );
      if (JSON.stringify(Chartdata) !== "{}") {
        try {
        
          const response = await axios.post("channel_hierarcy/", {
            channel_list: filteredKeysC,
            brand: Analyse.Brand,
          });

          const data = response.data;
          console.log("data.data", data.data);
          setCFilters(data.data);
          // localStorage.setItem("channelFilters", JSON.stringify(data.data));
        } catch (error) {
          console.error("Failed to fetch:", error);
        }
      }
    };
    fetchChannelFilters();
  }, [Chartdata]);

  useEffect(() => {
    if (refSpends.current !== null) {
      setSpendsHeight(refSpends.current.clientHeight - 50);
    }
    if (refSales.current !== null) {
      setSalesHeight(refSales.current.clientHeight - 50);
    }
  }, [MediaSunburstData, SunburstData]);

  const [Test, setTest] = useState(false);

  useEffect(() => {
    if (Test) {
      setTest(false);
    }
  }, [Test]);

  useEffect(() => {
    const controller = new AbortController();

    if (GetTabFlag) {
      setLoader(true);
      axios
        .post(
          "get_eda_tabs/",
          { brand: Analyse.Brand, kpi: Analyse.KPI },
          {
            signal: controller.signal,
          }
        )
        .then((response) => {
          if (response.data.error === 1) {
            toast.error(response.data.erroMsg);
            setLoader(false);
          } else {
            setEdaTabs(response.data.data.tabs);
            setSelectedChart(response.data.data.tabs[0].tab_short);
            //setLoader(false);
          }
          setShowComp(response.data.data.show_compare);
          setGetTabFlag(false);
        })
        .catch((data) => {
          setLoader(false);
        });
    }

    return () => {
      controller.abort();
    };
  }, [Analyse.Brand, Analyse.KPI, GetTabFlag]);
  // console.log(ShowComp, "ShowComp")

  useEffect(() => {
    setLoader(true);

    let page = "";
    if (SelectedChart === "overview") {
      page = "eda";
    }
    if (SelectedChart === "trends") {
      page = "trends";
    }
    if (page !== "") {
      axios
        .post("get_timeframes/", {
          page: page,
          brand: Analyse.Brand,
          kpi: Analyse.KPI,
        })
        .then((response) => {
          if (response.data.error === 1) {
            toast.error(response.data.erroMsg);
            setLoader(false);
          } else {
            setTimeFramesAll(response.data.data);
            setTimeframe(response.data.data[0].key);
            // setFromDatesAll(response.data.data[0].fromdates);
            // setToDatesAll(response.data.data[0].todates);
            setDefaultStartDate(response.data.data[0].default_startdate);
            setDefaultEndDate(response.data.data[0].default_enddate);
            setSubmittedStartDate(response.data.data[0].default_startdate);
            setSubmittedEndDate(response.data.data[0].default_enddate);
            setSubmittedTimeFrame(response.data.data[0].key);
            setStartdate(response.data.data[0].start_date);
            setEnddate(response.data.data[0].end_date);
            //setLoader(false);
            setFetchCall(true);
            setFetchTrendsFilters(true);
          }
        })
        .catch((data) => {
          setLoader(false);
        });
    }
  }, [SelectedChart, Analyse.Brand, Analyse.KPI]);
  console.log("ch data", Chartdata);

  useEffect(() => {
    const controller = new AbortController();
    console.log("channel_list ", Object.keys(Chartdata).filter((item) =>
      item.startsWith("C")));
    if (
      FetchTrendsFilters &&
      timeframe !== "" &&
      DefaultStartDate !== "" &&
      DefaultEndDate !== "" &&
      (SelectedChart === "trends" || SelectedChart === "newtrends")
      &&
      JSON?.stringify(Chartdata) !== "{}"
    ) {
      if (
        false
      ) {
        toast.error("Please enter valid time period");
      } else {
        axios
          .post(
            "get_trends_filters/",
            {
              brand: Analyse.Brand,
              kpi: Analyse.KPI,
              timeframe: timeframe,
              starttime: DefaultStartDate,
              endtime: DefaultEndDate,
              channel_list: Object.keys(Chartdata).filter((item) =>
                item.startsWith("C")
              ),
            },
            {
              signal: controller.signal,
            }
          )
          .then((response) => {
            if (response.data.error === 1) {

              toast.error(response.data.erroMsg);
              // toast.error("something went wromg");
              setLoader(false);
            } else {
              // setSelectionAll1(response.data.data);
              // setSelectionAll2(response.data.data);
              // setSelectionAll1Copy(response.data.data);
              // setSelectionAll2Copy(response.data.data);
              // if (primaryAxis.length === 0 && secondaryAxis.length === 0)
              //   setPrimaryAxis([response.data.data[0].value]);
              // if (secondaryAxis.length === 0 && primaryAxis.length === 0)
              //   setSecondaryAxis([response.data.data[1].value]);

              // setSelection1(response.data.data[0].value);
              setShouldRunEffect(true);
              setCommonData(response?.data?.data);
              // setSelectionData(response?.data?.data);
              // setSelectionData2(response?.data?.data);

              // console.log("hello",response.data.default_primaryaxis);
              setSalesData(response?.data?.default_primaryaxis);
              setIdsNotBeInCascader2(response?.data?.default_primaryaxis);
              setInitialSelection1(response?.data?.default_primaryaxis);
              setSpendData(response?.data?.default_secondaryaxis);
              setCheckedList2(response?.data?.default_secondaryaxis);
              setCheckedList1(response?.data?.default_primaryaxis);

              setIdsNotBeInCascader1(response?.data?.default_secondaryaxis);
              setInitialSelection2(response?.data?.default_secondaryaxis);

              // console.log("selectionData",selectionData);
              setFetchTrendsFilters(false);
              // //setLoader(false);
            }
          })
          .catch((data) => {
            console.log("error ", data);
            // setLoader(false);
          });
      }
    }

    return () => {
      controller.abort();
    };
  }, [
    // Analyse,
    timeframe,
    DefaultStartDate,   
    DefaultEndDate,
    // FromDatesAll,
    // ToDatesAll,
    SelectedChart,
    FetchTrendsFilters,
    Chartdata,
  ]);

  useEffect(() => {
    const controller = new AbortController();

    console.log("get sunburst befor chartData resultt outside", Chartdata);
    // console.log("useeffect called...............................!");
    if (FetchCall && SelectedChart === "overview") {
      console.log("get sunburst befor chartData resultt", Chartdata);
      // console.log("FetchCall: ", FetchCall);
      // console.log("SelectedChart: ", SelectedChart);
      // console.log("timeframe: ", timeframe);
      // console.log("DefaultStartDate: ", DefaultStartDate);
      // console.log("DefaultEndDate: ", DefaultEndDate);
      // console.log("Chartdata inside useeffect: ", Chartdata);
      if (
        timeframe !== "" &&
        DefaultStartDate !== "" &&
        DefaultEndDate !== "" &&
        // JSON.stringify(Chartdata) !== "{}"
        isChartdataReady
      ) {
        // if (FromDatesAll.indexOf(DefaultStartDate) > ToDatesAll.indexOf(DefaultEndDate)) {
        //   toast.error('Please enter valid time period');
        //   setFetchCall(false)
        // } else {
        setLoader(true);

        setSunburstData([]);
        setMediaSunburstData([]);

        axios
          .post(
            "get_sunburst/",
            {
              brand: Analyse.Brand,
              kpi: Analyse.KPI,
              timeframe: timeframe,
              starttime: DefaultStartDate,
              endtime: DefaultEndDate,
              chartdata: Chartdata,
              compkey: ShowCompValue,
              theme: localStorage.getItem("CurrentTheme"),
              LightThemeColorCode: [
                localStorage.getItem("ColorCode"),
                LightThemeHexCode[localStorage.getItem("ColorCode")],
              ],
            },
            // {
            //   signal: controller.signal,
            // }
          )
          .then((response) => {
            if (response.data.error === 1) {
              // toast.error(response.data.erroMsg);
              setLoader(false);
            } else {
              console.log("notes ", response);
              setSunburstData(response.data.chart.kpi);
              setMediaSunburstData(response.data.chart.media);
              setDecimalPoint(response.data.chart.decimal);
              setMediaPerc(response.data.chart.mediaper);
              setKpiPerc(response.data.chart.kpiper);
              setTest(true);
              setSummary(response.data.chart.summary);
              setShowSummary(response.data.chart.show_summary);
              setLegends(response.data.chart.Legends);
              setSb1_name(response.data.chart.sb1_name);
              setSb2_name(response.data.chart.sb2_name);
              setSb1_tag(response.data.chart.sb1_tag);
              setSb2_tag(response.data.chart.sb2_tag);
              setNotes(response.data.notes);
              setFooterNotes(response.data.chart.footernotes);
              // setLoader(false);
            }
            setFetchCall(false);
            setChangeFilter(false);
            // setIsChartdataReady(false);
          })
          .catch((data) => {
            setLoader(false);
          });
        // }
      }
    }
    // return () => {
    //   controller.abort();
    // };
  }, [
    Analyse.Brand,

    DefaultStartDate,
    DefaultEndDate,
    timeframe,
    FetchCall,
    // FromDatesAll,
    // ToDatesAll,
    SelectedChart,
    Chartdata,
    ShowCompValue,
    isChartdataReady,
  ]);

  const [fetchMixChart2, setFetchMixChart2] = useState({});
  // console.log("fetchMixChart2", fetchMixChart2);
  // console.log("initialSelection1",initialSelection1);
  const [shouldRunEffect, setShouldRunEffect] = useState(false);
  console.log("spendsData:1", spendData);

  useEffect(() => {
   
    if (shouldRunEffect) {
      console.log("get_trends",DefaultStartDate," ",DefaultEndDate,Chartdata,"  ", salesData,
        spendData,FetchCall," ",SelectedChart);
      // console.log("salesData inside useEffect", salesData);
      if (
        FetchCall &&
        SelectedChart === "trends" &&
        JSON.stringify(Chartdata) !== "{}"
      ) {
        if (
          timeframe !== "" &&
          DefaultStartDate !== "" &&
          DefaultEndDate !== "" &&
          !(spendData.length == 0 &&
            salesData.length == 0)
        ) {
          console.log("get_trends",DefaultStartDate," ",DefaultEndDate,Chartdata,"  ", salesData,
            spendData,);
          setLoader(true);
          setSunburstData([]);
          setMediaSunburstData([]);
          axios
            .post("get_trends/", {
              brand: Analyse.Brand,
              kpi: Analyse.KPI,
              timeframe: timeframe,
              chartdata: Chartdata,
              theme: localStorage.getItem("CurrentTheme"),
              LightThemeColorCode: [
                localStorage.getItem("ColorCode"),
                LightThemeHexCode[localStorage.getItem("ColorCode")],
              ],
              selection: [Selection1, Selection2],
              primary_axis: salesData,
              secondary_axis: spendData,
              starttime: DefaultStartDate,
              endtime: DefaultEndDate,
            })
            .then((response) => {
              if (response?.data?.error === 1) {
                toast.error(response.data.erroMsg);
                setLoader(false);
              } else {
                setFetchMixChart2(response?.data?.chart);
                // //setLoader(false);
                setFooterNotes(response.data.chart.footernotes);
              }
              setFetchCall(false);
            })
            .catch((data) => {
              // setLoader(false);
            });
        }
      }
    }
  }, [
    shouldRunEffect, // Add the flag to the dependency array
    // Analyse.Brand,
    // Analyse.KPI,
    DefaultStartDate,
    DefaultEndDate,
    // timeframe,
    FetchCall,
    // FromDatesAll,
    // ToDatesAll,
    // Selection1,
    // Selection2,
    SelectedChart,
    Chartdata,
    // primaryAxis,
    // secondaryAxis,
    salesData,
    spendData,
  ]);

  useEffect(() => {
    console.log("newTrends api ", FetchCall, "  ", SelectedChart, "   ", shouldRunEffect, spendData, " ", salesData);
    if (shouldRunEffect) {
      // console.log("salesData inside useEffect", salesData);

      if (
        FetchCall &&
        SelectedChart === "newtrends" &&
        JSON.stringify(Chartdata) !== "{}"
      ) {
        if (
          timeframe !== "" &&
          DefaultStartDate !== "" &&
          DefaultEndDate !== "" &&
          salesData.length > 0

        ) {
          setLoader(true);
          setSunburstData([]);
          setMediaSunburstData([]);
          axios
            .post("get_trends_new/", {
              brand: Analyse.Brand,
              kpi: Analyse.KPI,
              timeframe: timeframe,
              chartdata: Chartdata,
              theme: localStorage.getItem("CurrentTheme"),
              LightThemeColorCode: [
                localStorage.getItem("ColorCode"),
                LightThemeHexCode[localStorage.getItem("ColorCode")],
              ],
              selection: [Selection1, Selection2],
              primary_axis: salesData,
              secondary_axis: spendData,
              starttime: DefaultStartDate,
              endtime: DefaultEndDate,
            })
            .then((response) => {
              if (response?.data?.error === 1) {
                toast.error(response.data.erroMsg);
                setLoader(false);
              } else {
                setFetchMixChart2(response?.data?.chart);
                //setLoader(false);
              }
              setFetchCall(false);
            })
            .catch((data) => {
              setLoader(false);
            });
        }
      }
    }
  }, [
    shouldRunEffect, // Add the flag to the dependency array
    Analyse.Brand,
    Analyse.KPI,
    DefaultStartDate,
    DefaultEndDate,
    timeframe,
    FetchCall,
    // FromDatesAll,
    // ToDatesAll,
    Selection1,
    Selection2,
    SelectedChart,
    Chartdata,
    primaryAxis,
    secondaryAxis,
    salesData,
    spendData,
  ]);

  // Ref to keep track of last modified array
  // const lastModifiedArray = useRef(null);

  // useEffect(() => {
  //   if (shouldRunEffect) {
  //     if (
  //       FetchCall &&
  //       SelectedChart === "trends" &&
  //       JSON.stringify(Chartdata) !== "{}"
  //     ) {
  //       if (
  //         timeframe !== "" &&
  //         DefaultStartDate !== "" &&
  //         DefaultEndDate !== "" &&
  //         salesData.length > 0 &&
  //         spendData.length > 0
  //       ) {
  //         setLoader(true);

  //         axios
  //           .post("get_trends/", {
  //             brand: Analyse.Brand,
  //             kpi: Analyse.KPI,
  //             timeframe: timeframe,
  //             chartdata: Chartdata,
  //             theme: localStorage.getItem("CurrentTheme"),
  //             LightThemeColorCode: [
  //               localStorage.getItem("ColorCode"),
  //               LightThemeHexCode[localStorage.getItem("ColorCode")],
  //             ],
  //             selection: [Selection1, Selection2],
  //             primary_axis: salesData,
  //             secondary_axis: spendData,
  //             starttime: DefaultStartDate,
  //             endtime: DefaultEndDate,
  //           })
  //           .then((response) => {
  //             if (response?.data?.error === 1) {
  //               toast.error(response.data.erroMsg);
  //               if (lastModifiedArray.current === "salesData") {
  //                 setSalesData((prevData) => prevData.slice(0, -1)); // Remove last element
  //               } else if (lastModifiedArray.current === "spendData") {
  //                 setSpendData((prevData) => prevData.slice(0, -1)); // Remove last element
  //               }
  //               //setLoader(false);
  //             } else {
  //               setFetchMixChart2(response?.data?.data);
  //               //setLoader(false);
  //             }
  //             setFetchCall(false);
  //           })
  //           .catch(() => {
  //             //setLoader(false);
  //           });
  //       }
  //     }
  //   }
  // }, [
  //   shouldRunEffect,
  //   Analyse.Brand,
  //   Analyse.KPI,
  //   DefaultStartDate,
  //   DefaultEndDate,
  //   timeframe,
  //   FetchCall,
  //   FromDatesAll,
  //   ToDatesAll,
  //   Selection1,
  //   Selection2,
  //   SelectedChart,
  //   Chartdata,
  //   salesData,
  //   spendData,
  // ]);

  // useEffect(()=>{
  //   lastModifiedArray.current = "salesData";
  // },[salesData])

  // useEffect(()=>{
  //   lastModifiedArray.current = "spendData";
  // },[spendData])

  // // Add items to salesData or spendData
  // const addDataToSalesData = (newData) => {
  //   setSalesData((prevData) => {
  //     lastModifiedArray.current = "salesData";
  //     return [...prevData, newData];
  //   });
  // };

  // const addDataToSpendData = (newData) => {
  //   setSpendData((prevData) => {
  //     lastModifiedArray.current = "spendData";
  //     return [...prevData, newData];
  //   });
  // };

  useEffect(() => {
    setFetchCall(true);
    setFetchTrendsFilters(true);
    setGetTabFlag(true);
    setSelection1("");
    setSelection2("");
  }, [Analyse.Brand, Analyse.KPI]);

  const onChangeHandler = (e) => {
    const value = e.target.value;
    for (var i = 0; i < TimeFramesAll.length; i++) {
      if (TimeFramesAll[i].key === value) {
        // setFromDatesAll(TimeFramesAll[i].fromdates);
        // setToDatesAll(TimeFramesAll[i].todates);
        setDefaultStartDate(TimeFramesAll[i].default_startdate); // added this line by Naeem
        setDefaultEndDate(TimeFramesAll[i].default_enddate); // added this line by Naeem
      }
    }
    setTimeframe(value);
    setFetchCall(true);
  };

  const DownloadData1 = () => {
    setLoader(true);
    axiosDownload
      .post("eda/", {
        brand: Analyse.Brand,
        kpi: Analyse.KPI,
        timeframe: timeframe,
        starttime: DefaultStartDate,
        endtime: DefaultEndDate,
        selection: [sb1_name, sb2_name],
        // chartdata: {"H0":{"selection":[],"Filter":["loreal"]},"H1":{"selection":[],"Filter":[]}},
        chartdata: Chartdata,
        theme: localStorage.getItem("CurrentTheme"),
        compkey: ShowCompValue,
        download_type: "",
      })
      .then((response) => {
        var link = document.createElement("a");
        link.target = "_blank";
        link.href = response.data.data;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoader(false);
      })
      .catch((data) => {
        setLoader(false);
      });
  };

  const DownloadData2 = () => {
    // console.log("DownloadData2");
    setLoader(true);
    axiosDownload
      .post("trends/", {
        brand: Analyse.Brand,
        kpi: Analyse.KPI,
        timeframe: timeframe,
        starttime: DefaultStartDate,
        endtime: DefaultEndDate,
        selection: [Selection1, Selection2],
        // chartdata: {"H0":{"selection":[],"Filter":["loreal"]},"H1":{"selection":[],"Filter":[]}},
        chartdata: Chartdata,
        theme: localStorage.getItem("CurrentTheme"),
        compkey: ShowCompValue,
        download_type: "",
      })
      .then((response) => {
        var link = document.createElement("a");
        link.target = "_blank";
        link.href = response.data.data;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoader(false);
      })
      .catch((data) => {
        setLoader(false);
      });
  };

  const downloadData = () => {
    if (SelectedChart === "overview") {
      DownloadData1();
    } else if (SelectedChart === "trends") {
      DownloadData2();
    }
  };

  const downloadPDF1 = async () => {
    setLoader(true);
    const chartContainers = [
      document.getElementById("media_sunburst_chart"),
      document.getElementById("sunburst_chart"),
    ];
    if (chartContainers.every((container) => container !== null)) {
      try {
        // setLoader(true);
        const dataUrls = await Promise.all(
          chartContainers.map((container) => domtoimage.toPng(container))
        );
        // setLoader(true);
        const response = await axiosDownload.post("eda/", {
          brand: Analyse.Brand,
          kpi: Analyse.KPI,
          timeframe: timeframe,
          starttime: DefaultStartDate,
          endtime: DefaultEndDate,
          selection: [sb1_name, sb2_name],
          chartdata: Chartdata,
          theme: localStorage.getItem("CurrentTheme"),
          compkey: ShowCompValue,
          download_type: "PDF",
        });
        // //setLoader(false);
        // console.log("excel: ", response.data);
        const pdfResponse = await axiosChartDownload.post(
          "download_pdf/create/",
          {
            images: dataUrls.map((dataUrl) => dataUrl.split(",")[1]),
            document: {
              table1_data: response.data.table1_data,
              table2_data: response.data.table2_data,
              // title: "Business Landscape",
              // textcontent:
              // "We take digital marketing analytics one step further with unparalleled prescriptive deep-dive. Our innovative end-to-end analytics solution gives you relevant data & helps in strategy formulation & planning at the lowest cuts.",
              // header_content: "Loreal Haircolor Garnier- Sales",
              footer_content: "Private & Confidential",
              // date: "Jan 2019-Dec 2019",
              table_heading1: "Spends",
              image_heading1: "Spends",
              table_heading2: "Sales",
              image_heading2: "Sales",
              username: userName,
              timeframe: timeframe,
              start_date: DefaultStartDate,
              end_date: DefaultEndDate,
              titlename: "Business Landscape",
              category: "",
              // subcategory: "Overall",
              brand: Analyse.Brand,
              kpi: Analyse.KPI,
              tabname: "eda",
              chartdata: Chartdata,
            },
          }
        );
        if (pdfResponse.data.error === 1) {
          toast.error(pdfResponse.data.erroMsg);
          setLoader(false);
        } else {
          // console.log("response pdf: ", pdfResponse.data);
          setLoader(false);
          var link = document.createElement("a");
          link.target = "_blank";
          link.href = pdfResponse.data.pdf_url;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (error) {
        setLoader(false);
        console.error("Error while generating pdf:", error);
      }
    }
  };

  const downloadPDF2 = async () => {
    setLoader(true);
    const chartContainers = [document.getElementById("trends_chart")];
    if (chartContainers.every((container) => container !== null)) {
      try {
        // setLoader(true);
        const dataUrls = await Promise.all(
          chartContainers.map((container) => domtoimage.toPng(container))
        );
        // setLoader(true);
        const response = await axiosDownload.post("trends/", {
          brand: Analyse.Brand,
          kpi: Analyse.KPI,
          timeframe: timeframe,
          starttime: DefaultStartDate,
          endtime: DefaultEndDate,
          selection: [Selection1, Selection2],
          chartdata: Chartdata,
          theme: localStorage.getItem("CurrentTheme"),
          compkey: ShowCompValue,
          download_type: "PDF",
        });
        // //setLoader(false);
        // console.log("excel: ", response.data);
        const pdfResponse = await axiosChartDownload.post(
          "download_pdf/create/",
          {
            images: dataUrls.map((dataUrl) => dataUrl.split(",")[1]),
            document: {
              table1_data: response.data.table1_data,
              // title: "ROI Chart",
              // textcontent:
              // "We take digital marketing analytics one step further with unparalleled prescriptive deep-dive. Our innovative end-to-end analytics solution gives you relevant data & helps in strategy formulation & planning at the lowest cuts.",
              // header_content: "Loreal Haircolor Garnier- Sales",
              titlename: "Business Landscape",
              footer_content: "Private & Confidential",
              table_heading1: "Trend Analysis",
              image_heading1: "Trend Analysis",
              username: userName,
              timeframe: timeframe,
              start_date: DefaultStartDate,
              end_date: DefaultEndDate,
              category: "",
              chartdata: Chartdata,
              tabname: "eda",
              brand: Analyse.Brand,
              kpi: Analyse.KPI,
            },
          }
        );
        if (pdfResponse.data.error === 1) {
          toast.error(pdfResponse.data.erroMsg);
          setLoader(false);
        } else {
          // console.log("response pdf: ", pdfResponse.data);
          setLoader(false);
          var link = document.createElement("a");
          link.target = "_blank";
          link.href = pdfResponse.data.pdf_url;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (error) {
        setLoader(false);
        console.error("Error while generating pdf:", error);
      }
    }
  };

  const downloadPDF = () => {
    if (SelectedChart === "overview") {
      downloadPDF1();
    } else if (SelectedChart === "trends") {
      downloadPDF2();
    }
  };

  useEffect(() => {

    // if (changeFilter === false) {
      const chartdata = JSON.parse(localStorage.getItem("Chartdata"));
      console.log("resultt first time ", chartdata);
      if (JSON.stringify(chartdata) !== JSON.stringify(Chartdata)){
      // setChartdata(chartdata);
      }
      setChartdata(chartdata);
      setIsChartdataReady(true);
      setFetchCall(true);
      setSelectedCheckboxes([]);
      setSelectedCheckboxesC([]);
      console.log("resultt first time before ");
    // }
  }, [Analyse.Brand, Analyse.KPI, SelectedChart]);

  // useEffect(()=>{
  //   setChangeFilter(false);
  // },[SelectedChart])

  const AddHeirarchyFilter = (index) => {
    const ActiveFiltersTemp = JSON.parse(JSON.stringify(ActiveFilters));
    console.log("ActiveFiltersTemp", ActiveFiltersTemp);
    let filter = AllFilters[index];
    console.log("ActiveFiltersTemp filter", filter);
    // Assuming you want to select the first filter by default as in AddCompFilter
    // filter.Selected = [filter.filters[0]];

    ActiveFiltersTemp.push(filter);
    setActiveFilters(ActiveFiltersTemp);
    setFilterTabShow(true);

    // Update the localStorage with the new filter data
    const AnalyticFilterTemp = {
      AllFilters: AllFilters,
      Fixed: Fixed,
      ActiveFilters: ActiveFiltersTemp,
    };

    localStorage.setItem("AnalyticFilter", JSON.stringify(AnalyticFilterTemp));
    updateChartData();
  };

  const onChangeFilter = (selected, index, data) => {
    const ActiveFiltersTemp = data
      ? data
      : JSON.parse(JSON.stringify(ActiveFilters));

    ActiveFiltersTemp[index].Selected = selected;

    let next_level_arr = [];
    for (var j = 0; j < ActiveFiltersTemp.length; j++) {
      if (j > index) {
        next_level_arr.push(ActiveFiltersTemp[j].level);
      }
    }

    const next_level = next_level_arr.toString().replaceAll(",", ";");

    setLoader(true);

    var selection_value = [];
    for (var i = 0; i < selected.length; i++) {
      selection_value.push(selected[i].value);
    }

    const selection = selection_value.toString().replaceAll(",", ";");
    axios
      .post("hirarchy_dropdown_child_options/", {
        hirarchy: Analyse.Brand,
        selection: selection !== "all" ? selection : "",
        next_level: next_level,
      })

      .then((response) => {
        var data = [...ActiveFiltersTemp];
        for (var i = 0; i < ActiveFiltersTemp.length; i++) {
          if (response.data.data[data[i].level]) {
            data[i]["Selected"] = [];
            data[i]["filters"] = response.data.data[data[i].level];
          }
        }
        setActiveFilters(data);

        //Set analytical filters data in localstorage
        const AnalyticFilterTemp = {
          AllFilters: AllFilters,
          Fixed: Fixed,
          ActiveFilters: data,
        };

        localStorage.setItem(
          "AnalyticFilter",
          JSON.stringify(AnalyticFilterTemp)
        );

        //setLoader(false);
      })
      .catch((data) => {
        setLoader(false);
      });
  };

  const RemoveHeirarchyFilter = (item) => {
    const ActiveFiltersCompTemp = JSON.parse(JSON.stringify(ActiveFilters));
    for (let i = 0; i < ActiveFiltersCompTemp.length; i++) {
      if (ActiveFiltersCompTemp[i].name === item.name) {
        ActiveFiltersCompTemp.splice(i, 1);
        setActiveFilters(ActiveFiltersCompTemp);
      }
    }
    setFilterTabShow(true);

    //Set analytical filters data in localstorage
    const AnalyticFilterTemp = {
      AllFilters: AllFilters,
      Fixed: Fixed,
      ActiveFilters: ActiveFiltersCompTemp,
    };

    localStorage.setItem("AnalyticFilter", JSON.stringify(AnalyticFilterTemp));
    updateChartData();
  };

  //CHANNEL FILTERS
  const AddCompFilter = (index) => {
    const ActiveFiltersCompTemp = JSON.parse(JSON.stringify(CompActiveFilters));
    let filter = CompAllFilters[index];
    filter.Selected = [filter.filters[0]];

    ActiveFiltersCompTemp.push(filter);
    setCompActiveFilters(ActiveFiltersCompTemp);
    setFilterTabShow(true);

    //Set campaign filter data in localstorage
    const CampaignAnalyticFilter = {
      CompAllFilters: CompAllFilters,
      CompActiveFilters: ActiveFiltersCompTemp,
    };

    localStorage.setItem(
      "CampaignAnalyticFilter",
      JSON.stringify(CampaignAnalyticFilter)
    );
    updateChartData();
  };

  const RemoveCompFilter = (item) => {
    const ActiveFiltersCompTemp = JSON.parse(JSON.stringify(CompActiveFilters));
    for (let i = 0; i < ActiveFiltersCompTemp.length; i++) {
      if (ActiveFiltersCompTemp[i].name === item.name) {
        ActiveFiltersCompTemp.splice(i, 1);
        setCompActiveFilters(ActiveFiltersCompTemp);
      }
    }
    setFilterTabShow(true);

    //Set campaign filter data in localstorage
    const CampaignAnalyticFilter = {
      CompAllFilters: CompAllFilters,
      CompActiveFilters: ActiveFiltersCompTemp,
    };

    localStorage.setItem(
      "CampaignAnalyticFilter",
      JSON.stringify(CampaignAnalyticFilter)
    );
    updateChartData();
  };

  const IsInAllCompFilters = (item) => {
    for (let i = 0; i < CompActiveFilters.length; i++) {
      if (CompActiveFilters[i].name === item.name) {
        return true;
      }
    }
    return false;
  };

  const updateChartData = (changeFilter = [], channelFilter = []) => {
    // Initialize the result object
    const result = {};
    console.log("resultt updatedChartData");
    const filterdata = JSON.parse(localStorage.getItem("AnalyticFilter"));
    const ChannelFilterData = JSON.parse(
      localStorage.getItem("CampaignAnalyticFilter")
    );

    // Populate the result object with Fixed filters
    filterdata.Fixed.forEach((item) => {
      result[item.id] = {
        selection: [],
        Filter: [item.value],
      };
    });

    if (changeFilter.length === 0) {
      // Populate the result object with AllFilters that are not in Fixed
      filterdata.ActiveFilters.forEach((item) => {
        if (!result[item.name]) {
          result[item.name] = {
            selection: [],
            Filter: [],
          };
        }
      });
    } else {
      changeFilter.forEach((item) => {
        if (!result[item.name]) {
          result[item.name] = {
            selection: [],
            Filter: [],
          };
        }
      });
    }

    if (channelFilter.length === 0) {
      // Populate the result object with AllFilters that are not in Fixed
      ChannelFilterData.CompActiveFilters.forEach((item) => {
        if (!result[item.name]) {
          result[item.name] = {
            selection: [],
            Filter: [],
          };
        }
      });
    } else {
      channelFilter.forEach((item) => {
        if (!result[item.name]) {
          result[item.name] = {
            selection: [],
            Filter: [],
          };
        }
      });
    }
    console.log("resultt fun ", result, " ", Chartdata);
    const localChartData=localStorage.getItem('Chartdata');
    const numberOfKeyInChartData = Object.keys(localChartData).length;
    const numberOfKeyResult = Object.keys(result).length;
    
    
    // if (numberOfKeyInChartData===numberOfKeyResult){
    setChartdata(result);

    // }
    setFetchCall(true);
    
    setChangeFilter(true);
    setSelectedCheckboxes([]);
    setSelectedCheckboxesC([]);
    setFetchTrendsFilters(true);
  };

  const IsInAllHeirarchyFilters = (item) => {
    for (let i = 0; i < ActiveFilters.length; i++) {
      if (ActiveFilters[i].name === item.name) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    if (refGraphDiv.current !== null) {
      setChartHeight(refGraphDiv.current.clientHeight);
    }
  }, [SelectedChart, FetchCall]);

  const [mixChartData, setMixChartData] = useState([]);

  const [HFilters, setHFilters] = useState([]);
  // console.log("HFilters: ", HFilters);
  // console.log("Chartdata: ", Object.keys(Chartdata));

  const [selectedCheckboxes, setSelectedCheckboxes] = useState({});
  // console.log("selectedCheckboxes: ", selectedCheckboxes);

  useEffect(() => {
    const fetchHierarchyFilters = async () => {
      if (JSON.stringify(Chartdata) !== "{}") {
        // setHFilters([]);
        const filteredKeysH = Object.keys(Chartdata).filter(
          (key) => !key.startsWith("C")
        );
        try {
          const response = await axios.post("hieracrhy_data/", {
            hierarchy_list: filteredKeysH,
            brand: Analyse.Brand,
          });

          const data = response.data;
          // console.log(data);
          setHFilters(data.data);
          setFixedFilters(data.filter);

          // localStorage.setItem("hierarchyFilters", JSON.stringify(data.data));
        } catch (error) {
          console.error("Failed to fetch:", error);
        }
      }
    };
    fetchHierarchyFilters();
  }, [Chartdata]);

  const initialSelectedCheckboxes = initialSelection2.reduce((acc, id) => {
    // Mark the current ID as true
    acc[id] = true;

    // Split the ID into parts based on the '-' delimiter
    const parts = id.split("-");

    // Mark parents as "interminate"
    let parentId = "";
    for (let i = 0; i < parts.length - 1; i++) {
      parentId = parentId ? `${parentId}-${parts[i]}` : parts[i];
      // Only mark it as "interminate" if it's not already marked as true
      if (!acc[parentId]) {
        acc[parentId] = "indeterminate";
      }
    }

    return acc;
  }, {});

  // console.log("initial",initialSelectedCheckboxes);

  // const [selectedCheckboxes1, setSelectedCheckboxes1] = useState(initialSelectedCheckboxes1);

  const initialSelectedCheckboxes1 = initialSelection1.reduce((acc, id) => {
    // Mark the current ID as true
    acc[id] = true;

    // Split the ID into parts based on the '-' delimiter
    const parts = id.split("-");

    // Mark parents as "interminate"
    let parentId = "";
    for (let i = 0; i < parts.length - 1; i++) {
      parentId = parentId ? `${parentId}-${parts[i]}` : parts[i];
      // Only mark it as "interminate" if it's not already marked as true
      if (!acc[parentId]) {
        acc[parentId] = "indeterminate";
      }
    }
    return acc;

  }, {});

  console.log("initialSelectedCheckboxes1: ", initialSelectedCheckboxes1);

  const [selectedCheckboxes1, setSelectedCheckboxes1] = useState(initialSelectedCheckboxes1);
  console.log("selectedCheckboxes1: ", selectedCheckboxes1);

  useEffect(() => {
    setSelectedCheckboxes1(initialSelectedCheckboxes1)
  }, [initialSelection1]);



  const [selectedCheckboxes2, setSelectedCheckboxes2] = useState(
    initialSelectedCheckboxes
  );

  useEffect(() => {
    setSelectedCheckboxes2(initialSelectedCheckboxes)
  }, [initialSelection2]);

  const [keyValue, setKeyValue] = useState(0);

  // useEffect(()=>{
  //   setSelectedCheckboxes2(initialSelectedCheckboxes);
  //   setSelectedCheckboxes1(initialSelectedCheckboxes1);
  //   setKeyValue( prev => prev +1);
  // },[Chartdata])

  return (
    <>
      {loader ? <Loader /> : null}
      <ToastContainer theme="colored" />
      <Topbar />
      <Menubar />
      <div className="main-content">
        <div className="page-content">
          {/* change 2 class add */}
          <div className="filter-class">
            <AllHeirarchyFilter
              key={Chartdata}
              OnlyHeirarchyFilter={false}
              Brand={Analyse.Brand}
              Fixed={Fixed}
              ActiveFilters={ActiveFilters}
              AllFilters={AllFilters}
              AddHeirarchyFilter={AddHeirarchyFilter}
              RemoveHeirarchyFilter={RemoveHeirarchyFilter}
              IsInAllHeirarchyFilters={IsInAllHeirarchyFilters}
              Chartdata={Chartdata}
              setChartdata={setChartdata}
              setFetchCall={setFetchCall}
              HFilters={HFilters}
              selectedCheckboxes={selectedCheckboxes}
              setSelectedCheckboxes={setSelectedCheckboxes}
              SelectedChart={SelectedChart}
              ShowCompValue={ShowCompValue}
              setShowCompValue={setShowCompValue}
              ShowComp={ShowComp}
              fiexedFilters={fiexedFilters}
              updateChartData={updateChartData}
              setActiveFilters={setActiveFilters}
            />

            <ChannelFilter
              key={filteredKeysC}
              CompActiveFilters={CompActiveFilters}
              CompAllFilters={CompAllFilters}
              AddCompFilter={AddCompFilter}
              RemoveCompFilter={RemoveCompFilter}
              IsInAllCompFilters={IsInAllCompFilters}
              Chartdata={Chartdata}
              setChartdata={setChartdata}
              setFetchCall={setFetchCall}
              setCompActiveFilters={setCompActiveFilters}
              CFilters={CFilters}
              updateChartData={updateChartData}
              selectedCheckboxesC={selectedCheckboxesC}
              setSelectedCheckboxesC={setSelectedCheckboxesC}
              SelectedChart={SelectedChart}
            />
          </div>

          <div className="container-fluid main-content-w pt-1">
            <div className="row" >

              <div
                className={
                  parseInt(ShowSummary) === 1 ? "col-xl-9" : "col-xl-12"
                }
              >
                {/* <TestSun /> */}
                <div
                  className="card card-min-height optimizer-table-height"
                  ref={refGraphDiv}
                  style={{ height: 'max-content', padding: '10px' }}
                >
                  <div
                    className="card-body brand-card pt-2"
                    style={{ background: 'transparent', height: `${window.innerHeight < 850 ? window.innerHeight * 0.82 : window.innerHeight * 0.87}px` }}
                  >
                    <div
                      className="row align-items-center ce-filter justify-content-between"
                      style={{}}
                    >
                      {true && (
                        <div
                          className="col-sm-3 col-md-10 col-lg-2 d-flex align-items-center justify-content-space-between gap-3"
                          style={{ border: "none" }}
                        >
                          {DefaultStartDate &&
                            DefaultEndDate &&
                            startdate &&
                            enddate && (
                              <MultiCal
                                // key={refreshKey}
                                setDefaultStartDate={setDefaultStartDate}
                                setDefaultEndDate={setDefaultEndDate}
                                DefaultStartDate={DefaultStartDate}
                                DefaultEndDate={DefaultEndDate}
                                rangeStartDate={startdate}
                                rangeEndDate={enddate}
                                setTimeframe={setTimeframe}
                                onChangeHandler={onChangeHandler}
                                TimeFramesAll={TimeFramesAll}
                                timeframe={timeframe}
                                SelectedChart={SelectedChart}
                                setIsSubmitEnabled={setIsSubmitEnabled}
                                // handleRefreshClick={handleRefreshClick}
                                submittedStartDate={submittedStartDate}
                                submittedEndDate={submittedEndDate}
                                submittedTimeFrame={submittedTimeFrame}
                                isRefreshEnabled={isRefreshEnabled}
                              />

                            )}
                          <div className="">
                            <button
                              className="submit-btn"
                              disabled={isSubmitEnabled}
                              // className="btn-class"
                              type="button"
                              style={{
                                // marginTop:'-40px !important',

                                width: "80px",
                                height: "20px",
                              }}
                              onClick={() => {
                                setSubmittedStartDate(DefaultStartDate);
                                setSubmittedEndDate(DefaultEndDate);
                                setSubmittedTimeFrame(timeframe);
                                setIsRefreshEnabled(false);
                                setFetchCall(true);
                              }}
                            >
                              Submit
                            </button>

                          </div>
                          {SelectedChart === "trends" || SelectedChart === "newtrends" ? (
                            <div
                              className="col-sm-3 col-md-10 col-lg-2 d-flex align-items-center justify-content-space-between gap-3"
                              style={{ width: "330%" }}
                            >
                              <div
                                className="col-sm-6 d-flex align-items-center"
                                style={{ marginLeft: "50px" }}
                              >
                                <p className="m-0 px-1 text-white text-nowrap">
                                  Primary Axis:
                                </p>
                                <div style={{ marginBottom: "22px" }}>
                                  {JSON.stringify(selectedCheckboxes1) !== "{}" && <NewMultiCascader
                                    // key={Chartdata}
                                    setFetchCall={setFetchCall}
                                    data={selectionData}
                                    match={"tea-green-jasmine"}
                                    placeholder={"Select Primary"}
                                    setChannelData={setSalesData}
                                    salesData={salesData}
                                    Chartdata={Chartdata}
                                    idsNotBeInCascader1={idsNotBeInCascader1}
                                    setCheckedList={setCheckedList1}
                                    checkedList={checkedList1}
                                    initialSelection1={initialSelection1}
                                    selectedCheckboxes={selectedCheckboxes1}
                                    setSelectedCheckboxes={
                                      setSelectedCheckboxes1
                                    }
                                  />}
                                </div>
                              </div>
                              {SelectedChart === "trends" ?
                                <div className="col-sm-6 d-flex align-items-center">
                                  <p className="m-0 px-1 text-white text-nowrap">
                                    Secondary Axis:
                                  </p>
                                  <div style={{ marginBottom: "22px" }}>
                                    {JSON.stringify(selectedCheckboxes) !== "{}" && <NewMultiCascader2
                                      // key={Chartdata}
                                      setFetchCall={setFetchCall}
                                      data={selectionData2}
                                      match={"tea-green-jasmine"}
                                      placeholder={"Select Secondary"}
                                      setChannelData={setSpendData}
                                      spendData={spendData}
                                      Chartdata={Chartdata}
                                      // idsNotBeInCascader2={idsNotBeInCascader2}
                                      initialSelection2={initialSelection2}
                                      selectedCheckboxes={selectedCheckboxes2}
                                      setSelectedCheckboxes={
                                        setSelectedCheckboxes2
                                      }
                                      checkedList={checkedList2}
                                      setCheckedList={setCheckedList2}
                                    />}
                                  </div>
                                </div>
                                : null}
                            </div>
                          ) : null}
                        </div>
                      )}
                      {/* new code end */}

                      <div className="d-flex align-items-center justify-content tab-class ">
                        {EdaTabs.map((item, index) => {
                          return (
                            <p
                              key={index}
                              className={
                                SelectedChart === item.tab_short
                                  ? "m-0 px-2 mx-2 cursor-pointer  custom-input-box lt-st tab-class-clicked"
                                  : "m-0 px-2 mx-2 cursor-pointer text-lable custom-input-box tab-class-unclicked"
                              }
                              onClick={() => {
                                setSelectedChart(item.tab_short);
                              }}
                            >
                              {item.tab_name}
                            </p>
                          );
                        })}
                        {/* <img src={Download} alt="" style={{ float: "right", cursor: 'pointer', display: 'none' }} title="Download" onClick={() => DownloadData()} /> */}

                        {/* <Tooltip /> */}
                        {
                          Notes !== "" ?
                            <>
                              <p className="m-0 icon-class"
                                id='notesdiv'
                                style={{ display: "flex", alignItems: "center", justifyContent: "center", fontSize: '10px', height: 'max-content' }}>
                                <svg src={InfoIcon} id="notes" xmlns="http://www.w3.org/2000/svg" width="25" height="25" class="bi bi-info-circle " viewBox="0 0 16 16">
                                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                  <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                </svg>
                              </p>

                              <Tooltip anchorSelect="#notesdiv" clickable>
                                {/* <div className="api-notes">
                                  {Notes}
                                </div> */}
                                <div
                                  className="api-notes"
                                  style={{ height: 'max-content', color: 'white' }}
                                  dangerouslySetInnerHTML={{ __html: Notes }}
                                >
                                </div>
                              </Tooltip>
                            </> : null
                        }

                        {(downloadFlag.chart ||
                          downloadFlag.data ||
                          downloadFlag.pdf) && (
                            <span className="flex-shrink-0 dropdown">
                              <Link
                                to="#"
                                className="d-flex align-items-center text-decoration-none dropdown-toggle text-white"
                                id="dropdownUser2"

                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                {/* <p className="m-0 px-2">
                                {" "}
  
                                <img src={Download} alt="" style={{ float: "right", cursor: 'pointer', width: '25px', height: '25px' }} title="Download" />
                              </p> */}

                                <p className="m-0 icon-class"
                                  style={{ display: "flex", alignItems: "center", justifyContent: "center", fontSize: '10px' }}>

                                  <svg src={Download} title="Download" xmlns="http://www.w3.org/2000/svg" width="25" height="25" class="bi bi-download" viewBox="0 0 16 16">
                                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                                  </svg>
                                </p>
                              </Link>
                              <ul
                                className="dropdown-menu text-small shadow"
                                aria-labelledby="dropdownUser2"
                              >
                                {SelectedChart !== "trends" &&
                                  downloadFlag.chart && (
                                    <li onClick={(e) => { }}>
                                      <Link
                                        className="dropdown-item"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          if (MediaSunburstData.length > 0) {
                                            // setDownloadSpendschart(true);
                                            setIsDownloadingChart(true);
                                            setChartContainerID(
                                              "media_sunburst_chart"
                                            );
                                            setDownloadedImageName("Spend Chart");
                                            setDownloadingChartTitle(
                                              "Buisness Landscape : Spends"
                                            );
                                          } else {
                                            toast.error(
                                              "Chartdata is not available."
                                            );
                                          }
                                        }}
                                      >
                                        Download Spends Chart
                                      </Link>
                                    </li>
                                  )}

                                {SelectedChart !== "trends" &&
                                  downloadFlag.chart && (
                                    <li onClick={(e) => { }}>
                                      <Link
                                        className="dropdown-item"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          if (SunburstData.length > 0) {
                                            // setDownloadSaleschart(true);
                                            setIsDownloadingChart(true);
                                            setChartContainerID("sunburst_chart");
                                            setDownloadedImageName("Sales Chart");
                                            setDownloadingChartTitle(
                                              "Buisness Landscape : Sales"
                                            );
                                          } else {
                                            toast.error(
                                              "Chartdata is not available."
                                            );
                                          }
                                        }}
                                      >
                                        Download Sales Chart
                                      </Link>
                                    </li>
                                  )}

                                {SelectedChart !== "overview" &&
                                  downloadFlag.chart && (
                                    <li onClick={(e) => { }}>
                                      <Link
                                        className="dropdown-item"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          if (SelectedChart === "trends") {
                                            // setDownloadTrendschart(true);
                                            setIsDownloadingChart(true);
                                            setChartContainerID("trends_chart");
                                            setDownloadedImageName(
                                              "Trends Chart"
                                            );
                                            setDownloadingChartTitle(
                                              "Buisness Landscape : Trends"
                                            );
                                          } else {
                                            toast.error(
                                              "Chartdata is not available."
                                            );
                                          }
                                        }}
                                      >
                                        Download Trends Chart
                                      </Link>
                                    </li>
                                  )}

                                {downloadFlag.data && (
                                  <li>
                                    <Link
                                      className="dropdown-item"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        downloadData();
                                      }}
                                    >
                                      Download Data
                                    </Link>
                                  </li>
                                )}
                                {downloadFlag.pdf && (
                                  <li onClick={(e) => { }}>
                                    <Link
                                      className="dropdown-item"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        downloadPDF();
                                      }}
                                    >
                                      Download PDF
                                    </Link>
                                  </li>
                                )}
                              </ul>
                            </span>
                          )}
                      </div>
                    </div>
                    {SelectedChart === "trends" ? (
                      <>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                            }}
                          ></div>
                        </div>

                        {console.log(fetchMixChart2) ||
                          (JSON.stringify(fetchMixChart2) !== "{}" &&
                            FetchTrendsFilters === false) ? (
                          <div key={keyValue} style={{ height: "90%" }} >
                            <TrendChart
                              TrendChartdata={TrendChartdata}
                              height={SpendsHeight}
                              TrendChartRef={TrendChartRef}
                              fetchMixChart2={fetchMixChart2}
                              setLoader={setLoader}
                            />
                          </div>
                        ) : null}
                      </>
                    ) : null}

                    {SelectedChart === "newtrends" ? (
                      <>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                            }}
                          ></div>
                        </div>

                        {console.log(fetchMixChart2) ||
                          (JSON.stringify(fetchMixChart2) !== "{}" &&
                            FetchTrendsFilters === false) ? (
                          <div key={keyValue} style={{ height: "90%" }} >
                            <TrendChart2
                              TrendChartdata={TrendChartdata}
                              height={SpendsHeight}
                              TrendChartRef={TrendChartRef}
                              fetchMixChart2={fetchMixChart2}
                            />
                          </div>
                        ) : null}
                      </>
                    ) : null}

                    {SelectedChart === "overview" ? (
                      <>
                        <div className="row align-items-center custom-chart-title tag-switch ">
                          <div className="col-sm-6">
                            <div className="d-flex align-items-center px-1">
                              <h3 className="data-spends m-0">{sb1_name}</h3>
                              <p
                                className="data-spends-values text-white px-2 m-0 pb-0"
                                style={{ fontSize: "0.75rem" }}
                              >
                                [{sb1_tag}]
                              </p>
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <div className="d-flex align-items-center px-1">
                              <h3 className="data-spends m-0">{sb2_name}</h3>
                              <p
                                className="data-spends-values text-white px-2 m-0 pb-0"
                                style={{ fontSize: "0.75rem" }}
                              >
                                [{sb2_tag}]
                              </p>
                            </div>
                          </div>
                        </div>
                        <div
                          className="row align-items-center pt-3 chart-div"
                          id="overview_chart"
                        >
                          <div className="col-sm-6">
                            <div
                              className="sunburst-chart-height"
                              ref={refSpends}
                              style={{ height: "90%" }}
                            >
                              {MediaSunburstData.length > 0 ? (
                                <Sunburst
                                  Fixed={Fixed}
                                  ActiveFilters={ActiveFilters}
                                  Brand={Analyse.Brand}
                                  Timeframe={timeframe}
                                  StartDate={DefaultStartDate}
                                  EndDate={DefaultEndDate}
                                  Kpi={Analyse.KPI}
                                  setLoader={setLoader}
                                  // Downloadchart={DownloadSpendschart}
                                  // setDownloadchart={setDownloadSpendschart}
                                  SunburstData={MediaSunburstData}
                                  name={`${sb1_name} [${sb1_tag}]` }
                                  height={SpendsHeight}
                                  DecimalPoint={DecimalPoint}
                                  MediaPerc={MediaPerc}
                                />
                              ) : null}
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div
                              className="sunburst-chart-height"
                              ref={refSales}
                              style={{ height: "90%" }}
                            >
                              {SunburstData.length > 0 ? (
                                <Sunburst
                                  Fixed={Fixed}
                                  ActiveFilters={ActiveFilters}
                                  Brand={Analyse.Brand}
                                  Timeframe={timeframe}
                                  StartDate={DefaultStartDate}
                                  EndDate={DefaultEndDate}
                                  Kpi={Analyse.KPI}
                                  setLoader={setLoader}
                                  // setDownloadchart={setDownloadSaleschart}
                                  // Downloadchart={DownloadSaleschart}
                                  SunburstData={SunburstData}
                                  name={`${sb2_name} [${sb2_tag}]` }
                                  height={SalesHeight}
                                  KpiPerc={KpiPerc}
                                />
                              ) : null}
                            </div>
                          </div>
                          {Legends.length > 0 ? (
                            <div className="col-sm-12 mt-0">
                              <div
                                className="row justify-content-center"
                                style={{
                                  height: "70px",
                                  overflowY: "auto",
                                  overflowX: "hidden",
                                }}
                              >
                                {Legends.map((item, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className="col-md-3 col-lg-2 text-center text-white mb-3 charts-legends"
                                    >
                                      <span
                                        style={{
                                          height: "16px",
                                          width: "16px",
                                          display: "inline-block",
                                          marginRight: "5px",
                                          backgroundColor: item.Color,
                                        }}
                                      ></span>
                                      {item.Name}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
              {parseInt(ShowSummary) === 1 ? (
                <div className="col-xl-3">
                  <div
                    className="card card-min-height"
                    style={{ height: ChartHeight + "px" }}
                  >
                    <div className="card-body brand-card">
                      <div className="row align-items-center justify-content-sm-between">
                        <h3 className="data-spends my-2">Summary</h3>
                        <div className="spends-list">
                          <ol>
                            {Summary.map((item, i) => {
                              return (
                                <li key={i} className="spends-list-li">
                                  <p>{item}</p>
                                </li>
                              );
                            })}
                          </ol>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="row custom-note">
              <div className="col-sm-1">
                {FooterNotes !== "" ? (
                  <>
                    <i
                      className="fas fa-mail-bulk chart_info_icon footer-notes-icon icon-class"
                      style={{ display: "flex", alignItems: "center", justifyContent: "center", fontSize: '15px', marginLeft: '6px', marginBottom: '10px' }}
                      id="clickable"
                    ></i>
                    <Tooltip anchorSelect="#clickable" clickable>
                      {/* <div className="footer-notes">{FooterNotes}</div> */}
                      <div
                        className="footer-notes"
                        style={{ height: 'max-content' }}
                        dangerouslySetInnerHTML={{ __html: FooterNotes }}
                      ></div>
                    </Tooltip>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Main;