import React from 'react'
import Channel from './Channel';
import FooterOptimise from './FooterOptimise';
import CustomSelect from '../Components/CustomSelect';
import downloadFlag from '../DownloadFlag';


const TableData = (props) => {
    // console.log(props.AllowEdit, "props.AllowEdit")
    return (
        <div className="col-xs-12 col-md-12 col-lg-12 col-xl-7">
            <div className="card mb-0 optimizer-table-height">
                <div className="card-body">
                    <div className="table-responsive optimizer-table-responsive">
                        <span style={{color:'#d6ff41',float:'right'}}>* All values in {props.returnUnit()} </span>
                        <table className="table table-borderless mb-0">
                            <thead>
                                <tr>
                                    <th></th>
                                    {/* <th></th> */}
                                    <th className="text-left text-white data-spends">Channels</th>
                                    <th className="text-center">
                                        <CustomSelect
                                            value={props.SelectedYear} // Set the value to props.SelectedYear
                                            onChange={(e) => {
                                                props.YearHandler(e); // Call the existing YearHandler function with the selected value
                                            }}
                                            options={props.PreviousData.map((item) => ({
                                                key: item.year, // Map the item's year to the key property
                                                value: item.year, // Map the item's year to the value property
                                            }))} // Pass the mapped options to CustomSelect
                                            isDetailPopUp={false} // Keep this false if no detailed popup is needed
                                            barWidth={90}
                                        />

                                    </th>
                                    <th className="text-center text-white"></th>
                                    <th className="text-center text-white data-spends">{props.NewData.year}</th>
                                    <th className="text-center text-white data-spends">Edit spends</th>
                                    <th className="text-center text-white gap-3" style={{display:'flex',justifyContent:'right',alignItems:'center'}}
                                    >
                                        <label class="label" style={{marginTop:'12px'}}>
                                            <div className="container" >
                                                <input
                                                    type="checkbox"
                                                    checked={props.AllowEdit}
                                                    onChange={(e) => props.setAllowEdit(!props.AllowEdit)}
                                                    className="form-check-input switch"
                                                />
                                                <div class="toggle"></div>
                                            </div>
                                        </label>

                                        {(downloadFlag.chart ||
                          downloadFlag.data ||
                          downloadFlag.pdf) && <div className="m-0 icon-class"
                                            style={{ display: "flex", alignItems: "center", justifyContent: "center", fontSize: '10px', cursor: 'pointer' }}>
                                            <svg
                                                onClick={props.DownloadOptimizer}
                                                title="Download"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="25"
                                                height="25"
                                                className="bi bi-download"
                                                viewBox="0 0 16 16">
                                                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                                <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                                            </svg>
                                        </div>}

                                    </th>
                                </tr>
                            </thead>


                            {

                                JSON.stringify(props.Summary) !== "{}" ?
                                    <tbody>
                                        {
                                            Object.keys(props.Summary.channels).map((channel, index) => {
                                                return (
                                                    <Channel
                                                        key={index}
                                                        index={index}
                                                        Summary={props.Summary}
                                                        NewData={props.NewData}
                                                        NewFixedData={props.NewFixedData}
                                                        channel={channel}
                                                        AllowEdit={props.AllowEdit}

                                                        EditNewSpends={props.EditNewSpends}
                                                        EditNewSpendsOnBlur={props.EditNewSpendsOnBlur}
                                                        ResetSpends={props.ResetSpends}

                                                        ChannelExpand={props.ChannelExpand}
                                                        ChannelExpand1={props.ChannelExpand1}
                                                        handleExpandChannel={props.handleExpandChannel}
                                                        handleShrinkChannel={props.handleShrinkChannel}
                                                        handleExpandChannel1={props.handleExpandChannel1}
                                                        handleShrinkChannel1={props.handleShrinkChannel1}
                                                        Levels={props.Levels}
                                                        AddLevel={props.AddLevel}
                                                        RemoveLevel={props.RemoveLevel}
                                                        traverse={props.traverse}
                                                        flattened={props.flattened}
                                                        setIsExecuted={props.setIsExecuted}
                                                        setUserInputKey={props.setUserInputKey}
                                                    />
                                                )
                                            })
                                        }
                                    </tbody>
                                    : null
                            }

                            <FooterOptimise
                                setSaveResult={props.setSaveResult}
                                AllowEdit={props.AllowEdit}
                                Optimise={props.Optimise}
                                NewFixedData={props.NewFixedData}
                                NewData={props.NewData}
                                DownloadOptimizer={props.DownloadOptimizer}
                                setRe={props.setRe}
                            />

                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TableData